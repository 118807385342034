import { useEffect, useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Autocomplete as MuiAutocomplete, IconButton, ThemeProvider } from '@mui/material';
import { Close } from '@mui/icons-material';
import { debounce } from 'lodash/fp';
import { Checkbox, Select, TelephoneInput, TextField, theme } from 'FrontRoyalMaterialUiForm';
import { useForm, FormProvider, yupResolver } from 'FrontRoyalReactHookForm';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { editorLocales } from 'Locale';
import { editAoiRecordsApi } from 'UserAdministrationTab/redux';
import TimezoneAutocomplete from 'UserAdministrationTab/HelperComponents/TimezoneAutocomplete';
import './CreateUser.scss';
import { type UsersAdminFilterData } from 'Admin/Admin.types';
import { LEARNER_ROLE_ID } from 'Users';
import { autoSuggestOptionsApi } from 'AutoSuggestOptions';

type CompanyResults = { professionalOrganizationOptions: { id: string; text: string }[] };

const { useExecuteCreateUserActionMutation } = editAoiRecordsApi;
const { useLazyAutoSuggestOptionsQuery } = autoSuggestOptionsApi;

interface CreateUserFormValues {
    [key: string]: string | boolean | string[] | null;
    name: string;
    password: string;
    prefLocale: string;
    fallbackProgramType: string;
    accessGroupIds: string[];
    company: string;
    jobTitle: string;
    email: string;
    phone: string | null;
    activeInstitutionId: string;
    institutionIds: string[];
    globalRoleId: string;
    timezone: string | null;
    deactivated: boolean;
    mbaContentLockable: boolean;
}

const companySuggestionBaseFilters = {
    inLocaleOrEn: 'en',
    suggest: true,
    type: 'professional_organization',
} as const;

const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
    prefLocale: Yup.string().required('Locale is required'),
    fallbackProgramType: Yup.string().required('Fallback Program Type is required'),
    institutionIds: Yup.array().of(Yup.string()).min(1, 'At least one institution is required'),
    activeInstitutionId: Yup.string().required('Active Institution is required'),
    globalRoleId: Yup.string().required('Role is required'),
    timezone: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    accessGroupIds: Yup.array().of(Yup.string()),
    company: Yup.string(),
    jobTitle: Yup.string(),
    deactivated: Yup.boolean(),
    mbaContentLockable: Yup.boolean(),
});

const defaultValues: CreateUserFormValues = {
    name: '',
    password: '',
    prefLocale: 'en',
    fallbackProgramType: 'demo',
    accessGroupIds: [],
    company: '',
    jobTitle: '',
    email: '',
    phone: null,
    activeInstitutionId: '',
    institutionIds: [],
    globalRoleId: LEARNER_ROLE_ID,
    timezone: null,
    deactivated: false,
    mbaContentLockable: false,
};

type Props = {
    usersAdminFilterData: UsersAdminFilterData;
};

export const CreateUser: React.FC<Props> = ({ usersAdminFilterData }) => {
    const [modalOpen, setModalOpen] = useState(false);
    // State used for tracking when the modal is closing to add animations to modal
    const [closingModal, setClosingModal] = useState(false);
    const formFunctions = useForm<CreateUserFormValues>({ resolver: yupResolver(validationSchema), defaultValues });
    const [triggerFetch, results] = useLazyAutoSuggestOptionsQuery();
    const [submit, { reset: clearCreateUser, data: createdUser, isLoading: isSubmitting }] =
        useExecuteCreateUserActionMutation();

    const {
        formState: { isValid, isDirty },
        reset,
        setFieldValue,
        watch,
    } = formFunctions;

    const formValues = watch();
    const institutionIds = formValues.institutionIds || [];
    const email = formValues.email || '';

    const resetForm = useCallback(() => {
        reset(defaultValues);
    }, [reset]);

    useEffect(() => {
        if (createdUser) {
            setTimeout(() => {
                clearCreateUser();
                resetForm();
                setModalOpen(false);
            }, 800);
        }
    }, [createdUser, clearCreateUser, resetForm]);

    const closeHandler = useCallback(() => {
        if (isDirty && !window.confirm('Exiting now will cause you to lose all changes. Are you sure?')) {
            return;
        }
        setClosingModal(true);
        setTimeout(() => {
            setModalOpen(false);
            resetForm();
            setClosingModal(false);
        }, 310);
    }, [isDirty, resetForm]);

    return (
        <ThemeProvider theme={theme}>
            <div className="admin-create-user">
                <button type="button" className="btn flat blue" onClick={() => setModalOpen(true)}>
                    Create User
                </button>
                {modalOpen && (
                    <>
                        <div
                            className={`create-user-modal-overlay ${closingModal ? 'close-modal' : ''}`}
                            aria-hidden
                            onClick={closeHandler}
                        />
                        <FormProvider {...formFunctions}>
                            <form
                                onSubmit={formFunctions.handleSubmit(values => {
                                    submit(values);
                                })}
                            >
                                {createdUser ? (
                                    <div className="create-user-success">{`${email} created.`}</div>
                                ) : (
                                    <div className={`create-user-modal ${closingModal ? 'close-modal' : ''}`}>
                                        <IconButton onClick={closeHandler}>
                                            <Close fontSize="large" />
                                        </IconButton>

                                        <h3>Create User</h3>
                                        <p>
                                            By default, any user created without an associated institution will include
                                            subscription and upsell features.
                                        </p>
                                        <p>
                                            An institutional user will inherit groups from their associated institution,
                                            in addition to any groups you choose to assign them individually.
                                        </p>
                                        <p>
                                            Any user can be given groups, which allows them to view the courses in those
                                            groups.
                                        </p>
                                        <p>
                                            After creating the user, you can use User Admin actions to include as an
                                            auditing user or assign lesson permissions.
                                        </p>
                                        <div className="create-user-modal-body">
                                            <div>
                                                <TextField
                                                    name="name"
                                                    label="Name"
                                                    disabled={isSubmitting}
                                                    fullWidth
                                                    required
                                                />
                                                <TextField
                                                    name="password"
                                                    label="Password"
                                                    type="password"
                                                    disabled={isSubmitting}
                                                    fullWidth
                                                    required
                                                />
                                                <Select
                                                    name="prefLocale"
                                                    label="Locale"
                                                    fullWidth
                                                    disabled={isSubmitting}
                                                    options={editorLocales}
                                                    optionLabel={locale => locale.title}
                                                    optionValue={locale => locale.value}
                                                    required
                                                />
                                                <Select
                                                    name="fallbackProgramType"
                                                    label="Fallback Program Type"
                                                    fullWidth
                                                    disabled={isSubmitting}
                                                    options={usersAdminFilterData.availableFallbackProgramTypes}
                                                    required
                                                />
                                                <TelephoneInput
                                                    name="phone"
                                                    label="Phone Number"
                                                    disabled={isSubmitting}
                                                />
                                                <Select
                                                    name="accessGroupIds"
                                                    label="Available Access Groups"
                                                    fullWidth
                                                    multiple
                                                    disabled={isSubmitting}
                                                    options={usersAdminFilterData.availableAccessGroups}
                                                    optionLabel={group => group.name}
                                                    optionValue={group => group.id}
                                                />
                                                {/*
                                                We could probably use our internal FrontRoyalMaterialUiForm/Autocomplete here
                                                instead of using MaterialUI's Autocomplete, but it's not causing any problems...
                                                */}
                                                <MuiAutocomplete
                                                    freeSolo
                                                    options={
                                                        (results?.currentData as CompanyResults)
                                                            ?.professionalOrganizationOptions || []
                                                    }
                                                    getOptionLabel={option =>
                                                        typeof option === 'object' ? option.text : option
                                                    }
                                                    autoHighlight
                                                    loadingText={
                                                        <FrontRoyalSpinner className="no-top-margin no-delay" />
                                                    }
                                                    loading={results.isFetching}
                                                    onChange={(_event, value) => {
                                                        if (value) {
                                                            setFieldValue(
                                                                'company',
                                                                typeof value === 'object' ? value.text : value,
                                                            );
                                                        }
                                                    }}
                                                    renderInput={props => (
                                                        <TextField
                                                            {...props}
                                                            label="Company"
                                                            name="company"
                                                            onChange={debounce(500, e => {
                                                                const val = e.target.value;
                                                                if (val?.length >= 2) {
                                                                    triggerFetch(
                                                                        {
                                                                            filters: {
                                                                                ...companySuggestionBaseFilters,
                                                                                searchText: val,
                                                                            },
                                                                            limit: 10,
                                                                        },
                                                                        true,
                                                                    );
                                                                }
                                                                setFieldValue('company', val);
                                                            })}
                                                        />
                                                    )}
                                                />
                                                <TextField
                                                    name="jobTitle"
                                                    label="Job Title"
                                                    disabled={isSubmitting}
                                                    fullWidth
                                                />
                                            </div>
                                            <div>
                                                <TextField
                                                    name="email"
                                                    label="Email Address"
                                                    disabled={isSubmitting}
                                                    fullWidth
                                                    required
                                                />
                                                <Select
                                                    name="institutionIds"
                                                    label="Institutions"
                                                    fullWidth
                                                    multiple
                                                    disabled={isSubmitting}
                                                    options={usersAdminFilterData.availableInstitutions}
                                                    optionLabel={({ name }) => name}
                                                    optionValue={({ id }) => id}
                                                    required
                                                />
                                                <Select
                                                    name="activeInstitutionId"
                                                    label="Active Institution"
                                                    fullWidth
                                                    disabled={isSubmitting || !institutionIds.length}
                                                    options={usersAdminFilterData.availableInstitutions.filter(
                                                        ({ id }) => institutionIds.includes(id),
                                                    )}
                                                    optionLabel={({ name }) => name}
                                                    optionValue={({ id }) => id}
                                                    required
                                                />
                                                <Select
                                                    name="globalRoleId"
                                                    label="Role"
                                                    fullWidth
                                                    required
                                                    disabled={isSubmitting}
                                                    options={usersAdminFilterData.availableRoles}
                                                    optionLabel={role => role.name}
                                                    optionValue={role => role.id}
                                                />
                                                <TimezoneAutocomplete disabled={isSubmitting} fullWidth />
                                                <Checkbox name="deactivated" label="Deactivate User" />
                                                <Checkbox name="mbaContentLockable" label="Lock MBA Content" />
                                            </div>
                                        </div>
                                        <button
                                            className="btn flat blue"
                                            type="submit"
                                            disabled={!isValid || isSubmitting}
                                        >
                                            {isSubmitting ? (
                                                <FrontRoyalSpinner className="no-top-margin no-delay" color="white" />
                                            ) : (
                                                'Create'
                                            )}
                                        </button>
                                    </div>
                                )}
                            </form>
                        </FormProvider>
                    </>
                )}
            </div>
        </ThemeProvider>
    );
};

export default CreateUser;
