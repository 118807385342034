import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/batch_edit_users/batch_edit_users_select_users.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('batchEditUsersSelectUsers', [
    '$injector',

    function factory($injector) {
        const User = $injector.get('User');
        const $http = $injector.get('$http');
        const $window = $injector.get('$window');
        const DialogModal = $injector.get('DialogModal');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                viewModel: '<',
            },
            link(scope) {
                scope.searchParams = {};
                scope.searchParams.application_registered = undefined;

                scope.loadingUsers = false;
                scope.showNAccountIds = 40;

                scope.$watch('viewModel.userIdentifierText', text => {
                    text = text || '';
                    scope.userIdentifiers = text.split(/[\s,;]+/).filter(Boolean); // native _.compact
                });

                scope.loadUsers = () => {
                    const params = {
                        view: 'admin_list',
                        per_page: 9999999999,
                        filters: {
                            program_rows: [{ cohort_id: scope.viewModel.cohortId }],
                            identifiers: scope.userIdentifiers,
                        },
                    };

                    scope.loadingUsers = true;
                    scope.viewModel.loadedUsers = [];
                    scope.viewModel.updatedUsers = [];
                    scope.viewModel.errors = {};
                    scope.viewModel.unfoundIdentifiers = [];
                    scope.viewModel.noUsersFound = false;

                    // arguable whether we should reset this here, but seems safest so someone
                    // doesn't not realize that something they selected before is still selected
                    scope.viewModel.updateParams = {};

                    // The batch edit users feature needs a POST endpoint to avoid Http parse errors from being thrown.
                    // Using a GET request and passing in too many ids/emails will exceed the query string character limit,
                    // but by using a POST request we can bypass this issue by using POST data and not query parameters.
                    return $http.post(`${$window.ENDPOINT_ROOT}/api/users/index.json`, params).then(response => {
                        scope.loadingUsers = false;
                        response.data.contents.users.forEach(user => {
                            scope.viewModel.loadedUsers.push(User.new(user));
                        });

                        if (scope.viewModel.loadedUsers.length === 0) {
                            scope.viewModel.noUsersFound = scope.viewModel.loadedUsers.length === 0;
                        } else if (scope.viewModel.loadedUsers.length > 1000) {
                            // We saw issues on the server from batch updating too many people in a
                            // transaction. We have removed the transaction, but let's also place a
                            // client-side limit.
                            DialogModal.alert({
                                content: `Please update 1000 or less users (currently ${scope.viewModel.loadedUsers.length})`,
                            });
                            scope.viewModel.loadedUsers = undefined;
                        } else {
                            scope.viewModel.unfoundIdentifiers = _.difference(
                                scope.userIdentifiers,
                                getLoadedIdentifiers(),
                            );

                            if (scope.viewModel.unfoundIdentifiers.length === 0) {
                                scope.viewModel.gotoSection('update-users');
                            }
                        }
                    });
                };

                function getLoadedIdentifiers() {
                    const loadedIds = scope.viewModel.loadedUsers.map(user => user.id);
                    const loadedEmails = scope.viewModel.loadedUsers.map(user => user.email);
                    return _.union(loadedIds, loadedEmails);
                }
            },
        };
    },
]);
