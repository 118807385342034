import angularModule from 'Admin/angularModule/scripts/admin_module';
import AdminEditBillingTransaction from 'AdminEditBillingTransaction';
import { react2Angular } from 'FrontRoyalReact2Angular';
import template from 'Admin/angularModule/views/admin_owner_payments.html';
import tuitionContractTemplate from 'Admin/angularModule/views/admin_owner_payments_tuition_contract.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { formatMoney } from 'FormatMoney';
import { titleize } from 'String';

const templateUrl = cacheAngularTemplate(angularModule, template);
const tuitionContractTemplateUrl = cacheAngularTemplate(angularModule, tuitionContractTemplate);

angularModule.component(
    'adminEditBillingTransaction',
    react2Angular(AdminEditBillingTransaction, ['thing', 'editableThingsListViewModel', 'passthrough'], '', false),
);

angularModule.directive('adminOwnerPayments', [
    '$injector',

    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                owner: '<',
            },
            link(scope) {
                scope.$watch('owner', () => {
                    scope.billingTransactions = [];
                    if (!scope.owner) {
                        return;
                    }

                    function tuitionContract(billingTransaction) {
                        if (!billingTransaction.tuition_contract_id) {
                            return null;
                        }

                        return scope.owner.tuitionContracts.find(
                            tc => tc.id === billingTransaction.tuition_contract_id,
                        );
                    }

                    function tuitionContractStatus(billingTransaction) {
                        const contract = this.tuitionContract(billingTransaction);
                        if (contract) {
                            return contract.status;
                        }
                        if (billingTransaction.tuition_contract_id) {
                            // the transaction is linked to a tuition contract on the server,
                            // but the client doesn't have it, which means it isn't referenced
                            // from any program inclusions and can be *presumed* canceled.
                            return 'canceled';
                        }
                        return null;
                    }

                    function cohortName(billingTransaction) {
                        const contract = this.tuitionContract(billingTransaction);
                        if (!contract) {
                            return 'CANCELED CONTRACT';
                        }

                        const programInclusion = scope.owner.programInclusions.find(
                            pi => pi.tuitionContractId === contract.id,
                        );
                        return programInclusion?.cohortName;
                    }

                    function tuitionContractAmounts(billingTransaction) {
                        const contract = this.tuitionContract(billingTransaction);
                        if (!contract) {
                            return null;
                        }

                        if (contract.status === 'open') {
                            return `${formatMoney(contract.netTuitionAmount)} / ${formatMoney(
                                contract.netRequiredPayment,
                            )}`;
                        }

                        return `${formatMoney(contract.netTuitionAmount)}`;
                    }

                    scope.columns = [
                        {
                            prop: 'transaction_time',
                            type: 'time',
                            label: 'Time',
                        },
                        {
                            prop: 'transaction_type',
                            type: 'text',
                            label: 'Type',
                        },
                        {
                            prop: 'provider',
                            type: 'text',
                            label: 'Provider',
                        },
                        {
                            id: 'payment_method_category',
                            prop(billingTransaction) {
                                const method = billingTransaction.payment_method_category;

                                if (!method) {
                                    return null;
                                }
                                if (method === 'us_bank_account') {
                                    return 'ACH direct debit';
                                }

                                return titleize(method.replace('_', ' ')); // apple_pay -> Apple Pay
                            },
                            type: 'text',
                            label: 'Payment Method',
                        },
                        {
                            id: 'amount',
                            prop(billingTransaction) {
                                let str = formatMoney(billingTransaction.amount);
                                if (billingTransaction.amount_refunded) {
                                    str = `${str} ( ${formatMoney(billingTransaction.amount_refunded * -1.0)})`;
                                }
                                return str;
                            },
                            type: 'number',
                            label: 'Amount',
                        },
                        {
                            id: 'tuition_contract',
                            type: 'custom',
                            templateUrl: tuitionContractTemplateUrl,
                            callbacks: {
                                cohortName,
                                tuitionContract,
                                tuitionContractAmounts,
                                tuitionContractStatus,
                            },
                            label: 'Tuition Contract',
                        },
                    ];
                });

                //------------------------
                // STRIPE LINK
                //------------------------
                scope.stripeUrl = null;
                const userId = scope.owner?.id;
                if (userId) {
                    const stripeEnvModifier = process.env.NODE_ENV === 'production' ? '' : 'test/';
                    scope.stripeUrl = `https://dashboard.stripe.com/${stripeEnvModifier}customers/${userId}`;
                }
            },
        };
    },
]);
