import cacheAngularTemplate from 'cacheAngularTemplate';
import { formatScore } from 'FormatScore';
import blueCheckMark from 'vectors/blue-check-mark.svg';
import template from '../../views/admin_mba/edit_exam_score_modal.html';
import angularModule from '../admin_module';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editExamScoreModal', [
    '$injector',
    function factory($injector) {
        const $http = $injector.get('$http');
        const $window = $injector.get('$window');
        const safeApply = $injector.get('safeApply');
        const DialogModal = $injector.get('DialogModal');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                user: '<',
                exam: '<',
                onSuccess: '<',
            },
            link(scope) {
                //---------------------
                // Initialization
                //---------------------

                const ALLOWABLE_ERROR = 0.000001;
                scope.loading = true;
                scope.adjustmentPoints = {};
                scope.invalidNewScoreErrors = {};
                scope.detailsForLessonProgressRecords = [];
                scope.successCheckmark = blueCheckMark;

                //---------------------
                // Helpers
                //---------------------

                const hasChangedValue = val => val !== 0;
                const getNumCorrect = lessonProgress =>
                    Object.values(lessonProgress.challenge_scores).filter(val => val === 1).length;
                const getNumChallenges = lessonProgress => Object.values(lessonProgress.challenge_scores).length;
                const getPreviousAdjustmentPoints = (lessonProgress, numCorrect, numChallenges) =>
                    lessonProgress.best_score * numChallenges - numCorrect;

                scope.$watchGroup(['user', 'exam'], async () => {
                    if (scope.loading && scope.user && scope.exam) {
                        // Ideally, we would get just the stream progress and lesson progress that we need,
                        // but our API doesn't currently support it and the `getAllProgress` API call appears
                        // to be reasonably fast, so we just use it instead.
                        const allProgress = await scope.user.progress.getAllProgress();
                        scope.examProgress = allProgress.streamProgress.find(
                            streamProgress => streamProgress.locale_pack_id === scope.exam.locale_pack.id,
                        );

                        // If the exam was waived, we need to fetch the original stream that they completed and use that instead.
                        // The score will then be updated on all of the user's progress records with a matching `requirement_identifier`.
                        if (scope.examProgress.waiver) {
                            const response = await $http.get(
                                `${$window.ENDPOINT_ROOT}/api/lesson_streams/get_originally_completed_stream_for_user.json`,
                                {
                                    params: {
                                        user_id: scope.user.id,
                                        requirement_identifier: scope.exam.locale_pack.requirement_identifier,
                                    },
                                },
                            );
                            scope.exam = response.data.contents.lesson_streams[0];
                            scope.examProgress = allProgress.streamProgress.find(
                                streamProgress => streamProgress.locale_pack_id === scope.exam.locale_pack.id,
                            );
                        }

                        scope.allLessonProgressByLocalePackId = allProgress.lessonProgress.reduce(
                            (cache, lessonProgress) => {
                                cache[lessonProgress.locale_pack_id] = lessonProgress;
                                return cache;
                            },
                            {},
                        );

                        scope.exam.lessons.forEach(lesson => {
                            const lessonLocalePackId = lesson.locale_pack.id;
                            const lessonProgress = scope.allLessonProgressByLocalePackId[lessonLocalePackId];
                            const numCorrect = getNumCorrect(lessonProgress);
                            const numChallenges = getNumChallenges(lessonProgress);
                            const originalScore = numCorrect / numChallenges;
                            // We want to warn the admin if we detect that the score may have already
                            // been adjusted from the originalScore.
                            const showWarning =
                                originalScore !== lessonProgress.best_score &&
                                Math.abs(originalScore - lessonProgress.best_score) > ALLOWABLE_ERROR;

                            scope.adjustmentPoints[lessonLocalePackId] = 0;
                            scope.detailsForLessonProgressRecords.push({
                                localePackId: lessonLocalePackId,
                                title: lesson.title,
                                showWarning,
                                formattedScore() {
                                    const adjustmentPointValue = scope.adjustmentPoints[lessonLocalePackId];

                                    if (adjustmentPointValue) {
                                        return `${formatScore(lessonProgress.best_score)}% → ${formatScore(
                                            scope.calculateNewScore(lessonProgress, adjustmentPointValue),
                                        )}%`;
                                    }
                                    return `${formatScore(lessonProgress.best_score)}%`;
                                },
                                newScoreIsInvalid() {
                                    const adjustmentPointValue = scope.adjustmentPoints[lessonLocalePackId];
                                    const newScore = scope.calculateNewScore(lessonProgress, adjustmentPointValue);
                                    const scoreIsInvalid = newScore < 0 || newScore > 1; // anything less than 0% or higher than 100% is invalid
                                    if (scoreIsInvalid) {
                                        scope.invalidNewScoreErrors[lessonLocalePackId] = true;
                                    } else {
                                        delete scope.invalidNewScoreErrors[lessonLocalePackId];
                                    }
                                    return scoreIsInvalid;
                                },
                                warn() {
                                    const previousAdjustmentPoints = getPreviousAdjustmentPoints(
                                        lessonProgress,
                                        numCorrect,
                                        numChallenges,
                                    );
                                    const confirmationMessage =
                                        Math.abs(Math.round(previousAdjustmentPoints) - previousAdjustmentPoints) <
                                        ALLOWABLE_ERROR
                                            ? `The original score for this lesson (${Math.round(
                                                  originalScore * 100,
                                              )}%) has already been adjusted by ${Math.round(
                                                  previousAdjustmentPoints,
                                              )} point${
                                                  Math.round(previousAdjustmentPoints) === 1 ? '' : 's'
                                              }.\n\nAre you sure you wish to adjust the score further?`
                                            : 'WARNING: The current score for this lesson progress looks a bit fishy. The user may have tried to hack their score for this lesson.\n\nAre you sure you wish to adjust the score?';

                                    if ($window.confirm(confirmationMessage)) {
                                        this.showWarning = false;
                                    }
                                },
                            });
                        });
                        scope.loading = false;

                        // Since we use async/await, which doesn't trigger a digest cycle,
                        // we need to manually kick one off so that the UI updates appropriately.
                        safeApply(scope);
                    }
                });

                scope.newScoresAreValid = () => Object.values(scope.invalidNewScoreErrors).length === 0;

                // The form is valid if all of the following conditions are true:
                //     1. At least one adjustment point value is no longer the default value of 0.
                //     2. All adjustment point values are integers.
                //     3. All new scores are valid i.e. between 0% and 100% (inclusive).
                scope.formIsValid = () => {
                    const adjustmentPointValues = Object.values(scope.adjustmentPoints);
                    return (
                        !!adjustmentPointValues.find(hasChangedValue) &&
                        adjustmentPointValues.every(Number.isInteger) &&
                        scope.newScoresAreValid()
                    );
                };

                scope.calculateNewScore = (lessonProgress, adjustmentPoints) => {
                    const numCorrect = getNumCorrect(lessonProgress);
                    const numChallenges = getNumChallenges(lessonProgress);
                    const originalScore = numCorrect / numChallenges;

                    // If the best_score on the lessonProgress is not the same as the calculated originalScore,
                    // then the score has already been adjusted, in which case we would want to calculate the
                    // new score so that it's relative to the previously adjusted best_score.
                    if (lessonProgress.best_score !== originalScore) {
                        const previousAdjustmentPoints = getPreviousAdjustmentPoints(
                            lessonProgress,
                            numCorrect,
                            numChallenges,
                        );
                        if (previousAdjustmentPoints) {
                            const combinedAdjustmentPoints = Math.round(previousAdjustmentPoints) + adjustmentPoints;
                            return (numCorrect + combinedAdjustmentPoints) / numChallenges;
                        }
                    }
                    return (numCorrect + adjustmentPoints) / numChallenges;
                };

                scope.confirmChanges = () => {
                    scope.saving = true;

                    // Get the lesson progress records that should have a new best_score
                    // calculated and update the best_score on the lesson progress record.
                    const records = Object.entries(scope.adjustmentPoints)
                        // eslint-disable-next-line no-unused-vars
                        .filter(([localePackId, val]) => hasChangedValue(val) && Number.isInteger(val))
                        .map(([localePackId, val]) => {
                            const lessonProgressRecord = scope.allLessonProgressByLocalePackId[localePackId];
                            return {
                                id: lessonProgressRecord.id,
                                best_score: scope.calculateNewScore(lessonProgressRecord, val),
                                adjustment_points: val,
                            };
                        });

                    return $http
                        .put(`${$window.ENDPOINT_ROOT}/api/lesson_progress/batch_update_scores.json`, {
                            records,
                            stream_progress_id: scope.examProgress.id,
                        })
                        .then(response => {
                            scope.onSuccess(response);
                            scope.scoresSaved = true;
                        })
                        .catch(() => {})
                        .finally(() => {
                            scope.saving = false;
                        });
                };

                scope.closeModal = () => {
                    DialogModal.hideAlerts();
                };
            },
        };
    },
]);
