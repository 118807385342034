import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/batch_edit_users.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('batchEditUsers', [
    '$injector',

    function factory($injector) {
        const $location = $injector.get('$location');
        const isMobileMixin = $injector.get('isMobileMixin');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const BatchEditUsersViewModel = $injector.get('BatchEditUsersViewModel');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                section: '@',
            },
            link(scope) {
                isMobileMixin.onLink(scope);
                AppHeaderViewModel.setTitleHTML('Batch Edit Users');
                AppHeaderViewModel.setBodyBackground('beige');
                AppHeaderViewModel.showAlternateHomeButton = false;

                // We hide the app header on mobile on this screen
                scope.$watch('isMobile', () => {
                    AppHeaderViewModel.toggleVisibility(!scope.isMobile);
                });

                scope.viewModel = BatchEditUsersViewModel.instance;

                // If, previously, a batch save was made and the user has not yet seen
                // the message that it was completed, then we will force the user to the
                // show-save-progress page and show the progress for that previous save.
                scope.viewModel.getQueuedJobBatchWatcherFromClientStorage();

                scope.availableSections = [
                    {
                        id: 'select-users',
                        label: '1. Select Users',
                    },
                    {
                        id: 'update-users',
                        label: '2. Update Users',
                    },
                    {
                        id: 'review-changes',
                        label: '3. Review Changes',
                    },
                    {
                        id: 'show-save-progress',
                        label: '4. Saving',
                    },
                ];

                function targetSectionFor(section) {
                    let targetSection;
                    const viewModel = scope.viewModel;

                    // Whenver we have a queuedJobBatchWatcher, we should be on the
                    // show-save-progress page
                    if (viewModel.queuedJobBatchWatcher || viewModel.saving) {
                        targetSection = 'show-save-progress';
                    } else if (section === 'update-users' && !_.some(viewModel.loadedUsers)) {
                        targetSection = 'select-users';
                    } else if (
                        section === 'review-changes' &&
                        !_.some(viewModel.loadedUsers) &&
                        !_.some(viewModel.updatedUsers)
                    ) {
                        targetSection = 'select-users';
                    } else if (
                        section === 'review-changes' &&
                        !viewModel.updateParams.status &&
                        !_.some(viewModel.updatedUsers)
                    ) {
                        targetSection = 'update-users';
                    }

                    // If we don't have a queuedJobBatchWatcher anymore, then we shouldn't
                    // be on the show-save-progress page, and we can start over
                    else if (section === 'show-save-progress') {
                        targetSection = 'select-users';
                    }

                    return targetSection;
                }

                scope.isSectionActive = section => {
                    const current = $location.path().replace('/admin/batch-users/', '');
                    if (section.id === current) {
                        return true;
                    }
                    return false;
                };

                scope.isSectionDisabled = section => !!targetSectionFor(section.id);

                scope.$watch('section', section => {
                    if (section) {
                        scope.mobileState.expanded = false;
                    }
                });

                scope.$watchGroup(
                    ['viewModel.loadedUsers', 'viewModel.updateParams', 'section', 'viewModel.queuedJobBatchWatcher'],
                    () => {
                        const targetSection = targetSectionFor(scope.section);
                        if (targetSection && targetSection !== scope.section) {
                            scope.viewModel.gotoSection(targetSection);
                        }
                    },
                );

                scope.mobileState = {
                    expanded: false,
                };

                scope.$on('$destroy', () => {
                    AppHeaderViewModel.toggleVisibility(true);
                    scope.viewModel.stopWatchingBatch();
                });
            },
        };
    },
]);
