import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_mba/admin_cohort_enrollment_status.html';
import customFieldsTemplate from 'Admin/angularModule/views/admin_mba/admin_cohort_enrollment_status_custom_fields.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { getTuitionContract, getEnrollmentAgreement, hasSignedEnrollmentAgreement } from 'Users';
import transformKeyCase from 'Utils/transformKeyCase';
import { getDownloadUrl as getSignableDocumentDownloadUrl, SIGNABLE_DOCUMENTS_UPLOAD_PATH } from 'SignableDocument';
import { statusOptionsWithChildren } from 'AdminUsers/StatusOptions';

const templateUrl = cacheAngularTemplate(angularModule, template);
const customTemplatePath = cacheAngularTemplate(angularModule, customFieldsTemplate);

angularModule.directive('adminCohortEnrollmentStatus', [
    '$injector',

    function factory($injector) {
        const $window = $injector.get('$window');
        const editContentItemListMixin = $injector.get('editContentItemListMixin');
        const AdminCohortStudentsTableHelper = $injector.get('AdminCohortStudentsTableHelper');
        const frontRoyalUpload = $injector.get('frontRoyalUpload');
        const DialogModal = $injector.get('DialogModal');
        const dateHelper = $injector.get('dateHelper');
        const PrivateUserDocumentsHelper = $injector.get('PrivateUserDocumentsHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                cohort: '<',
            },
            link(scope) {
                const adminCohortStudentsTableHelper = new AdminCohortStudentsTableHelper(
                    scope.cohort,
                    `${$window.ENDPOINT_ROOT}/api/users/batch_update_cohort_enrollment_status.json`,
                    {
                        indexParams: {
                            view: 'cohort_enrollment_status',
                            filters: {
                                program_rows: [
                                    {
                                        cohort_id: scope.cohort.id,
                                        status: statusOptionsWithChildren(['registered', 'matriculated', 'graduated']),
                                    },
                                ],
                            },
                        },
                    },
                );
                scope.adminCohortStudentsTableHelper = adminCohortStudentsTableHelper;

                adminCohortStudentsTableHelper.trackStudentChanges(student =>
                    student.user_id_verifications ? student.user_id_verifications.length : 0,
                );
                adminCohortStudentsTableHelper.trackStudentChanges(student => student.transcripts_verified);
                adminCohortStudentsTableHelper.trackStudentChanges(
                    student => student.english_language_proficiency_documents_approved,
                );

                scope.$on('$destroy', () => {
                    adminCohortStudentsTableHelper.destroy();
                });

                scope.setColumns = () => {
                    scope.columns = [
                        adminCohortStudentsTableHelper.getNameColumn(),
                        adminCohortStudentsTableHelper.getEmailColumn(),
                        {
                            id: 'identity-verified',
                            prop: 'identifiedForEnrollment',
                            type: 'checkIfTrue',
                            label: 'ID Verified?',
                            classes: ['text-center'],
                        },
                        {
                            id: 'transcripts-uploaded',
                            prop: 'requiredTranscriptsUploadedColumnString',
                            type: 'string',
                            label: 'Education with Transcripts Uploaded',
                            classes: ['text-center'],
                            classesCallback: (_column, careerProfile) => {
                                if (careerProfile.missingOfficialTranscripts) return ['beige'];
                                return undefined;
                            },
                            doNotExport: true,
                        },
                        {
                            id: 'transcripts-approved',
                            prop: 'requiredTranscriptsApprovedColumnString',
                            type: 'string',
                            label: 'Education with Transcripts Approved',
                            classes: ['text-center'],
                            classesCallback: (_column, careerProfile) => {
                                if (careerProfile.missingTranscriptApprovals) return ['beige'];
                                return undefined;
                            },
                            doNotExport: true,
                        },
                        {
                            id: 'has-uploaded-english-language-proficiency-documents',
                            prop: 'hasUploadedEnglishLanguageProficiencyDocuments',
                            type: 'custom',
                            label: 'Uploaded English Proficiency?',
                            classes: ['text-center'],
                            templateUrl: customTemplatePath,
                            callbacks: getCallbacksForCheckmarkColumn(
                                'english_language_proficiency_documents_approved',
                                'hasUploadedEnglishLanguageProficiencyDocuments',
                                'careerProfileIndicatesUserShouldUploadEnglishLanguageProficiencyDocuments',
                            ),
                        },
                        {
                            id: 'english-proficiency-documents-approved',
                            prop: 'english_language_proficiency_documents_approved',
                            type: 'custom',
                            label: 'English Proficiency Approved?',
                            classes: ['text-center'],
                            templateUrl: customTemplatePath,
                            callbacks: getCallbacksForCheckboxColumn(
                                'english_language_proficiency_documents_approved',
                                'careerProfileIndicatesUserShouldUploadEnglishLanguageProficiencyDocuments',
                            ),
                        },
                        {
                            id: 'has-signed-enrollment-agreement',
                            prop: 'has_signed_enrollment_agreement',
                            type: 'custom',
                            label: 'Agreement Signed?',
                            templateUrl: customTemplatePath,
                            cohortId: scope.cohort.id, // needed inside of admin_cohort_enrollment_status_custom_fields.html
                            classes: ['text-center'],
                            callbacks: {
                                sort(u) {
                                    return hasSignedEnrollmentAgreement(u, { programType: scope.cohort.programType });
                                },
                                downloadEnrollmentAgreement(student) {
                                    return PrivateUserDocumentsHelper.downloadDocument({
                                        downloadUrl: getSignableDocumentDownloadUrl(
                                            getEnrollmentAgreement(student, {
                                                programType: scope.cohort.programType,
                                            }).id,
                                        ),
                                        ...getEnrollmentAgreement(student, { programType: scope.cohort.programType }),
                                    });
                                },
                                status(student) {
                                    if (
                                        hasSignedEnrollmentAgreement(student, { programType: scope.cohort.programType })
                                    ) {
                                        return 'downloadLink';
                                    }
                                    return 'uploadLink';
                                },
                                onDocumentSelect(student, file, errFiles) {
                                    student.$$uploadingEnrollmentAgreement = true;

                                    frontRoyalUpload
                                        .handleNgfSelect(file, errFiles, _file => ({
                                            url: `${window.ENDPOINT_ROOT}${SIGNABLE_DOCUMENTS_UPLOAD_PATH}`,
                                            data: {
                                                record: {
                                                    file: _file,
                                                    user_id: student.id,
                                                    document_type: 'enrollment_agreement',
                                                    metadata: {
                                                        cohort_id: scope.cohort.id,
                                                        program_type: scope.cohort.programType,
                                                    },
                                                },
                                            },
                                            supportedFormatsForErrorMessage: '.pdf, .doc, .docx, .jpg, .png',
                                        }))
                                        .then(response => {
                                            const signableDocument = _.first(response.data.contents.signable_documents);
                                            student.signableDocuments = student.signableDocuments || [];
                                            student.signableDocuments.push(
                                                transformKeyCase(signableDocument, { to: 'camelCase' }),
                                            );
                                            getTuitionContract(student, {
                                                programType: scope.cohort.programType,
                                            }).enrollmentAgreementId = signableDocument.id;
                                        })
                                        .catch(err => {
                                            const message = err && err.message;
                                            DialogModal.alert({ content: message || 'Something went wrong' });
                                        })
                                        .finally(() => {
                                            student.$$uploadingEnrollmentAgreement = false;
                                        });
                                },
                                isDisabled(student) {
                                    return (
                                        adminCohortStudentsTableHelper.saving || student.$$uploadingEnrollmentAgreement
                                    );
                                },
                                getFormattedColumnValueForCSV(student) {
                                    return hasSignedEnrollmentAgreement(student, {
                                        programType: scope.cohort.programType,
                                    });
                                },
                            },
                        },
                        adminCohortStudentsTableHelper.getSaveColumn(),
                    ];

                    //----------------------------------------------------------
                    // Special-Case Handling for ID Verification Period Columns
                    //----------------------------------------------------------

                    const verificationPeriodPrefix = 'verification-period-';

                    const getIdVerificationPeriodColumn = (verificationPeriod, periodIndex) => ({
                        id: `${verificationPeriodPrefix}${periodIndex}`,
                        prop: `id_verification_period_${periodIndex}`,
                        type: 'custom',
                        label: `ID Verification ${dateHelper.formattedUserFacingMonthDayYearShort(
                            verificationPeriod.dueDate,
                            false,
                        )}`,
                        classes: ['text-center'],
                        templateUrl: customTemplatePath,
                        isIdVerificationPeriodColumn: true,
                        verificationPeriod,
                        callbacks: {
                            userIdVerification(user) {
                                return user.userIdVerificationForPeriod(verificationPeriod);
                            },
                            isSaving() {
                                return adminCohortStudentsTableHelper.saving;
                            },
                            getFormattedColumnValueForCSV(user) {
                                const verification = user.userIdVerificationForPeriod(verificationPeriod);
                                return verification && verification.verification_method;
                            },
                            sort(user) {
                                const verification = user.userIdVerificationForPeriod(verificationPeriod);

                                // when you first check a checkbox, before it is saved,
                                // we don't want the record to move in the list
                                if (!verification || !verification.verified_at) return 0;
                                return verification.verification_method;
                            },
                        },
                    });

                    // construct the ID verification period columns
                    const idVerificationColumns = [];
                    (scope.cohort.id_verification_periods || []).forEach((verificationPeriod, periodIndex) =>
                        idVerificationColumns.push(getIdVerificationPeriodColumn(verificationPeriod, periodIndex)),
                    );

                    // insert the ID verification period columns after the email column
                    const emailColumnIndex = scope.columns.findIndex(
                        column => column.id === AdminCohortStudentsTableHelper.EMAIL_COLUMN_ID,
                    );
                    scope.columns.splice(emailColumnIndex + 1, 0, ...idVerificationColumns);

                    // Remove and add columns specifically for the CSV export
                    scope.csvExportColumns = _.reject(
                        scope.columns,
                        column => column.id === 'editorAbilities' || !!column.doNotExport,
                    );
                    scope.csvExportColumns = scope.csvExportColumns.concat([
                        {
                            label: 'Education with Transcripts Required',
                            prop: 'career_profile.numRequiredTranscripts',
                            type: 'string',
                        },
                        {
                            label: 'Education with Transcripts Uploaded',
                            prop: 'career_profile.numRequiredTranscriptsUploaded',
                            type: 'string',
                        },
                        {
                            label: 'Education with Transcripts Waived',
                            prop: 'career_profile.numRequiredTranscriptsWaived',
                            type: 'string',
                        },
                        {
                            label: 'Education with Transcripts Approved',
                            prop: 'career_profile.numRequiredTranscriptsApproved',
                            type: 'string',
                        },
                    ]);
                };

                scope.$watch('cohort', scope.setColumns);

                function getCallbacksForCheckmarkColumn(
                    docsVerifiedProp,
                    hasUploadedProp,
                    indicatesUserShouldUploadProp,
                ) {
                    const callbacks = {};

                    // isVisible uses the student instead of the $$proxy because it is not changeable
                    callbacks.isVisible = function isVisible(student) {
                        return student[hasUploadedProp] || !student[indicatesUserShouldUploadProp];
                    };

                    // isDisabled uses the student instead of the $$proxy because it is not changeable
                    callbacks.isDisabled = function isDisabled(student) {
                        return !student[docsVerifiedProp] && !student[indicatesUserShouldUploadProp];
                    };

                    // sort uses the student instead of the proxy so that if we edit a user inline after
                    // sorting, the row will not move.  Unfortunately, this also means that if you edit a row
                    // and then sort (without saving), the sort will not be based on the changes you made, but
                    // rather on the underlying values.  Still seemed, though, that this is the best implementation.
                    callbacks.sort = function sort(student) {
                        if (student[hasUploadedProp]) return 1;
                        return 0;
                    };
                    callbacks.getFormattedColumnValueForCSV = student => {
                        if (student[hasUploadedProp]) return true;
                        if (!student[indicatesUserShouldUploadProp]) return 'Not required';
                        return false;
                    };

                    // When sorting, we always want to move users who do not need to upload this thing
                    // anyway to the end
                    callbacks.emptyToEnd = student => student[indicatesUserShouldUploadProp];
                    return callbacks;
                }

                function getCallbacksForCheckboxColumn(docsVerifiedProp, indicatesUserShouldUploadProp) {
                    const callbacks = {};

                    callbacks.onClick = student => {
                        student.$$proxy[docsVerifiedProp] = !student.$$proxy[docsVerifiedProp];
                    };

                    // isChecked is based on the proxy because the status of the checkbox should show the value
                    // on the proxy if it has been edited
                    callbacks.isChecked = student =>
                        student.$$proxy[docsVerifiedProp] || !student[indicatesUserShouldUploadProp];

                    // isDisabled is based on the student rather than the proxy because it is only based
                    // on things that cannot change.
                    callbacks.isDisabled = student =>
                        adminCohortStudentsTableHelper.saving || !student[indicatesUserShouldUploadProp];

                    // See comment above about why sort is based on the student instead of the proxy.
                    callbacks.sort = student => {
                        if (student[docsVerifiedProp]) return 1;
                        return 0;
                    };
                    callbacks.getFormattedColumnValueForCSV = student => {
                        if (student[docsVerifiedProp]) return true;
                        if (!student[indicatesUserShouldUploadProp]) return 'Not required';
                        return student[docsVerifiedProp];
                    };
                    callbacks.emptyToEnd = student => student[indicatesUserShouldUploadProp];
                    return callbacks;
                }

                //-------------------------
                // Filters
                //-------------------------

                scope.quickFilterProperties = ['name', 'email'];

                scope.missingRequiredDocumentsOptions = getBooleanFilterOptions(
                    'Missing Required Documents',
                    'Not Missing Required Documents',
                );
                scope.readyForApprovalOptions = getBooleanFilterOptions('Ready for Approval', 'Not Ready for Approval');

                function getBooleanFilterOptions(trueLabel, falseLabel) {
                    return [
                        { label: trueLabel, value: 'true' },
                        { label: falseLabel, value: 'false' },
                    ];
                }

                scope.missingRequiredDocumentsKey = 'missing_required_documents';
                scope.readyForApprovalKey = 'ready_for_approval';

                const booleanProperties = [scope.missingRequiredDocumentsKey, scope.readyForApprovalKey];

                // default filters
                const defaultFilters = _.map(
                    adminCohortStudentsTableHelper.indexParams.filters,
                    (filterValue, filterKey) => {
                        const filter = { server: true, default: true, value: {} };
                        filter.value[filterKey] = filterValue;
                        return filter;
                    },
                );

                // wire up filtering support
                editContentItemListMixin.onLink(
                    scope,
                    'adminEditCohortEnrollmentStatus',
                    defaultFilters,
                    booleanProperties,
                );

                scope.$watch('clientFilters', () => {
                    // remove deprecated filters
                    delete scope.clientFilters.missingRequiredDocumentsLegacy;
                    delete scope.clientFilters.readyForApprovalLegacy;
                    delete scope.clientFilters.isAccepted;

                    delete scope.clientFilters.missingRequiredDocuments;
                    delete scope.clientFilters.readyForApproval;
                    delete scope.clientFilters.isCurrentOrHasCompletedActiveProgram;

                    delete scope.clientFilters.current_or_graduated_or_failed;
                });
            },
        };
    },
]);
