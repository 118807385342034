import angularModule from 'Admin/angularModule/scripts/admin_module';
import AdminStudentNetworkEventsTable from 'AdminStudentNetworkEventsTable';
import AdminGroups from 'AdminGroups';
import { react2Angular } from 'FrontRoyalReact2Angular';
import template from 'Admin/angularModule/views/admin_mba.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.component('adminStudentNetworkEventsTable', react2Angular(AdminStudentNetworkEventsTable, [], '', false));

angularModule.component('adminGroups', react2Angular(AdminGroups, [], '', false));

angularModule.directive('adminMba', [
    '$injector',

    function factory($injector) {
        const $location = $injector.get('$location');
        const isMobileMixin = $injector.get('isMobileMixin');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const ngToast = $injector.get('ngToast');
        const $window = $injector.get('$window');
        const Cohort = $injector.get('Cohort');
        const CohortPromotion = $injector.get('CohortPromotion');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                section: '@',
            },
            link(scope) {
                //---------------------------
                // Initialization
                //---------------------------

                scope.proxy = {};
                isMobileMixin.onLink(scope);
                AppHeaderViewModel.setTitleHTML('Manage MBA and Institutions');
                AppHeaderViewModel.setBodyBackground('beige');
                AppHeaderViewModel.showAlternateHomeButton = false;

                // We hide the app header on mobile on this screen
                scope.$watch('isMobile', () => {
                    AppHeaderViewModel.toggleVisibility(!scope.isMobile);
                });

                scope.availableSections = [
                    {
                        id: 'calendar',
                        label: 'Calendar',
                    },
                    {
                        id: 'cohorts',
                        label: 'Cohorts',
                    },
                    {
                        id: 'curriculum_templates',
                        label: 'Curriculum Templates',
                    },
                    {
                        id: 'projects',
                        label: 'Projects',
                    },
                    {
                        id: 'student_network_events',
                        label: 'Events',
                    },
                    {
                        id: 'institutions',
                        label: 'Institutions',
                    },
                    {
                        id: 'groups',
                        label: 'Groups',
                    },
                ];

                // FIXME: This needs to be DRY'ed up with /careers and /settings
                scope.gotoSection = section => {
                    $location.url(`/admin/mba/${section}`);
                };

                scope.isSectionActive = section => {
                    const current = $location.path().replace('/admin/mba/', '');
                    if (section.id === current) {
                        return true;
                    }

                    return false;
                };

                scope.getSectionLabel = () => {
                    const section = _.find(scope.availableSections, {
                        id: scope.section,
                    });
                    return section.label;
                };

                //---------------------------
                // Cohorts
                //---------------------------

                // FIXME: It's probably not the case that all of these
                // requests need both cohort promotions and stripe
                // metadata, but also we're probably making more
                // requests than we need to.
                scope.indexParamsForCohorts = {
                    get_cohort_promotions: true,
                    get_learner_projects: true,
                    get_available_cohort_sections: true,
                    fields: ['ADMIN_FIELDS'],
                };

                scope.indexParamsForStudentNetworkEvents = {
                    filters: {
                        editor: true,
                        start_time: 0,
                        end_time: new Date('2099/01/01').getTime(),
                    },
                    fields: ['ADMIN_FIELDS'],
                };

                scope.indexParamsForCurriculumTemplates = {
                    get_learner_projects: true,
                };

                scope.filtersForCohorts = [
                    {
                        type: 'hidden',
                        server: true,
                        value: {
                            name: true,
                        },
                    },
                ];

                scope.columnsForCohorts = [
                    {
                        prop: 'name',
                        type: 'text',
                        label: 'Name',
                    },
                    {
                        prop: 'title',
                        type: 'text',
                        label: 'Title',
                    },
                    {
                        prop: 'program_type',
                        type: 'text',
                        label: 'Program Type',
                    },
                    {
                        prop: 'start_date',
                        type: 'time',
                        label: 'Start Date',
                    },
                    {
                        prop: 'end_date',
                        type: 'time',
                        label: 'End Date',
                    },
                    {
                        prop: 'updated_at',
                        type: 'time',
                        label: 'Modified',
                    },
                    {
                        prop: 'published_at',
                        type: 'time',
                        label: 'Published',
                    },
                ];

                //---------------------------
                // Curriculum Templates
                //---------------------------

                scope.filtersForCurriculumTemplates = [
                    {
                        type: 'hidden',
                        server: true,
                        value: {
                            name: true,
                        },
                    },
                ];

                scope.columnsForCurriculumTemplates = [
                    {
                        prop: 'name',
                        type: 'text',
                        label: 'Name',
                    },
                    {
                        prop: 'program_type',
                        type: 'text',
                        label: 'Program Type',
                    },
                ];

                scope.fieldsForCurriculumTemplates = ['name', 'description'];

                //---------------------------
                // Projects
                //---------------------------

                scope.columnsForProjects = [
                    {
                        prop: 'tag',
                        type: 'text',
                        label: 'Tag',
                    },
                    {
                        prop: 'requirement_identifier',
                        type: 'text',
                        label: 'Fulfills Req.',
                    },
                    {
                        prop: 'title',
                        type: 'text',
                        label: 'Title',
                    },
                    {
                        prop: 'updated_at',
                        type: 'time',
                        label: 'Modified',
                    },
                ];

                //---------------------------
                // Institutions
                //---------------------------

                scope.filtersForInstitutions = [
                    {
                        type: 'hidden',
                        sever: true,
                        value: {
                            name: true,
                        },
                    },
                ];

                scope.columnsForInstitutions = [
                    {
                        prop: 'name',
                        type: 'text',
                        label: 'Name',
                    },
                    {
                        prop: 'groupNamesString',
                        type: 'text',
                        label: 'Groups',
                    },
                    {
                        prop: 'sign_up_code',
                        type: 'text',
                        label: 'Sign Up Code',
                    },
                    {
                        prop: 'created_at',
                        type: 'time',
                        label: 'Created',
                    },
                    {
                        prop: 'updated_at',
                        type: 'time',
                        label: 'Modified',
                    },
                    {
                        prop: 'ios_url',
                        type: 'text',
                        label: 'iOS App URL',
                    },
                    {
                        prop: 'android_url',
                        type: 'text',
                        label: 'Android App URL',
                    },
                    {
                        prop: 'privacy_policy_url',
                        type: 'text',
                        label: 'Privacy Policy URL',
                    },
                    {
                        prop: 'terms_url',
                        type: 'text',
                        label: 'Terms URL',
                    },
                ];

                //---------------------------
                // Groups
                //---------------------------

                scope.filtersForGroups = [
                    {
                        type: 'hidden',
                        sever: true,
                        value: {
                            name: true,
                        },
                    },
                ];

                scope.columnsForGroups = [
                    {
                        prop: 'name',
                        type: 'text',
                        label: 'Name',
                    },
                    {
                        prop: 'created_at',
                        type: 'time',
                        label: 'Created',
                    },
                    {
                        prop: 'updated_at',
                        type: 'time',
                        label: 'Modified',
                    },
                ];

                scope.columnsForStudentNetworkEvents = [
                    {
                        prop: 'title',
                        type: 'text',
                        label: 'Title',
                    },
                    {
                        prop: 'start_time',
                        type: 'time',
                        label: 'Start',
                    },
                ];

                //---------------------------
                // Promoted cohort logic
                //---------------------------

                scope.promotedCohortSelected = cohortPromotionViewModel => {
                    const newPromotedCohort = _.find(cohortPromotionViewModel.availableCohorts, {
                        id: cohortPromotionViewModel.cohortPromotion.cohort_id,
                    });

                    if (
                        $window.confirm(
                            `Are you sure you want to change the promoted cohort to ${newPromotedCohort.name}?`,
                        )
                    ) {
                        cohortPromotionViewModel.cohortPromotion.save().then(() => {
                            cohortPromotionViewModel.origPromotedCohortId =
                                cohortPromotionViewModel.cohortPromotion.cohort_id;
                            ngToast.create({
                                content: 'Promoted cohort updated',
                                className: 'success',
                            });
                        });
                    } else {
                        cohortPromotionViewModel.cohortPromotion.cohort_id =
                            cohortPromotionViewModel.origPromotedCohortId;
                    }
                };

                // These things are passed up from the editable-things-list
                scope.editableThings = {};

                function rebuildCohortPromotions() {
                    const indexCallResponseMeta = scope.editableThings.indexCallResponseMeta;
                    scope.cohorts = scope.editableThings.cohorts;

                    if (!scope.cohorts || !indexCallResponseMeta) {
                        return;
                    }
                    const cohortPromotions = indexCallResponseMeta.cohort_promotions;
                    scope.cohortPromotionViewModels = _.map(Cohort.promotableProgramTypes, programType => {
                        const cohortPromotionAttrs = _.find(cohortPromotions, {
                            program_type: programType.key,
                        });
                        const cohortPromotion = CohortPromotion.new(cohortPromotionAttrs || {});
                        const cohortsForProgramType = _.chain(scope.cohorts)
                            .filter({
                                program_type: programType.key,
                            })
                            .sortBy('name')
                            .value();
                        let promotedCohort;
                        if (cohortPromotion.cohort_id) {
                            promotedCohort = _.find(cohortsForProgramType, {
                                id: cohortPromotion.cohort_id,
                            });

                            if (!promotedCohort) {
                                throw new Error('promotedCohort not found');
                            }
                        }

                        const viewModel = {
                            programType,
                            cohortPromotion,
                            availableCohorts: cohortsForProgramType,
                            origPromotedCohortId: cohortPromotion.cohort_id,
                        };

                        return viewModel;
                    });
                }

                // In order to keep the lists of promotable cohorts current,
                // we need to update whenever a cohort is added, removed or changes program type,
                // or whenever we get new information back in the meta from the
                // server about which cohorts are currently promoted
                scope.$watch(
                    () => ({
                        cohorts: _.chain(scope.cohorts)
                            .map(cohort => ({
                                id: cohort.id,
                                programType: cohort.program_type,
                            }))
                            .value(),

                        indexCallResponseMeta: scope.editableThings.indexCallResponseMeta,
                    }),
                    rebuildCohortPromotions,
                    true,
                );

                //---------------------------
                // Other
                //---------------------------

                // Detect if in an edit directive
                scope.$watch('proxy.visibleThing', thing => {
                    scope.createOrEditMode = !!thing;
                });

                scope.$on('$destroy', () => {
                    AppHeaderViewModel.toggleVisibility(true);
                });
            },
        };
    },
]);
