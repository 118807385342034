import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/edit_project_resources.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editProjectResources', [
    '$injector',

    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                ngModel: '<',
                availableLearnerProjects: '<',
            },
            link(scope) {
                function setSelectedLearnerProjects() {
                    // ensure that the learner_projects array gets updated based on the selected project ids
                    scope.selectedLearnerProjects = _.chain(scope.ngModel.learner_project_ids)
                        .map(id =>
                            _.find(scope.availableLearnerProjects, {
                                id,
                            }),
                        )
                        .compact()
                        .value();
                    scope.ngModel.learner_projects = scope.selectedLearnerProjects;
                }

                scope.$watchCollection('ngModel.learner_project_ids', setSelectedLearnerProjects);
                scope.$watch('availableLearnerProjects', setSelectedLearnerProjects);

                scope.orderBySortKey = option => option.sortKey;

                // Could potentially DRY this up with form_helper.js#moveOne and cfReorderListItemButtons
                // Or could move this to schedulable.js
                scope.moveCollectionOne = (collection, currentIndex, movement) => {
                    const newIndex = currentIndex + movement;

                    if (Math.abs(movement) > 1) {
                        throw new Error('This function only allows movement of one');
                    }

                    // return if trying to move the item outside the collection bounds
                    if (newIndex > collection.length - 1 || newIndex < 0) {
                        return;
                    }

                    // get the item at the position where the moving item will be placed
                    const item = collection[newIndex];

                    // // move the item
                    collection[newIndex] = collection[currentIndex];

                    // // replace the original item at this spot
                    collection[currentIndex] = item;
                };
            },
        };
    },
]);
