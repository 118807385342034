import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/edit_institution.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editInstitution', [
    '$injector',

    function factory($injector) {
        const ngToast = $injector.get('ngToast');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                institution: '<',
            },
            link(scope) {
                scope.updateInstitution = () => {
                    scope.preventUpdate = true;
                    scope.institution.save().then(() => {
                        ngToast.create({
                            content: `${scope.institution.name} updated.`,
                            className: 'success',
                        });

                        scope.preventUpdate = false;
                    });
                };
            },
        };
    },
]);
