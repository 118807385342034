import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/activity_timeline.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { getDownloadUrl as getSignableDocumentDownloadUrl } from 'SignableDocument';

import trashcanBeige from 'vectors/trashcan_beige.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('activityTimeline', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const UserTimeline = $injector.get('UserTimeline');
        const PersistedTimelineEvent = $injector.get('PersistedTimelineEvent');
        const PrivateUserDocumentsHelper = $injector.get('PrivateUserDocumentsHelper');
        const S3TranscriptAsset = $injector.get('S3TranscriptAsset');
        const S3EnglishLanguageProficiencyDocument = $injector.get('S3EnglishLanguageProficiencyDocument');

        const downloadAbleDocumentKlasses = {
            SignableDocument: null, // there is no class for these. They are handled as vanilla objects
            S3TranscriptAsset,
            S3EnglishLanguageProficiencyDocument,
        };

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                section: '@',
                user: '<',
            },
            link(scope) {
                scope.trashcanBeige = trashcanBeige;

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.filter = 'all';

                scope.categories = {
                    all: 'All types',
                    user: 'User',
                    program_application: 'Program Application',
                    admission_offer: 'Admission Offer',
                    program_inclusion: 'Program Inclusion',
                    tuition_contract: 'Tuition Contract',
                    enrollment: 'Enrollment',
                    career_profile: 'Career Profile',
                    project: 'Project',
                    student_network: 'Network',
                    exam: 'Exam',
                    note: 'Note',
                };

                scope.$watch('user', user => {
                    scope.timeline = null;
                    if (!user) {
                        return;
                    }

                    UserTimeline.show(user.id).then(response => {
                        scope.timeline = response.result;
                    });
                });

                scope.$watchCollection('timeline.events', events => {
                    scope.sortedEvents = events ? events.sort(sortEvents) : null;
                });

                // Returns an array containing the change. `null`, `undefined`, and empty array values
                // will be shown in the UI as a single pill containing the word `BLANK`. Note that if
                // `val` is a non-empty array, the contents of the array will be shown as a series of
                // pills rather than as a single really large pill with the array syntax inside of it.
                scope.formattedChange = val => {
                    if (val === null || val === undefined || (Array.isArray(val) && val.length === 0)) {
                        return ['blank'];
                    }

                    if (Array.isArray(val)) {
                        return val;
                    }

                    return [val];
                };

                function sortEvents(a, b) {
                    if (a.time < b.time) {
                        return 1;
                    }
                    if (a.time > b.time) {
                        return -1;
                    }
                    if (a.secondary_sort < b.secondary_sort) {
                        return 1;
                    }
                    if (a.secondary_sort > b.secondary_sort) {
                        return -1;
                    }
                    return 0;
                }
                scope.timelineClasses = (item, nextItem) => {
                    const nextTime = nextItem ? nextItem.time : item.time;
                    const daysUntilNext = (item.time - nextTime) / 60 / 60 / 24;
                    return [item.category, item.event, daysUntilNext >= 1 ? 'spacer' : undefined];
                };

                /** ********************************* */
                // notes
                /** ************************************* */
                scope.noteTime = null;

                scope.addNote = () => {
                    const obj = {
                        category: 'note',
                        event: 'note',
                        time: (scope.noteTime || new Date()).getTime() / 1000,
                        text: scope.note,

                        // editor_name is set here to populate the UI, but the server is going rely
                        // on the currently logged in user
                        editor_name: scope.currentUser.name,
                    };

                    scope.timeline.events.unshift(obj);

                    delete scope.note;
                    scope.noteTime = null;

                    PersistedTimelineEvent.create(obj, {
                        // We send up information about the user that this event relates to
                        // in the meta.  The server will create the necessary relationship
                        object: 'user',
                        object_id: scope.user.id,
                    }).then(response => {
                        obj.id = response.result.id;
                    });
                };

                scope.removeNote = item => {
                    scope.timeline.events = _.without(scope.timeline.events, item);

                    PersistedTimelineEvent.destroy(item.id);
                };

                const initDocumentFromKlassString = item => {
                    // The `item` is a _versions record embedded in a
                    // derived timeline event. In some cases, we need to initialize the data into
                    // the relevant klass so we can use PrivateUserDocumentsHelper
                    // to download it (see PrivateUserDocumentsHelper.downloadDocument)
                    const klass = downloadAbleDocumentKlasses[item.document_klass];

                    if (klass) return klass.new(item.document_attrs);

                    if (klass === null && item.document_klass === 'SignableDocument')
                        return {
                            ...item.document_attrs,
                            downloadUrl: getSignableDocumentDownloadUrl(item.document_attrs.id),
                        };

                    throw new Error(`Invalid item: ${item.document_klass}`);
                };

                scope.downloadDocument = item => {
                    const document = initDocumentFromKlassString(item);
                    const config = {
                        'FrontRoyal.ApiErrorHandler': {
                            skip: true,
                        },
                    };

                    return PrivateUserDocumentsHelper.downloadDocument(document, config);
                };
            },
        };
    },
]);
