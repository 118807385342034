import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_mba/edit_project_documents.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editProjectDocuments', [
    '$injector',

    function factory($injector) {
        const frontRoyalUpload = $injector.get('frontRoyalUpload');
        const $window = $injector.get('$window');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                ngModel: '<',
            },
            link(scope) {
                // Could potentially DRY this up with form_helper.js#moveOne and cfReorderListItemButtons
                // Or could move this to schedulable.js
                scope.moveCollectionOne = (collection, currentIndex, movement) => {
                    const newIndex = currentIndex + movement;

                    if (Math.abs(movement) > 1) {
                        throw 'This function only allows movement of one';
                    }

                    // return if trying to move the item outside the collection bounds
                    if (newIndex > collection.length - 1 || newIndex < 0) {
                        return;
                    }

                    // get the item at the position where the moving item will be placed
                    const item = collection[newIndex];

                    // // move the item
                    collection[newIndex] = collection[currentIndex];

                    // // replace the original item at this spot
                    collection[currentIndex] = item;
                };

                //----------------------------
                // Project Documents
                //----------------------------

                scope.onProjectDocumentSelect = (file, errFiles) => {
                    scope.projectDocumentErrMessage = null;
                    scope.uploadingProjectDocument = true;

                    frontRoyalUpload
                        .handleNgfSelect(file, errFiles, file => ({
                            url: `${$window.ENDPOINT_ROOT}/api/cohorts/upload_project_document.json`,
                            data: {
                                file,
                            },
                        }))
                        .then(response => {
                            scope.ngModel.addProjectDocument(response.data.contents.project_document);
                        })
                        .catch(err => {
                            scope.projectDocumentErrMessage = err && err.message;
                        })
                        .finally(() => {
                            scope.uploadingProjectDocument = false;
                        });
                };

                scope.openProjectDocument = projectDocument => {
                    NavigationHelperMixin.loadUrl(projectDocument.url, '_blank');
                };
            },
        };
    },
]);
