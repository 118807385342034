import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_mba/admin_cohort_actions.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { QueuedJobBatchWatcher } from 'QueuedJob';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminCohortActions', [
    '$injector',

    function factory($injector) {
        const $http = $injector.get('$http');
        const $rootScope = $injector.get('$rootScope');
        const safeApply = $injector.get('safeApply');
        const BATCH_WATCHER_KEY_PREFIX = 'AdminCohortActions.unlockContentJobWatcher-';

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                cohort: '<',
            },
            link(scope) {
                const BATCH_WATCHER_KEY = BATCH_WATCHER_KEY_PREFIX + scope.cohort.id;

                // show the progress for that previous save.
                scope.queuedJobBatchWatcher = QueuedJobBatchWatcher.load(BATCH_WATCHER_KEY, $injector);
                if (scope.queuedJobBatchWatcher) {
                    watchQueuedJobBatch();
                }

                scope.saving = false;

                scope.unlockContent = () => {
                    scope.saving = true;

                    $http
                        .put(`${window.ENDPOINT_ROOT}/api/cohorts/unlock_content.json`, {
                            id: scope.cohort.id,
                        })
                        .then(response => {
                            const { id: batchId, total_count: totalCount } = response.data.contents;

                            if (totalCount > 0) {
                                createQueuedJobBatchWatcher({
                                    batchId,
                                    totalCount,
                                });
                            }

                            scope.cohort.content_unlocked = true;
                        })
                        .finally(() => {
                            scope.saving = false;
                        });
                };

                Object.defineProperty(scope, 'showLoading', {
                    get() {
                        return scope.saving || scope.queuedJobBatchWatcher?.pendingCount > 0;
                    },
                });

                scope.$on('$destroy', () => {
                    stopWatchingBatch();
                });

                // When we navigate away, we need to stop watching the batch so it doesn't keep making api calls
                function stopWatchingBatch() {
                    if (scope.queuedJobBatchWatcher) {
                        scope.queuedJobBatchWatcher.cancel();
                        scope.queuedJobBatchWatcher = null;
                    }
                }

                // create a new QueuedJobBatchWatcher right after queuing up a list of program_inclusions to save
                function createQueuedJobBatchWatcher(batchInfo) {
                    scope.queuedJobBatchWatcher = new QueuedJobBatchWatcher(batchInfo.batchId, $injector, {
                        totalCount: batchInfo.totalCount,
                        pendingCount: batchInfo.totalCount,
                    }).store(BATCH_WATCHER_KEY);
                    watchQueuedJobBatch();
                }

                // call watch() on a QueuedJobBatchWatcher, which might have been created with createQueuedJobBatchWatcher
                // or might have been loaded from local storage
                function watchQueuedJobBatch() {
                    scope.queuedJobBatchWatcher.watch({ onProgress: safeApply }).then(() => {
                        unstoreQueuedJobBatch();

                        // If there are errors, then we don't want to hide the message from the user
                        if (scope.queuedJobBatchWatcher.errorCount === 0) {
                            scope.queuedJobBatchWatcher = null;
                        }
                        safeApply($rootScope);
                    });
                }

                // remove the QueuedJobBatchWatcher from local storage
                function unstoreQueuedJobBatch() {
                    QueuedJobBatchWatcher.unstore(BATCH_WATCHER_KEY, $injector);
                }
            },
        };
    },
]);
