import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_users/lesson_permissions.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('lessonPermissions', [
    '$injector',

    function factory($injector) {
        const User = $injector.get('User');
        const LessonsCtrlBase = $injector.get('LessonsCtrlBase');
        const HasSortableColumnsMixin = $injector.get('HasSortableColumnsMixin');
        const ngToast = $injector.get('ngToast');
        const safeDigest = $injector.get('safeDigest');
        const Locale = $injector.get('Locale');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                user: '<',
            },
            link(scope) {
                //-------------------------
                // Lesson Permissions
                //-------------------------
                HasSortableColumnsMixin.onLink(scope);

                scope.Locale = Locale;

                // by default, sort columns by tag
                scope.sort = {
                    column: 'tag',
                    descending: false,
                };

                // default to display assigned tab if user already has assigned lessons
                scope.currentTab = Object.keys(scope.user.lessonPermissions).length > 0 ? 'assigned' : 'unassigned';
                setUserProxy(scope.user);

                function setUserProxy(user) {
                    scope.user.copyAttrs(user.asJson());
                    scope.userProxy = User.new(user.asJson());
                }

                initializeFilters();

                function updateAllLessonPermissions(tabType, roleName) {
                    const lessons = scope.filteredLessonsByTab[tabType];
                    if (lessons) {
                        lessons.forEach(lesson => {
                            if (scope.userProxy.id !== lesson.author.id) {
                                scope.updateLessonPermissions(lesson, roleName);
                            }
                        });
                    }
                }

                scope.initializeLessons = () => {
                    if (!scope.lessons) {
                        return;
                    }

                    // create a lookup hash for our tabbed interface
                    scope.lessonsByTab = {
                        assigned: [],
                        unassigned: [],
                    };

                    scope.userProxy.$$lessonPermissions = undefined;

                    // build out lookup hash and ensure authors always retain edit permissions
                    scope.lessons.forEach(lesson => {
                        if (lesson.id) {
                            // find and set editor permissions on any lessons where the userProxy is the author
                            if (lesson.author && lesson.author.id === scope.userProxy.id) {
                                scope.updateLessonPermissions(lesson, 'lesson_editor');
                            }

                            // place in the right tab filtering
                            if (scope.userProxy.lessonPermissions[lesson.id]) {
                                scope.lessonsByTab.assigned.push(lesson);
                            } else {
                                scope.lessonsByTab.unassigned.push(lesson);
                            }
                        }
                    });
                };

                // initialize lesson permissions on update
                scope.$watch('lessons', scope.initializeLessons);

                // Load lessons
                scope.$watch('userProxy.globalRole.name', roleName => {
                    if (roleName === 'editor') {
                        // eslint-disable-next-line no-new
                        new LessonsCtrlBase(scope);
                    }
                });

                scope.onAllClick = (tabType, roleName) => {
                    updateAllLessonPermissions(tabType, roleName);
                    scope.updateUserDetails();
                };

                scope.onSingleClick = (lesson, roleName) => {
                    scope.updateLessonPermissions(lesson, roleName);
                    scope.updateUserDetails();
                };

                scope.updateLessonPermissions = (lesson, roleName) => {
                    let i = 0;
                    const indicesToRemove = [];
                    // find indices of all other lesson override roles for this lessonId
                    scope.userProxy.roles.forEach(role => {
                        if (
                            role.resource_id === lesson.id &&
                            (!roleName || scope.userProxy.isScopedlessonPermission(role.name))
                        ) {
                            indicesToRemove.push(i);
                        }
                        i += 1;
                    });

                    // remove all indicesToRemove from lesson override roles
                    for (i = indicesToRemove.length - 1; i >= 0; i--) {
                        scope.userProxy.roles.splice(indicesToRemove[i], 1);
                    }

                    // add this lesson override role if supplied (otherwise defaults to hidden)
                    if (roleName) {
                        const newRole = {
                            resource_id: lesson.id,
                            resource_type: 'Lesson',
                            name: roleName,
                        };
                        scope.userProxy.roles.push(newRole);
                    }

                    // clear out the cached version of lessonPermissions so the next getter refreshes it
                    scope.userProxy.$$lessonPermissions = undefined;
                };

                scope.$watch('userProxy.lessonPermissions', () => {
                    if (!scope.lessonsByTab?.assigned || !Object.keys(scope.userProxy.lessonPermissions).length) {
                        scope.numOfAssignedLessonsText = '0';
                        return;
                    }

                    const savedLessonIds = scope.lessonsByTab.assigned.map(lesson => lesson.id);
                    const allPermittedLessonIds = Object.keys(scope.userProxy.lessonPermissions);

                    const numOfLessonsSaved = savedLessonIds.length;
                    const numOfLessonsPendingSave = allPermittedLessonIds.filter(lessonId => {
                        // If `archived` is false, then we won't retrieve archived lessons
                        // from the lessons controller. It's still possible for them to show up in a user's
                        // lessonPermissions, though. In that case, those records aren't actually "pending save"
                        // and shouldn't be counted in this list.
                        if (!scope.archived && !scope.lessons.find(l => l.id === lessonId)) {
                            return false;
                        }

                        if (!savedLessonIds.includes(lessonId)) {
                            return true;
                        }

                        return false;
                    }).length;

                    scope.numOfAssignedLessonsText = `${numOfLessonsSaved}${
                        numOfLessonsPendingSave ? ` (${numOfLessonsPendingSave} pending)` : ''
                    }`;
                });

                //-------------------------
                // Infinite Scrolling/Pagination
                //-------------------------

                const DEFAULT_NUM_LESSONS_TO_SHOW = 50;
                scope.numOfLessonsToShow = DEFAULT_NUM_LESSONS_TO_SHOW;
                const lastRowSelector = () =>
                    `[data-id="${scope.currentTab}"] .fixed-table-container-inner tbody tr:last-of-type`;
                const observerCallback = (entries, obs) => {
                    entries.forEach(entry => {
                        if (
                            entry.isIntersecting &&
                            scope.numOfLessonsToShow < scope.filteredLessonsByTab[scope.currentTab].length
                        ) {
                            scope.numOfLessonsToShow += 25;
                            safeDigest(scope);
                            obs.unobserve(entry.target);
                            obs.observe(document.querySelector(lastRowSelector()));
                        }
                    });
                };

                const observer = new IntersectionObserver(observerCallback);
                function resetObserver() {
                    observer.disconnect();

                    setTimeout(() => {
                        const element = document.querySelector(lastRowSelector());
                        if (element) {
                            observer.observe(element);
                        }
                    }, 0);
                }

                scope.$watchGroup(['filteredLessonsByTab.unassigned', 'filteredLessonsByTab.assigned'], () => {
                    resetObserver();
                });

                //-------------------------
                // Client-side Table Filtering
                //-------------------------

                function scrollToTop() {
                    const tableScrollArea = document.querySelector(
                        `[data-id="${scope.currentTab}"] .fixed-table-container-inner`,
                    );
                    if (tableScrollArea) {
                        tableScrollArea.scrollTop = 0;
                        scope.numOfLessonsToShow = DEFAULT_NUM_LESSONS_TO_SHOW;
                        resetObserver();
                    }
                }

                function initializeFilters() {
                    scope.clientFilters = {
                        locale: '',
                        tag: '',
                        title: '',
                    };

                    scope.filteredLessonsByTab = {
                        assigned: scope.lessonsByTab?.assigned || [],
                        unassigned: scope.lessonsByTab?.unassigned || [],
                    };
                }

                function applyFilters() {
                    if (!scope.lessonsByTab) {
                        return;
                    }

                    const filteredLessons = scope.lessonsByTab[scope.currentTab].filter(lesson => {
                        const { locale, tag, title } = scope.clientFilters;

                        const localeFilterPassing = !locale || locale === lesson.locale;
                        const tagFilterPassing =
                            !tag || lesson.tag?.toLocaleLowerCase()?.includes(tag.toLocaleLowerCase());
                        const titleFilterPassing =
                            !title || lesson.title?.toLocaleLowerCase()?.includes(title.toLocaleLowerCase());

                        return localeFilterPassing && tagFilterPassing && titleFilterPassing;
                    });

                    scope.filteredLessonsByTab[scope.currentTab] = filteredLessons;
                }

                scope.changeTab = tabType => {
                    scope.currentTab = tabType;
                    initializeFilters();
                    applyFilters();
                };

                scope.$watchGroup(
                    ['lessonsByTab', 'clientFilters.locale', 'clientFilters.tag', 'clientFilters.title'],
                    () => {
                        applyFilters();
                        scrollToTop();
                    },
                );

                scope.$watchGroup(['sort.column', 'sort.descending'], () => {
                    scrollToTop();
                });

                scope.sortOverride = lesson => {
                    if (scope.sort.column === 'tag' || scope.sort.column === 'title') {
                        return lesson[scope.sort.column];
                    }

                    if (scope.sort.column === 'hidden') {
                        return Object.keys(scope.userProxy.lessonPermissions).includes(lesson.id);
                    }

                    return scope.userProxy.lessonPermissions[lesson.id] !== scope.sort.column;
                };

                scope.updateUserDetails = () => {
                    scope.preventUpdate = true;

                    scope.userProxy
                        .save()
                        .then(response => {
                            setUserProxy(response.result);

                            ngToast.create({
                                content: `${scope.userProxy.accountId} updated.`,
                                className: 'success',
                            });

                            scope.initializeLessons();
                        })
                        .finally(() => {
                            scope.preventUpdate = false;
                        });
                };

                scope.updateCanCreateLessons = () => {
                    scope.userProxy.canCreateLessons = !scope.userProxy.canCreateLessons;
                    scope.updateUserDetails();
                };

                scope.canCreateLessonClasses = canCreateLessons => `btn btn-${canCreateLessons ? 'success' : 'danger'}`;
                scope.canCreateLessonMessage = canCreateLessons =>
                    `Lesson Creation ${canCreateLessons ? 'Enabled' : 'Disabled'}`;
            },
        };
    },
]);
