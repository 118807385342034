import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/edit_period_exercise.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editPeriodExercise', [
    '$injector',

    function factory($injector) {
        const frontRoyalUpload = $injector.get('frontRoyalUpload');
        const $window = $injector.get('$window');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                exercise: '<',
                period: '=',
            },
            link(scope) {
                scope.onFileSelect = (file, errFiles, obj) => {
                    scope.fileErrMessage = null;
                    scope.uploadingFile = true;

                    frontRoyalUpload
                        .handleNgfSelect(file, errFiles, () => ({
                            url: `${$window.ENDPOINT_ROOT}/api/cohorts/upload_exercise_document.json`,
                            data: {
                                file,
                            },
                        }))
                        .then(response => {
                            const document = response.data.contents.exercise_document;
                            document.title = document.file_file_name; // default to the file_name
                            obj.document = document;
                        })
                        .catch(err => {
                            scope.fileErrMessage = err && err.message;
                        })
                        .finally(() => {
                            scope.uploadingFile = false;
                        });
                };

                scope.duplicateExercise = exercise => {
                    if (exercise && typeof exercise === 'object') {
                        const { id, ...attrs } = exercise;

                        scope.period.addExercise(attrs);
                    }
                };

                scope.openDocument = document => {
                    NavigationHelperMixin.loadUrl(document.url, '_blank');
                };

                /* ----------------------- slack-room-specific setters ----------------------- */
                /*
                        Behavior:
                        For both messages and documents, the admin has the choice of setting one
                        value for the exercise or setting distinct values for each slack room.  If the
                        user is setting distinct values for each slack room, then all rooms must have a
                        value set.  This is enforced with a server-side validation.

                        Implementation:
                        `scope.messageDetailEntries.message` and `scope.messageDetailEntries.document` keep
                        track of the objects on which the admin
                        is setting `message` and `document` values. If the admin is setting just one
                        value for the exercise, then there will be only one entry in the list
                        and it will reference the exercise object itself.  If the admin is setting distinct
                        values for each slack room, then there will be one entry for each room.

                        For example, if an admin has decided to set distinct messages for each slack room,
                        but to use the same document for all, then it would look like this:

                        scope.messageDetailEntries
                        => {
                            message: [
                                {
                                    title: 'Slack Room 1',
                                    obj: scope.exercise.slack_room_overrides.slack_room_1_id
                                },
                                {
                                    title: 'Slack Room 2',
                                    obj: scope.exercise.slack_room_overrides.slack_room_2_id
                                }
                            ],
                            document: [
                                {
                                    title: null,
                                    obj: scope.exercise
                                }
                            ]
                        }
                    */

                // helper method that grabs the value for `message` or `document`
                // from the first room in the list
                function firstSlackRoomValue(key) {
                    return _.chain(scope.exercise.slack_room_overrides).values().map(key).compact().first().value();
                }

                // Helper that allows for looping through each entry in
                // slack_room_overrides.  This helper will be called when
                // the user checks on a box to send distinct messages to each
                // room, and it will create an entry in `exercise.slack_room_overrides`
                // for each room.  Note that if another slack room is added to the
                // cohort, then `Cohort#addSlackRoom` is responsible for creating the
                // new entry in `slack_room_overrides` for that new slack room.
                function withEachOverrideEntry(fn) {
                    scope.exercise.slack_room_overrides = scope.exercise.slack_room_overrides || {};

                    _.forEach(scope.exercise.cohort.slack_rooms, slackRoom => {
                        const overrides = scope.exercise.slack_room_overrides;
                        overrides[slackRoom.id] = overrides[slackRoom.id] || {};
                        fn(overrides[slackRoom.id]);
                    });
                }

                // function that sets up either `scope.messageDetailEntries.message`
                // or `scope.messageDetailEntries.document` based on the
                // current state of the exercise and the UI.
                scope.messageDetailEntries = {};

                function setMessageDetailEntries(key, sendingDistinctValues) {
                    // If we are sending distinct values to each slack
                    // room, then we set up one entry per slack room.
                    if (sendingDistinctValues) {
                        scope.messageDetailEntries[key] = [];

                        // put the overrides in the same order as the slack rooms on the cohort
                        _.forEach(scope.exercise.cohort.slack_rooms, slackRoom => {
                            scope.messageDetailEntries[key].push({
                                title: slackRoom.title,
                                obj: scope.exercise.slack_room_overrides[slackRoom.id],
                            });
                        });
                    }

                    // If we are sending the same thing to all slack
                    // rooms, then there is just one entry that
                    // references the exercise itself.
                    else {
                        scope.messageDetailEntries[key] = [
                            {
                                title: null,
                                obj: scope.exercise,
                            },
                        ];
                    }
                }

                // This is called when the user toggles a checkbox to decide
                // where or not to send distinct messages or documents to
                // each slack room.
                function setSendDistinctProp(key, newVal, oldVal) {
                    // If unchecking the checkbox, then take the values from
                    // the first slack room and copy them to the exercise itself,
                    // and then delete the overrides.
                    if (!newVal && oldVal) {
                        const firstVal = _.clone(firstSlackRoomValue(key));
                        // If we got here because the user just unchecked the box, then we
                        // can copy the value from the slack room override before deleting
                        // the overrides.  If, on the other hand, we got here because a slack
                        // room was just deleted, then the overrides were already removed in
                        // Cohort#removeSlackRoom and we can't access it anymore (not ideal, maybe, but
                        // not a big deal)
                        if (firstVal) {
                            scope.exercise[key] = firstVal;
                        }
                        _.forEach(scope.exercise.slack_room_overrides, entry => {
                            delete entry[key];
                        });
                    }

                    // If checking the checkbox, then create overrides for
                    // each room and copy the value from the exercise itself
                    // onto each room.
                    else if (newVal && !oldVal) {
                        scope.exercise.slack_room_overrides = scope.exercise.slack_room_overrides || {};
                        withEachOverrideEntry(entry => {
                            entry[key] = _.clone(scope.exercise[key]);
                        });
                        delete scope.exercise[key];
                    }
                    setMessageDetailEntries(key, newVal);
                }

                // Set the initial values for the "sendDistinct... " properties.
                // If there are distinct values set on the exercise, then default it
                // to true.  Otherwise, default it to false.  Once those are set,
                // setup scope.messageDetailEntries
                let _sendDistinctMessageToEachSlackRoom = !!firstSlackRoomValue('message');
                let _sendDistinctDocumentToEachSlackRoom = !!firstSlackRoomValue('document');
                setMessageDetailEntries('message', _sendDistinctMessageToEachSlackRoom);
                setMessageDetailEntries('document', _sendDistinctDocumentToEachSlackRoom);

                // Getter/setter for the message checkbox
                Object.defineProperty(scope, 'sendDistinctMessageToEachSlackRoom', {
                    get() {
                        return _sendDistinctMessageToEachSlackRoom;
                    },
                    set(val) {
                        setSendDistinctProp('message', val, scope.sendDistinctMessageToEachSlackRoom);
                        _sendDistinctMessageToEachSlackRoom = val;
                    },
                });

                // Getter/setter for the document checkbox
                Object.defineProperty(scope, 'sendDistinctDocumentToEachSlackRoom', {
                    get() {
                        return _sendDistinctDocumentToEachSlackRoom;
                    },
                    set(val) {
                        setSendDistinctProp('document', val, scope.sendDistinctDocumentToEachSlackRoom);
                        _sendDistinctDocumentToEachSlackRoom = val;
                    },
                });

                // If the list of slack rooms changes while this directive is
                // visible, we need to respond
                scope.$watchCollection('exercise.cohort.slack_rooms', () => {
                    let slackRoomsCount = 0;
                    try {
                        slackRoomsCount = scope.exercise.cohort.slack_rooms.length;
                        // eslint-disable-next-line no-empty
                    } catch (err) {}
                    scope.hasMultipleSlackRooms = slackRoomsCount > 1;

                    // If we do not have multiple slack rooms, then we need to ensure
                    // that both `sendDistinct` properties are set to false.  This
                    // will call setMessageDetailEntries
                    if (!scope.hasMultipleSlackRooms) {
                        scope.sendDistinctMessageToEachSlackRoom = false;
                        scope.sendDistinctDocumentToEachSlackRoom = false;
                    }

                    // Otherwise we just call setMessageDetailEntries directly to
                    // setup `scope.messageDetailEntries`
                    else {
                        setMessageDetailEntries('message', scope.sendDistinctMessageToEachSlackRoom);
                        setMessageDetailEntries('document', scope.sendDistinctDocumentToEachSlackRoom);
                    }
                });
            },
        };
    },
]);
