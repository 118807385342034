import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_users/admin_edit_student_english_documents.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminEditStudentEnglishDocuments', [
    '$injector',

    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                user: '<',
                userProxy: '<',
            },
            link(scope) {
                scope.approvedProxy = { approved: false };

                scope.$watch('userProxy', () => {
                    if (scope.userProxy) {
                        // Special messaging when in the admin to explain why the user does not need to upload English
                        // language proficiency documents and does not need document approval for that section.
                        if (
                            !scope.userProxy.careerProfileIndicatesUserShouldUploadEnglishLanguageProficiencyDocuments
                        ) {
                            if (scope.userProxy.career_profile.native_english_speaker) {
                                scope.englishLanguageProficiencySectionAdminMessageLocale =
                                    'english_language_proficiency_section_admin_message_for_native_english_speaker';
                            } else if (scope.userProxy.career_profile.earned_accredited_degree_in_english) {
                                scope.englishLanguageProficiencySectionAdminMessageLocale =
                                    'english_language_proficiency_section_admin_message_for_accredited_degree_holder';
                            } else {
                                scope.englishLanguageProficiencySectionAdminMessageLocale =
                                    'english_language_proficiency_section_admin_message_for_exempt_user';
                            }
                        }

                        scope.approvalDisabled =
                            !scope.userProxy.recordsIndicateUserShouldUploadEnglishLanguageProficiencyDocuments;

                        scope.approvedProxy.approved =
                            scope.userProxy.english_language_proficiency_documents_approved || scope.approvalDisabled;
                    }
                });

                scope.toggleApprovalCheckbox = () => {
                    scope.userProxy.english_language_proficiency_documents_approved =
                        !scope.userProxy.english_language_proficiency_documents_approved;
                };
            },
        };
    },
]);
