import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_users/list_applicants.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import customLabelTemplate from 'Admin/angularModule/views/admin_users/list_applicants_custom_label_template.html';
import { react2Angular } from 'FrontRoyalReact2Angular';
import Filters from 'AdminUsers';

angularModule.component(
    'listApplicantsFilters',
    react2Angular(
        Filters,
        ['initialValues', 'usersAdminFilterData', 'resetFiltersCallback', 'searchCallback'],

        '',
        false,
    ),
);

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('listApplicants', [
    '$injector',

    function factory($injector) {
        const editContentItemListMixin = $injector.get('editContentItemListMixin');
        const LogInAs = $injector.get('LogInAs');
        const $location = $injector.get('$location');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                containerViewModel: '<',
                usersAdminFilterData: '<',
            },
            link(scope) {
                scope.reactInitialValues = $location.search().filters
                    ? JSON.parse($location.search().filters)
                    : undefined;
                scope.reactSearchCallback = filters => {
                    if (filters.generic_search === 'fireworks') {
                        angular.element('.main-box').css('overflow', 'hidden');
                        for (let i = 0; i < 3; i++) {
                            angular.element('.edit-content-list-panel-body').append('<div class="firework"></div>');
                        }
                    }

                    scope.reactInitialValues = filters;
                    $location.search('filters', JSON.stringify(filters));

                    scope.clientFilters = filters;
                    scope.updateFilters();
                    scope.$digest();
                };
                scope.reactResetFiltersCallback = () => {
                    scope.reactInitialValues = undefined;
                    $location.search('filters', undefined);

                    scope.resetFilters();
                    scope.$digest();
                };

                scope.containerViewModel.section = 'applicants';

                // We need to set this here rather than passing in true directly from the template so it's available
                // in the editContentItemListMixin, which uses this value to determine what filters should be passed
                // along to the server.
                scope.usingServerPagination = true;

                scope.forceShowRequest = () => true;

                //-------------------------
                // Config
                //-------------------------

                scope.advancedFiltersVisible = false;
                scope.toggleAdvancedFilters = () => {
                    scope.advancedFiltersVisible = !scope.advancedFiltersVisible;
                };

                scope.indexParamsForUsers = { view: 'admin_list', per_page: 100 };

                //-------------------------
                // Columns
                //-------------------------

                scope.displayColumns = [
                    { prop: 'created_at', type: 'time', label: 'Created' },
                    { prop: 'name', type: 'text', label: 'Name' },
                    { prop: 'email', type: 'text', label: 'Email' },
                    { prop: 'city_state_country', type: 'text', label: 'Location', sortable: false },
                    {
                        prop: 'globalRole',
                        type: 'custom',
                        label: 'Role',
                        sortable: false,
                        template: customLabelTemplate,
                        callbacks: {
                            getRoleClasses: user => {
                                const classes = ['label'];
                                classes.push(`label-${user && user.roleName() === 'learner' ? 'info' : 'success'}`);
                                return classes;
                            },
                        },
                    },
                    {
                        prop: 'groups',
                        type: 'custom',
                        label: 'Group(s)',
                        sortable: false,
                        template: customLabelTemplate,
                    },
                    {
                        prop: 'institutions',
                        type: 'custom',
                        label: 'Institution(s)',
                        sortable: false,
                        template: customLabelTemplate,
                        callbacks: {
                            getInstitutionNames: user =>
                                user.institutions.map(
                                    institution =>
                                        scope.institutionOptions.find(option => option.value === institution.id)?.label,
                                ),
                        },
                    },
                    {
                        prop: 'status_infos',
                        type: 'custom',
                        label: 'Status(es)',
                        sortable: false,
                        template: customLabelTemplate,
                    },
                    {
                        prop: 'logInAs',
                        type: 'custom',
                        label: '',
                        sortable: false,
                        template: customLabelTemplate,
                        unhideable: true,
                        callbacks: {
                            logInAs: user => {
                                LogInAs.logInAsUser(user);
                            },
                        },
                    },
                ];

                //-------------------------
                // Filters
                //-------------------------

                scope.proxy = {};

                scope.numOfAdvancedFiltersAppliedText = null;
                scope.$watchCollection('clientFilters', () => {
                    const nonAdvancedFilterIds = [
                        'name',
                        'email',
                        'city_state_country',
                        'phone',
                        'cohort_id',
                        'status',
                        'locale',
                    ];

                    const filterKeys = Object.keys(scope.clientFilters);

                    const numOfAdvancedFiltersApplied = filterKeys.filter(key => {
                        if (Array.isArray(scope.clientFilters[key])) {
                            return !nonAdvancedFilterIds.includes(key) && scope.clientFilters[key].length > 0;
                        }

                        if (scope.clientFilters[key]) {
                            return !nonAdvancedFilterIds.includes(key);
                        }

                        return false;
                    }).length;

                    if (numOfAdvancedFiltersApplied > 0) {
                        scope.numOfAdvancedFiltersAppliedText = `(${numOfAdvancedFiltersApplied})`;
                    } else {
                        scope.numOfAdvancedFiltersAppliedText = null;
                    }
                });

                // cohort and user-administration related filters
                scope.$watch('usersAdminFilterData', () => {
                    if (!scope.usersAdminFilterData) return;

                    scope.groupOptions = scope.usersAdminFilterData.availableAccessGroups.map(accessGroup => ({
                        label: accessGroup.name,
                        value: accessGroup.name,
                    }));
                    scope.globalRoleOptions = scope.usersAdminFilterData.availableRoles.map(role => ({
                        label: role.name.toUpperCase(),
                        value: role.id,
                    }));
                    scope.institutionOptions = scope.usersAdminFilterData.availableInstitutions.map(institution => ({
                        label: institution.name.toUpperCase(),
                        value: institution.id,
                    }));
                    scope.cohortOptions = scope.usersAdminFilterData.availableCohorts;
                });

                //------------------------------
                // Editable things list support
                //------------------------------

                const booleanProperties = [];
                const defaultFilters = [];
                const customFilterCallback = null;
                const manualFiltering = true;

                // wire up filtering support
                editContentItemListMixin.onLink(
                    scope,
                    'listApplicants',
                    defaultFilters,
                    booleanProperties,
                    customFilterCallback,
                    manualFiltering,
                );
            },
        };
    },
]);
