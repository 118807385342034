import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_mba/admin_edit_institution.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { INTERNAL_INSTITUTION_IDS } from 'Institutions';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminEditInstitution', [
    '$injector',

    function factory($injector) {
        const Institution = $injector.get('Institution');
        const guid = $injector.get('guid');
        const AdminEditService = $injector.get('AdminEditService');
        const $window = $injector.get('$window');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                thing: '<',
                goBack: '&',
                created: '&',
                destroyed: '&',
            },
            link(scope) {
                AdminEditService.onLink(scope, Institution);

                scope.isSpecialInstitution = INTERNAL_INSTITUTION_IDS.includes(scope.thing?.id);

                scope.maybeConfirmAndSave = () => {
                    let confirmSave;

                    if (scope.isSpecialInstitution) {
                        confirmSave = $window.confirm(
                            `Are you sure that you want to save the ${scope.thing.name} institution?`,
                        );
                    }

                    if (!scope.isSpecialInstitution || confirmSave) {
                        scope.save();
                    }
                };

                scope.generateToken = proxy => {
                    proxy.scorm_token = guid.generate();
                };

                scope.$watch('proxy.external', () => {
                    if (scope.proxy?.id && scope.proxy?.external) {
                        // Load the users in the external institution for potentially adding
                        // them as reports_viewers.
                        Institution.show(scope.proxy.id, {
                            fields: ['users'],
                        }).then(response => {
                            scope.users = response.result.users;
                        });
                    } else {
                        scope.users = [];
                    }
                });
            },
        };
    },
]);
