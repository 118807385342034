import { Brand } from 'AppBranding';
import { ProgramFamily, type ProgramTypeConfig } from 'Program';

// Used to group options in a cohort/program admin dropdown
// See useProgramTypeGroups.tsx for a hook that uses this
export const PROGRAM_TYPE_OPTION_GROUPS: {
    label: string;
    priority: number;
    filter: (pt: ProgramTypeConfig) => boolean;
}[] = [
    {
        label: 'Quantic Degrees',
        priority: 1,
        filter: (pt: ProgramTypeConfig) =>
            pt.branding === Brand.quantic && (pt.isMBA || pt.isEMBA || pt.isMSBA || pt.isMSSE),
    },
    {
        label: 'Valar Degrees',
        priority: 2,
        filter: pt => pt.branding === Brand.valar && (pt.isMBA || pt.isEMBA),
    },
    { label: 'Exec Ed Certs', priority: 3, filter: pt => pt.isExecEd },
    {
        label: 'VLA',
        priority: 4,
        filter: pt => pt.programFamily === ProgramFamily.valar_leadership_accelerator,
    },
    {
        label: 'Miscellaneous',
        priority: 5,
        filter: pt => ['the_business_certificate', 'jordanian_math', 'career_network_only'].includes(pt.key),
    },
];

export const PROGRAM_TYPE_OPTION_GROUP_OTHER = { label: 'All Other Programs', priority: 6 };
