import 'ng-toast';
import 'angular-selectize2/dist/angular-selectize';
import 'angular-utils-pagination';
import cacheAngularTemplate from 'cacheAngularTemplate';

import 'AdminEditorReportsShared/angularModule';
import 'AngularHttpQueueAndRetry/angularModule';
import 'Authentication/angularModule';
import 'Careers/angularModule';
import 'Cohorts/angularModule';
import 'ContentItemEditor/angularModule';
import 'DateHelper/angularModule';
import 'DialogModal/angularModule';
import 'Dynamic-node/angularModule';
import 'EditableThingsList/angularModule';
import 'FrontRoyalForm/angularModule';
import 'FrontRoyalSpinner/angularModule';
import 'FrontRoyalUpload/angularModule';
import 'Groups/angularModule';
import 'ImageFadeInOnLoad/angularModule';
import 'Institutions/angularModule';
import 'ItemGroupsEditor/angularModule';
import 'LearnerProjects/angularModule';
import 'Lessons/angularModule';
import 'Navigation/angularModule';
import 'Oreo/angularModule';
import 'Pagination/angularModule';
// NOTE: We explicitly import Reports/angularModule/scripts/reports_module here because
// Reports/angularModule/index.js is an entrypoint in Webpack. If we were to import
// the same file that the entrypoint uses, Webpack actually thinks that Reports/angularModule
// belongs inside the `admin` chunk because it technically isn't being imported in the `reports` chunk.
import 'Reports/angularModule/scripts/reports_module';
import 'SafeApply/angularModule';
import 'ScrollHelper/angularModule';
import 'Settings/angularModule';
import 'SortableColumns/angularModule';
import 'TimeoutHelpers/angularModule';
import 'Translation/angularModule';
import 'Users/angularModule';
import 'ZoomContainer/angularModule';
import 'Countries/angularModule';

import adminFormActions from 'Admin/angularModule/views/admin_form_actions.html';

const frontRoyalAdminModule = angular
    .module('FrontRoyal.Admin', [
        'Translation',
        'timeoutHelpers',
        'scrollHelper',
        'safeApply',
        'DialogModal',
        'EditableThingsList',
        'FrontRoyal.LearnerProjects',
        'FrontRoyal.AdminEditorReportsShared',
        'FrontRoyal.Careers',
        'FrontRoyal.ContentItemEditor',
        'FrontRoyal.EntityMetadata',
        'FrontRoyal.Form',
        'FrontRoyal.GlobalMetadata',
        'FrontRoyal.Lessons',
        'FrontRoyal.Institutions',
        'FrontRoyal.Cohorts',
        'FrontRoyal.Groups',
        'FrontRoyal.Navigation',
        'FrontRoyal.Users',
        'FrontRoyal.Reports',
        'FrontRoyal.Oreo',
        'FrontRoyal.Settings',
        'FrontRoyal.Upload',
        'FrontRoyal.Countries',
        'FrontRoyalSpinner',
        'ItemGroupsEditor',
        'Pagination',
        'imageFadeInOnLoad',
        'SortableColumns',
        'ngToast',
        'FrontRoyal.Authentication',
        'selectize',
        'zoomContainer',
        'angularUtils.directives.dirPagination',
        'HttpQueueAndRetry',
        'ui.bootstrap.datetimepicker', // angular-bootstrap-datetimepicker
        'dynamicNode',
        'DateHelper',
        'FrontRoyalStore',
    ])
    .config([
        '$compileProvider',
        $compileProvider => {
            // whitelist a[href] mailto links, otherwise the URL is prefixed with 'unsafe' and you can't send an email
            $compileProvider.aHrefSanitizationTrustedUrlList(/^\s*(https?|mailto):/);
        },
    ]);

cacheAngularTemplate(frontRoyalAdminModule, 'Admin/admin_form_actions.html', adminFormActions);

export default frontRoyalAdminModule;
