import angularModule from 'Admin/angularModule/scripts/admin_module';

angularModule.factory('UserTimeline', [
    '$injector',
    function ($injector) {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.setCollection('user_timelines');
            this.alias('UserTimeline');
            this.setIdProperty('id');
        });
    },
]);
