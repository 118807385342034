import angularModule from 'Admin/angularModule/scripts/admin_module';
import moment from 'moment-timezone';
import template from 'Admin/angularModule/views/admin_mba/admin_view_calendar.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminViewCalendar', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const Cohort = $injector.get('Cohort');
        const colorHelper = $injector.get('colorHelper');
        const ClientStorage = $injector.get('ClientStorage');
        const dateHelper = $injector.get('dateHelper');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                calendarService: '<?',
            },
            link(scope) {
                NavigationHelperMixin.onLink(scope);

                const colors = [
                    $injector.get('COLOR_V3_TURQUOISE'),
                    $injector.get('COLOR_V3_GREEN'),
                    $injector.get('COLOR_V3_ORANGE'),
                    $injector.get('COLOR_V3_PURPLE'),
                    $injector.get('COLOR_V3_CORAL'),
                    $injector.get('COLOR_V3_TURQUOISE_DARK'),
                    $injector.get('COLOR_V3_BLUE'),
                    $injector.get('COLOR_V3_GREEN_DARK'),
                    $injector.get('COLOR_V3_YELLOW'),
                    $injector.get('COLOR_V3_PURPLE_DARK'),
                    $injector.get('COLOR_V3_RED_DARK'),
                    $injector.get('COLOR_V3_BLUE_LIGHT'),
                ];

                scope.calendarService = scope.calendarService || {
                    initializeCalendar() {
                        // Initialize variables
                        let cohorts = _.sortBy(scope.cohorts, 'startDate');
                        const filters = scope.filters;

                        // For displaying events in the calendar we will cycle through a list of colors for each program_type, using a
                        // desaturated version for EMBA. Add the color as a transient property before applying filters so they don't change
                        // as filters are applied.
                        Cohort.programTypes.forEach(programType => {
                            if (programType.supportsAdmissionRounds) {
                                const cohortsOfThisType = cohorts.filter(
                                    cohort => cohort.program_type === programType.key,
                                );

                                cohortsOfThisType.forEach((cohort, i) => {
                                    // The offset comes from the fact that we want to keep sister programs aligned color-wise,
                                    // with the first pair being MBA5 / EMBA1, then MBA6 / EMBA2, and so on. Note that starting
                                    // with EMBA38/MBA38, the naming scheme for cohorts no longer reflects this offset.
                                    // However, it still remains true that there are 4 MBA cohorts before the first EMBA cohort,
                                    // so we still need the offset for the color scheme in the calendar to keep the sister
                                    // programs aligned. Also note that both MBA and EMBA programs have a single demo cohort
                                    // between MBA41/MBA42 and EMBA41/EMBA42, respectively. So, starting with MBA42/EMBA42,
                                    // the number in the name of the cohort actually lines up with the index in the
                                    // cohortsOfThisType array.
                                    const rotatingColorIndex =
                                        (i + Cohort.numPrecedingMbaCohorts(cohort.program_type)) % colors.length;
                                    const rotatingColor = colors[rotatingColorIndex];
                                    cohort.$$color = colorHelper.desaturateColor(
                                        Cohort.adminCohortCalendarColorDesaturation(cohort.program_type),
                                        rotatingColor,
                                    );
                                });
                            }
                        });

                        // Filter on programType if applicable
                        if (filters.programType) {
                            cohorts = cohorts.filter(cohort => cohort.program_type === filters.programType);
                        }

                        // Filter on cohortName if applicable
                        if (filters.cohortName) {
                            cohorts = cohorts.filter(cohort => cohort.name === filters.cohortName);
                        }

                        const prettyPeriodTitle = (cohort, period) =>
                            `${cohort.name}: ${period.title.replaceAll('**', '').replace(/Week [0-9]+: /, '')}`;

                        // Each cohort will be a source, seems easiest for how the fullcalendar API works. Let's iterate through every cohort
                        // that is left after filters are applied and create the appropriate event sources for them.
                        const eventSources = cohorts.map(cohort => ({
                            events(_fetchInfo, successCallback) {
                                const events = [];

                                // Start Date
                                if (filters.startDates) {
                                    events.push({
                                        title: `${cohort.name} starts`,
                                        start: cohort.startDate,
                                        end: cohort.startDate,
                                    });
                                }

                                ['exam', 'review', 'break', 'specialization'].forEach(style => {
                                    const prop = `${style}Periods`;
                                    if (filters[prop]) {
                                        cohort[prop].forEach(period => {
                                            events.push({
                                                title: prettyPeriodTitle(cohort, period),
                                                start: period.startDate,
                                                end: period.endDate,
                                            });
                                        });
                                    }
                                });

                                if (filters.projectPeriods) {
                                    cohort.projectPeriods.forEach(period => {
                                        events.push({
                                            title: prettyPeriodTitle(cohort, period),
                                            start: period.startDate,
                                            end: period.endDate,
                                        });
                                    });
                                }

                                // Create admission round events. Note that this is tricky because it sometimes needs to look at other cohorts
                                // since a cohort's first admission round starts a day after the last admission round of the previous
                                // cohort of the same type ends.
                                // See cohort.js#getPreviousAdmissionRound
                                if (filters.admissionRounds) {
                                    cohort.admission_rounds.forEach((admissionRound, i) => {
                                        const previousEndDate = Cohort.getPreviousAdmissionRound(
                                            admissionRound,
                                            cohort,
                                            scope.cohorts,
                                        );
                                        if (previousEndDate) {
                                            let applicationDeadline = admissionRound.applicationDeadline;
                                            if (!filters.admissionDeadlines) {
                                                applicationDeadline = dateHelper.addInDefaultTimeZone(
                                                    applicationDeadline,
                                                    1,
                                                    'days',
                                                );
                                            }

                                            const oneDayAfterPreviousEndDate = dateHelper.addInDefaultTimeZone(
                                                previousEndDate,
                                                1,
                                                'days',
                                            );
                                            events.push({
                                                title: `${cohort.name} admission round ${i + 1}`,
                                                start: dateHelper.shiftMonthDayForThreshold(
                                                    moment(oneDayAfterPreviousEndDate),
                                                ),

                                                // So you may wonder how this event ends a day earlier than the application deadline
                                                // when the end dates are the same. Well, that is a fantastic question! The answer is that
                                                // here we are both subtracting a day and the fullcalendar itself has a threshold that substracts a day
                                                // (see the nextDayThreshold option). This gives us
                                                // what we actually want in that the round span and the deadline don't overlap (since that would be redundant),
                                                // whereas below the fullcalendar can't do a threshold shift because the start and end dates are at the
                                                // same time.
                                                end: dateHelper.shiftMonthDayForThreshold(applicationDeadline),
                                            });
                                        }
                                    });
                                }

                                if (filters.admissionDeadlines) {
                                    cohort.admission_rounds.forEach((admissionRound, i) => {
                                        events.push({
                                            title: `${cohort.name} application deadline ${i + 1}`,
                                            start: dateHelper.shiftMonthDayForThreshold(
                                                admissionRound.applicationDeadline,
                                            ),
                                            end: dateHelper.shiftMonthDayForThreshold(
                                                admissionRound.applicationDeadline,
                                            ),
                                        });
                                    });
                                }

                                if (filters.admissionDecisions) {
                                    cohort.admission_rounds.forEach((admissionRound, i) => {
                                        events.push({
                                            title: `${cohort.name} decision date ${i + 1}`,
                                            start: admissionRound.decisionDate,
                                            end: admissionRound.decisionDate,
                                        });
                                    });
                                }

                                if (filters.enrollmentDeadline) {
                                    if (cohort.supportsEnrollmentDeadline) {
                                        events.push({
                                            title: `${cohort.name} enrollment deadline`,
                                            start: cohort.enrollmentDeadline,
                                            end: cohort.enrollmentDeadline,
                                        });
                                    }
                                }

                                if (filters.enrollmentDocumentsDeadline) {
                                    if (cohort.supportsEnrollmentDocumentsDeadline) {
                                        events.push({
                                            title: `${cohort.name} enrollment documents deadline`,
                                            start: cohort.enrollmentDocumentsDeadline,
                                            end: cohort.enrollmentDocumentsDeadline,
                                        });
                                    }
                                }

                                if (filters.officialTranscriptsDeadline) {
                                    if (cohort.supportsOfficialTranscriptsDeadline) {
                                        events.push({
                                            title: `${cohort.name} official transcripts deadline`,
                                            start: cohort.officialTranscriptsDeadline,
                                            end: cohort.officialTranscriptsDeadline,
                                        });
                                    }
                                }

                                // Registration Periods
                                if (
                                    filters.registrationPeriod &&
                                    cohort.registrationDeadline &&
                                    cohort.lastDecisionDate
                                ) {
                                    // Registration is technically open 180 days before the cohort's registration deadline,
                                    // but most users aren't actually able to register until their admissions decision has
                                    // been set on decision day, so we use the decision date as the start of the registration
                                    // period in the calendar for consistency.
                                    // See `#registrationOpenDate` in cohort.js.
                                    events.push({
                                        title: `${cohort.name} registration period`,
                                        start: cohort.lastDecisionDate,
                                        end: cohort.registrationDeadline,
                                    });
                                }

                                // Graduation Dates
                                if (filters.graduationDates) {
                                    events.push({
                                        title: `${cohort.name} graduates`,
                                        start: cohort.graduationDate,
                                        end: cohort.graduationDate,
                                    });
                                }

                                // Diploma Generation Dates
                                if (filters.diplomaGenerationDates) {
                                    events.push({
                                        title: `${cohort.name} diplomas`,
                                        start: cohort.diplomaGenerationDate,
                                        end: cohort.diplomaGenerationDate,
                                    });
                                }

                                // Each event is clickable and will navigate to the Cohort editor, so we add this on
                                // to every event. See also `eventClick` below.
                                events.forEach(event => {
                                    event.extendedProps = { cohort_id: cohort.id };
                                });

                                successCallback(events);
                            },

                            color: cohort.$$color,
                            textColor: '#FFFFFF',
                        }));

                        const overallCalendarElement = document.getElementById('overall-calendar');
                        scope.eventSources = eventSources;
                        scope.overallCalendar = new Calendar(overallCalendarElement, {
                            nextDayThreshold: '9:00:00',
                            height: 720,
                            timeZone: scope.dateHelper.ADMIN_REF_TIMEZONE,
                            displayEventTime: false,
                            eventSources,
                            eventDisplay: 'block',
                            plugins: [dayGridPlugin],
                            initialView: 'dayGridMonth',
                            handleWindowResize: true,
                            contentHeight: 'auto',
                            eventClick: info => {
                                scope.loadRoute(
                                    `/admin/mba/cohorts?cohort-id=${info.event.extendedProps.cohort_id}&tab=edit`,
                                );
                            },
                        });

                        scope.overallCalendar.render();
                    },
                    gotoDate(date) {
                        scope.overallCalendar.gotoDate(date);
                    },
                    refetchEvents() {
                        scope.overallCalendar.refetchEvents();
                    },
                    setOption(key, val) {
                        scope.overallCalendar.setOption(key, val);
                    },
                };

                // Setup filters
                const filtersKey = 'adminViewCalendarFilters';
                const defaultFilters = {
                    programType: undefined,
                    cohortName: undefined,
                    startDates: true,
                    examPeriods: true,
                    reviewPeriods: true,
                    breakPeriods: true,
                    admissionRounds: true,
                    enrollmentDeadline: true,
                    enrollmentDocumentsDeadline: true,
                    officialTranscriptsDeadline: true,
                };
                const existingFiltersString = ClientStorage.getItem(filtersKey);

                if (existingFiltersString) {
                    scope.filters = JSON.parse(existingFiltersString);
                } else {
                    scope.filters = defaultFilters;
                }

                scope.dateHelper = $injector.get('dateHelper');
                scope.programTypeOptions = Cohort.calendarProgramTypes;

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Cohort.index().then(response => {
                    scope.cohorts = _.sortBy(response.result, 'startDate');

                    // Always filter on supportsAdmissionRounds
                    scope.cohorts = scope.cohorts.filter(cohort => cohort.supportsAdmissionRounds);

                    // Build out the filter options
                    buildCohortNameOptions();
                });

                scope.calendarService.initializeCalendar();

                scope.$watch('cohorts', () => {
                    scope.calendarService.initializeCalendar(true);
                });

                scope.$watchCollection('filters', () => {
                    scope.calendarService.initializeCalendar(true);
                });

                scope.$watch('filters.programType', () => {
                    buildCohortNameOptions();
                });

                scope.$watchCollection('filters', () => {
                    ClientStorage.setItem(filtersKey, JSON.stringify(scope.filters));
                });

                scope.resetFilters = () => {
                    Object.assign(scope.filters, defaultFilters);
                };

                function buildCohortNameOptions() {
                    if (!scope.cohorts) {
                        return;
                    }

                    if (scope.filters.programType) {
                        const cohortsForProgramType = scope.cohorts.filter(
                            cohort => cohort.program_type === scope.filters.programType,
                        );
                        scope.cohortNameOptions = cohortsForProgramType.map(cohort => ({
                            key: cohort.name,
                            label: cohort.name,
                        }));
                    } else {
                        scope.cohortNameOptions = scope.cohorts.map(cohort => ({
                            key: cohort.name,
                            label: cohort.name,
                        }));
                    }
                }
            },
        };
    },
]);
