import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_mba/import_curriculum_template.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { getProgramConfigValue } from 'Program';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('importCurriculumTemplate', [
    '$injector',

    function factory($injector) {
        const CurriculumTemplate = $injector.get('CurriculumTemplate');
        const $window = $injector.get('$window');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                schedulableItem: '<',
            },
            link(scope) {
                CurriculumTemplate.index().then(response => {
                    scope.curriculumTemplates = response.result;
                    scope.curriculumTemplateOptions = scope.curriculumTemplates.map(curriculumTemplate => ({
                        value: curriculumTemplate,
                        label: curriculumTemplate.name,
                    }));
                });

                scope.importCurriculumTemplate = curriculumTemplate => {
                    if (
                        curriculumTemplate &&
                        $window.confirm(
                            `Are you sure you want to replace the current schedule with the ${curriculumTemplate.name} template?`,
                        )
                    ) {
                        // program_type has to be first, since it defines what else you can set
                        scope.schedulableItem.program_type = curriculumTemplate.program_type;

                        const curriculumTemplateAttrs = [
                            'program_type',
                            'description',
                            'groups',
                            'periods',
                            'specialization_playlist_pack_ids',
                            'num_required_specializations',
                            'enrollment_deadline_days_offset',
                            'graduation_days_offset_from_end',
                            'admission_rounds',
                            'id_verification_periods',
                            'project_submission_email',
                            'diploma_generation_days_offset_from_end',
                            'tila_disclosure_templates',
                            'playlist_collections',
                            'credit_hours',
                            'program_guide_url',
                        ];

                        if (curriculumTemplate.supportsEnrollmentDocumentsDeadline) {
                            curriculumTemplateAttrs.push('enrollment_documents_deadline_days_offset');
                        }

                        if (curriculumTemplate.supportsOfficialTranscriptsDeadline) {
                            curriculumTemplateAttrs.push('official_transcripts_deadline_days_offset');
                        }

                        const supportsActivateExecEdDates = getProgramConfigValue(
                            scope.schedulableItem.program_type,
                            'supportsActivateExecEdDates',
                        );
                        if (supportsActivateExecEdDates) {
                            curriculumTemplateAttrs.push('activate_exec_ed_for_unadmitted_users_days_offset');
                            curriculumTemplateAttrs.push('activate_exec_ed_for_former_students_days_offset');
                        }

                        if (
                            // Never overwrite an existing enrollment_agreement_template_id on the Cohort. We only want to
                            // set enrollment_agreement_template_id when initially creating a Cohort and importing a template.
                            // Additional changes to enrollment_agreement_template_id must be an engineering task and done by backfill.
                            !scope.schedulableItem.enrollment_agreement_template_id &&
                            curriculumTemplate.enrollment_agreement_template_id
                        ) {
                            curriculumTemplateAttrs.push('enrollment_agreement_template_id');
                        }

                        // If a CurriculumTemplate has cohort_sections, we apply them.  If it has none, we interpret
                        // that to mean that the template has no "opinion" about cohort sections and we won't
                        // alter the sections on the cohort.
                        if (curriculumTemplate.cohort_sections?.length > 0) {
                            curriculumTemplateAttrs.push('cohort_sections');
                        }

                        if (curriculumTemplate.supportsExternalScheduleUrl) {
                            curriculumTemplateAttrs.push('external_schedule_url');
                        }

                        if (curriculumTemplate.supportsCourseSummary) {
                            curriculumTemplateAttrs.push('course_summary');
                        }

                        const attrs = _.pick(curriculumTemplate.asJson(), curriculumTemplateAttrs);

                        scope.schedulableItem.copyAttrs(attrs);
                    }
                };
            },
        };
    },
]);
