import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_mba/admin_edit_cohort.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminEditCohort', [
    '$injector',

    function factory($injector) {
        const Cohort = $injector.get('Cohort');
        const AdminEditService = $injector.get('AdminEditService');
        const $rootScope = $injector.get('$rootScope');
        const $document = $injector.get('$document');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                // NOTE: We're using '=' binding instead of '&' binding for
                // these callbacks because this directive is not used directly
                // in editable-things-list as the edit-directive.  This is one step
                // farther down and we need to pass the callbacks down to it.
                // All of these callbacks are used by the AdminEditService
                thing: '<',
                created: '=',
                destroyed: '=',
                goBack: '=',
                listMeta: '<',
            },
            link(scope) {
                //---------------------------
                // Initialization
                //---------------------------

                AdminEditService.onLink(scope, Cohort);

                //---------------------------
                // MetaData Management
                //---------------------------

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.metadata = {
                    should_publish: false,
                    should_unpublish: false,
                };

                scope.$watch('metadata.should_publish', val => {
                    if (val) {
                        scope.metadata.should_unpublish = false;
                    }
                });

                scope.$watch('metadata.should_unpublish', val => {
                    if (val) {
                        scope.metadata.should_publish = false;
                    }
                });

                //---------------------------
                // Save hotkey
                //---------------------------

                const handleEditCohortHotkey = $event => {
                    if ($event.ctrlKey && $event.key === 's') {
                        scope.save();
                    }
                };

                $document.on('keydown', handleEditCohortHotkey);
                scope.$on('$destroy', () => {
                    $document.off('keydown', handleEditCohortHotkey);
                });
            },
        };
    },
]);
