import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/edit_schedule_basic_info.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { downloadSchedule } from 'Cohorts';
import { getProgramConfigValue } from 'Program';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editScheduleBasicInfo', [
    '$injector',

    function factory($injector) {
        const Cohort = $injector.get('Cohort');
        const $rootScope = $injector.get('$rootScope');
        const $q = $injector.get('$q');
        const ErrorLogService = $injector.get('ErrorLogService');
        const frontRoyalUpload = $injector.get('frontRoyalUpload');
        const DialogModal = $injector.get('DialogModal');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                schedulableItem: '<',
                playlists: '<',
                streams: '<',
            },
            link(scope) {
                // pre-open the editing for the basics if no name is specified
                scope.$watch('proxy.name', () => {
                    if (angular.isUndefined(scope.schedulableItem.name) || scope.schedulableItem.name.length === 0) {
                        scope.$$editingBasics = true;
                    }
                });

                scope.$watch('schedulableItem', schedulableItem => {
                    scope.isACohort = schedulableItem && schedulableItem.isA(Cohort);
                });

                scope.$watch('schedulableItem.enrollment_agreement_template_id', enrollment_agreement_template_id => {
                    if (enrollment_agreement_template_id) {
                        scope.signNowTemplateUrl = `https://app.signnow.com/webapp/document/${enrollment_agreement_template_id}`;
                    } else {
                        scope.signNowTemplateUrl = undefined;
                    }
                });

                scope.programTypeOptions = Cohort.programTypes;

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'showScheduleDownloadSection', {
                    get() {
                        return (
                            scope.isACohort &&
                            scope.schedulableItem.supportsSchedule &&
                            scope.schedulableItem.isDegreeProgram
                        );
                    },
                });

                Object.defineProperty(scope, 'canDownloadSchedule', {
                    get() {
                        return (
                            scope.showScheduleDownloadSection &&
                            !scope.downloadingSchedule &&
                            scope.playlists &&
                            scope.streams
                        );
                    },
                });

                scope.downloadSchedule = $event => {
                    $event.stopPropagation();

                    scope.downloadingSchedule = true;

                    $q.when(downloadSchedule(scope.schedulableItem, scope.playlists, scope.streams, $injector))
                        .then(() => {
                            scope.downloadingSchedule = false;
                        })
                        .catch(error => {
                            ErrorLogService.notify('Failed to download and/or save cohort schedule', error);
                        });
                };

                //----------------------------
                // Course Summary
                //----------------------------
                Object.defineProperty(scope, 'showCourseSummarySection', {
                    get() {
                        return scope.schedulableItem.programType
                            ? getProgramConfigValue(scope.schedulableItem.programType, 'supportsCourseSummary')
                            : false;
                    },
                    configurable: true, // specs
                });

                scope.onSelectCourseSummary = (file, errFile) => {
                    if (file) {
                        scope.uploadingAssets = true;
                    }

                    frontRoyalUpload
                        .handleNgfSelect(file, errFile, selectedFile => ({
                            url: `${window.ENDPOINT_ROOT}/api/cohorts/upload_course_summary.json`,
                            data: {
                                file: selectedFile,
                            },
                        }))
                        .then(response => {
                            const { course_summary } = response.data.contents;
                            scope.schedulableItem.course_summary = course_summary;
                        })
                        .catch(err => {
                            const message = err && err.message;
                            DialogModal.alert({
                                content: message || 'Something went wrong',
                            });
                        })
                        .finally(() => {
                            scope.uploadingAssets = false;
                        });
                };

                scope.removeCourseSummary = () => {
                    scope.schedulableItem.course_summary = null;
                };
            },
        };
    },
]);
