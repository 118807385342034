import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_users/admin_edit_student_transcripts.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { getAoiRecord } from 'Users';
import trashcanBeige from 'vectors/trashcan_beige.svg';
import progressBadgeCompleteTurquoise from 'images/progress_badge_complete_turquoise.png';
import { AoiRecordType } from 'ProgramAoi';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminEditStudentTranscripts', [
    '$injector',

    function factory($injector) {
        const DialogModal = $injector.get('DialogModal');
        const frontRoyalUpload = $injector.get('frontRoyalUpload');
        const S3TranscriptAsset = $injector.get('S3TranscriptAsset');
        const PrivateUserDocumentsHelper = $injector.get('PrivateUserDocumentsHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                user: '<',
                userProxy: '<',
            },
            link(scope) {
                //-------------------------
                // Initialization
                //-------------------------

                PrivateUserDocumentsHelper.onLinkWhenUsingProxy(scope);

                scope.trashcanBeige = trashcanBeige;
                scope.progressBadgeCompleteTurquoise = progressBadgeCompleteTurquoise;

                scope.eligibleThreeYearDegree = getAoiRecord(
                    scope.userProxy,
                    AoiRecordType.ProgramApplication,
                )?.eligibleThreeYearDegree;

                scope.$watch('eligibleThreeYearDegree', (newVal, prevVal) => {
                    const validValues = newVal !== undefined && prevVal !== undefined;
                    if (validValues && newVal !== prevVal) {
                        scope.userProxy.$$updateEligibleThreeYearDegreeTo = newVal;
                    }
                });

                const transcriptRejectedReasons = {
                    ineligible_institution: 'Ineligible Institution',
                    ineligible_non_degree_program: 'Ineligible Non-Degree Program',
                    ineligible_associates_degree: "Ineligible Associate's Degree",
                    ineligible_bcom: 'Ineligible BCom',
                    ineligible_bachelors_degree: "Ineligible Bachelor's Degree",
                    degree_not_completed: 'Degree Not Completed',
                    naces_confirmed_nonequivalent_degree: 'NACES Confirmed Nonequivalent Degree',
                    other: 'Other',
                };

                const transcriptExceptionReasons = {
                    gpa: 'GPA',
                    awards_or_distinctions: 'Awards or Distinctions',
                    standardized_test_scores: 'Standardized Test Scores',
                    other: 'Other',
                };

                const generateMultiSelectConfig = (fieldOptionsMap, overrides) => ({
                    label: overrides.label,
                    name: overrides.name,
                    options: overrides.options || Object.keys(fieldOptionsMap),
                    modelProp: overrides.modelProp,
                    placeholder: overrides.placeholder,
                    min: overrides.min === undefined ? 1 : overrides.min,
                    allowCreate: !!overrides.allowCreate,
                    required: overrides.required === undefined ? true : !!overrides.required,
                    // eslint-disable-next-line lodash-fp/prefer-identity
                    getOptionValue(option) {
                        return option;
                    },
                    getOptionLabel(option) {
                        return fieldOptionsMap[option];
                    },
                });

                const transcriptRejectedReasonsConfig = generateMultiSelectConfig(transcriptRejectedReasons, {
                    label: 'Transcript Rejected Reasons',
                    name: 'transcript-rejected-reasons',
                    modelProp: 'transcript_rejected_reasons',
                    placeholder: 'Reasons',
                });

                const transcriptExceptionReasonsConfig = generateMultiSelectConfig(transcriptExceptionReasons, {
                    label: 'Exception Approved Reasons',
                    name: 'transcript-exception-reasons',
                    modelProp: 'transcript_exception_reasons',
                    placeholder: 'Reasons',
                });

                scope.transcriptStatusConfigs = {
                    null: { label: '', value: null, supportsDocumentUpload: true },
                    unofficial_submitted: {
                        label: 'Unofficial Submitted',
                        value: 'unofficial_submitted',
                        supportsDocumentUpload: true,
                    },
                    unofficial_needs_more_info: {
                        label: 'Unofficial Needs More Information',
                        value: 'unofficial_needs_more_info',
                        supportsDocumentUpload: true,
                    },
                    unofficial_rejected: {
                        label: 'Unofficial Rejected',
                        value: 'unofficial_rejected',
                        supportsDocumentUpload: true,
                        supportsTranscriptRejectedReasons: true,
                        multiSelectConfig: transcriptRejectedReasonsConfig,
                    },
                    unofficial_approved: {
                        label: 'Unofficial Approved',
                        value: 'unofficial_approved',
                        supportsDocumentUpload: true,
                    },
                    official_requested_by_student: {
                        label: 'Official Requested by Student',
                        value: 'official_requested_by_student',
                        supportsDocumentUpload: true,
                    },
                    official_submitted: {
                        label: 'Official Submitted',
                        value: 'official_submitted',
                        supportsDocumentUpload: true,
                    },
                    granted_extension: {
                        label: 'Granted an Extension',
                        value: 'granted_extension',
                        supportsDocumentUpload: true,
                    },
                    granted_extension_covid_19: {
                        label: 'Granted an Extension (COVID-19)',
                        value: 'granted_extension_covid_19',
                        supportsDocumentUpload: true,
                    },
                    official_under_third_party_review: {
                        label: 'Official Under 3rd-Party Review',
                        value: 'official_under_third_party_review',
                        supportsDocumentUpload: true,
                    },
                    unofficial_under_third_party_review: {
                        label: 'Unofficial Under 3rd-Party Review',
                        value: 'unofficial_under_third_party_review',
                        supportsDocumentUpload: true,
                    },
                    eligibility_exception_considered: {
                        label: 'Eligibility Exception Considered',
                        value: 'eligibility_exception_considered',
                        supportsDocumentUpload: true,
                    },
                    failed_to_submit_official: {
                        label: 'Failed to Submit Official',
                        value: 'failed_to_submit_official',
                        supportsDocumentUpload: true,
                    },
                    official_rejected: {
                        label: 'Official Rejected',
                        value: 'official_rejected',
                        supportsDocumentUpload: true,
                        supportsTranscriptRejectedReasons: true,
                        multiSelectConfig: transcriptRejectedReasonsConfig,
                    },
                    official_approved: {
                        label: 'Official Approved',
                        value: 'official_approved',
                        supportsDocumentUpload: true,
                        supportsTranscriptApproval: true,
                    },
                    official_waived_unofficial_approved: {
                        label: 'Official Waived, Unofficial Approved',
                        value: 'official_waived_unofficial_approved',
                        supportsDocumentUpload: true,
                        supportsTranscriptApproval: true,
                    },
                    eligibility_exception_approved: {
                        label: 'Eligibility Exception Approved',
                        value: 'eligibility_exception_approved',
                        supportsDocumentUpload: true,
                        supportsTranscriptApproval: true,
                        supportsTranscriptExceptionReasons: true,
                        multiSelectConfig: transcriptExceptionReasonsConfig,
                    },
                    failed_to_submit_unofficial: {
                        label: 'Failed to Submit Unofficial',
                        value: 'failed_to_submit_unofficial',
                        supportsDocumentUpload: true,
                    },
                    waived_requirement: {
                        label: 'Waived Requirement',
                        value: 'waived_requirement',
                        supportsTranscriptWaiver: true,
                        inputConfig: {
                            label: 'Reason',
                            name: 'waiver-input',
                            modelProp: 'transcript_waiver',
                            required: true,
                        },
                    },
                    approved_legacy: {
                        label: 'Approved (Legacy)',
                        value: 'approved_legacy',
                        supportsDocumentUpload: true,
                        supportsTranscriptApproval: true,
                    },
                };
                const divider = { label: '──────────', disabled: true };

                const officialTranscriptStatusOptions = [
                    scope.transcriptStatusConfigs.null,
                    divider,
                    scope.transcriptStatusConfigs.unofficial_submitted,
                    scope.transcriptStatusConfigs.unofficial_needs_more_info,
                    scope.transcriptStatusConfigs.unofficial_rejected,
                    scope.transcriptStatusConfigs.unofficial_approved,
                    scope.transcriptStatusConfigs.unofficial_under_third_party_review,
                    divider,
                    scope.transcriptStatusConfigs.official_requested_by_student,
                    scope.transcriptStatusConfigs.official_submitted,
                    scope.transcriptStatusConfigs.granted_extension,
                    scope.transcriptStatusConfigs.granted_extension_covid_19,
                    scope.transcriptStatusConfigs.official_under_third_party_review,
                    scope.transcriptStatusConfigs.eligibility_exception_considered,
                    divider,
                    scope.transcriptStatusConfigs.failed_to_submit_official,
                    scope.transcriptStatusConfigs.official_rejected,
                    scope.transcriptStatusConfigs.official_approved,
                    scope.transcriptStatusConfigs.official_waived_unofficial_approved,
                    scope.transcriptStatusConfigs.eligibility_exception_approved,
                    scope.transcriptStatusConfigs.waived_requirement,
                    scope.transcriptStatusConfigs.approved_legacy,
                ];

                const unofficialTranscriptStatusOptions = [
                    scope.transcriptStatusConfigs.null,
                    divider,
                    scope.transcriptStatusConfigs.unofficial_submitted,
                    scope.transcriptStatusConfigs.unofficial_under_third_party_review,
                    scope.transcriptStatusConfigs.eligibility_exception_considered,
                    divider,
                    scope.transcriptStatusConfigs.failed_to_submit_unofficial,
                    scope.transcriptStatusConfigs.unofficial_needs_more_info,
                    scope.transcriptStatusConfigs.unofficial_rejected,
                    scope.transcriptStatusConfigs.unofficial_approved,
                    scope.transcriptStatusConfigs.eligibility_exception_approved,
                    scope.transcriptStatusConfigs.waived_requirement,
                    scope.transcriptStatusConfigs.approved_legacy,
                ];

                scope.$watch('userProxy', () => {
                    if (scope.userProxy) {
                        // For overriding the experience that requires an official transcript
                        const educationExperiences =
                            scope.userProxy.career_profile.educationExperiencesThatCanRequireOfficial;
                        scope.transcriptOverrideOptions = [];
                        educationExperiences.forEach(e => {
                            scope.transcriptOverrideOptions.push({
                                label: `${e.degreeName} ${e.major} - ${e.orgName} (${e.graduation_year})`,
                                value: e.id,
                            });
                            e.$$showNotesForAdmissionsSection = !!e.transcript_notes_for_admissions;
                        });
                        scope.transcriptOverrideOptions.unshift({
                            label: `🤖 Automatically choose`,
                            value: -1,
                        });
                        scope.transcriptOverride =
                            educationExperiences.find(e => e.official_transcript_required_override)?.id || -1;
                    }
                });

                scope.statusConfig = experience => scope.transcriptStatusConfigs[experience.transcript_status];
                scope.transcriptStatusOptions = experience => {
                    const options = experience.official_transcript_required
                        ? officialTranscriptStatusOptions
                        : unofficialTranscriptStatusOptions;
                    if (originalEducationExperience(experience.id).transcript_status !== 'approved_legacy') {
                        return options.filter(opt => opt.value !== 'approved_legacy');
                    }
                    return options;
                };

                function originalEducationExperience(id) {
                    return scope.user.career_profile.education_experiences.find(e => e.id === id);
                }

                //-------------------------
                // Handlers
                //-------------------------

                scope.onTranscriptOverrideChange = newVal => {
                    const educationExperiences =
                        scope.userProxy.career_profile.educationExperiencesThatCanRequireOfficial;

                    educationExperiences.forEach(e => {
                        e.official_transcript_required_override = false;
                    });

                    if (newVal !== -1) {
                        educationExperiences.find(e => e.id === newVal).official_transcript_required_override = true;
                    }
                };

                scope.onTranscriptSelect = ($file, $invalidFiles, educationExperienceProxy) => {
                    educationExperienceProxy.$$transcriptErrMessage = null;
                    educationExperienceProxy.$$uploadingTranscript = true;

                    frontRoyalUpload
                        .handleNgfSelect($file, $invalidFiles, file => ({
                            url: S3TranscriptAsset.UPLOAD_URL,
                            data: {
                                record: {
                                    file,
                                    education_experience_id: educationExperienceProxy.id,
                                },
                            },
                            supportedFormatsForErrorMessage: '.pdf, .doc, .docx, .jpg, .png',
                        }))
                        .then(response => {
                            const transcriptJson = response.data.contents.s3_transcript_assets[0];

                            // Since this change happens independently of a save button, reflect
                            // it on the original object now.
                            const originalExperience = originalEducationExperience(educationExperienceProxy.id);
                            originalExperience.transcripts.push(S3TranscriptAsset.new(transcriptJson));

                            educationExperienceProxy.transcripts.push(S3TranscriptAsset.new(transcriptJson));
                        })
                        .catch(err => {
                            educationExperienceProxy.$$transcriptErrMessage = err && err.message;
                        })
                        .finally(() => {
                            educationExperienceProxy.$$uploadingTranscript = false;
                        });
                };

                scope.deleteTranscript = (educationExperienceProxy, index) => {
                    if (educationExperienceProxy.$$deletingTranscript) {
                        return;
                    }

                    const fileName = educationExperienceProxy.transcripts[index].file_file_name;

                    DialogModal.confirm({
                        text: `Are you sure you want to delete transcript '${fileName}'?`,
                        confirmCallback: () => {
                            educationExperienceProxy.$$deletingTranscript = true;
                            educationExperienceProxy.transcripts[index]
                                .destroy()
                                .then(
                                    () => {
                                        // Since this change happens independently of a save button, reflect
                                        // it on the original object now.
                                        const originalExperience = originalEducationExperience(
                                            educationExperienceProxy.id,
                                        );
                                        originalExperience.transcripts.splice(index, 1);

                                        educationExperienceProxy.transcripts.splice(index, 1);
                                    },
                                    err => {
                                        throw err;
                                    },
                                )
                                .finally(() => {
                                    educationExperienceProxy.$$deletingTranscript = false;
                                });
                        },
                    });
                };

                scope.onTranscriptStatusChange = experienceProxy => {
                    const statusConfig = scope.statusConfig(experienceProxy);

                    if (experienceProxy.official_transcript_required) {
                        if (statusConfig.supportsTranscriptApproval) {
                            experienceProxy.transcript_approved = true;
                            scope.userProxy.transcripts_verified = true;
                        } else if (statusConfig.supportsTranscriptWaiver) {
                            experienceProxy.transcript_approved = false;
                            scope.userProxy.transcripts_verified = true;
                        } else {
                            experienceProxy.transcript_approved = false;
                            scope.userProxy.transcripts_verified = false;
                        }
                    } else {
                        experienceProxy.transcript_approved = false;
                    }

                    if (!statusConfig.supportsTranscriptWaiver) {
                        experienceProxy.transcript_waiver = null;
                    }

                    if (!statusConfig.supportsTranscriptRejectedReasons) {
                        experienceProxy.transcript_rejected_reasons = [];
                    }

                    if (!statusConfig.supportsTranscriptExceptionReasons) {
                        experienceProxy.transcript_exception_reasons = [];
                    }
                };
            },
        };
    },
]);
