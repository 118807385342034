import angularModule from 'Admin/angularModule/scripts/admin_module';
import 'ExtensionMethods/array';
import template from 'Admin/angularModule/views/admin_global_metadata.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminGlobalMetadata', [
    '$injector',

    function factory($injector) {
        // var $http = $injector.get('$http'),
        //     Tag = $injector.get('Tag'),
        const ngToast = $injector.get('ngToast');

        const HasSortableColumnsMixin = $injector.get('HasSortableColumnsMixin');
        const GlobalMetadata = $injector.get('GlobalMetadata');
        const $window = $injector.get('$window');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                HasSortableColumnsMixin.onLink(scope);
                scope.EDITOR_FORM_CLASSES = $injector.get('EDITOR_FORM_CLASSES');
                scope.proxy = {};

                AppHeaderViewModel.setTitleHTML('GLOBAL METADATA<br>ADMIN');

                //-------------------------
                // Group Management
                //-------------------------

                scope.loadGlobalMetadata = () => {
                    GlobalMetadata.index().then(response => {
                        const globalMetadata = response.result || [];
                        globalMetadata.forEach(globalMetadatum => {
                            globalMetadatum.created_at = new Date(globalMetadatum.created_at * 1000);
                            globalMetadatum.updated_at = new Date(globalMetadatum.updated_at * 1000);
                        });
                        scope.globalMetadata = globalMetadata;
                        scope.availableActions = response.meta.available_actions;
                    });
                };

                // invoke immediately
                scope.loadGlobalMetadata();

                function updatePageOptions() {
                    if (!scope.availableActions || !scope.globalMetadata) {
                        scope.pageOptions = undefined;
                        return;
                    }
                    scope.pageOptions = [];
                    const existingEntries = {};
                    scope.globalMetadata.map(record => {
                        existingEntries[record.site_name] = true;
                    });
                    scope.availableActions.forEach(name => {
                        if (!existingEntries[name]) {
                            scope.pageOptions.push({
                                value: name,
                                text: name,
                            });
                        }
                    });
                    scope.proxy.newGlobalMetadataSiteName = undefined;
                }

                scope.$watchCollection('globalMetadata', updatePageOptions);
                scope.$watchCollection('availableActions', updatePageOptions);

                scope.createGlobalMetadata = () => {
                    if (!scope.proxy.newGlobalMetadataSiteName) {
                        return;
                    }

                    const newGlobalMetadataSiteName = scope.proxy.newGlobalMetadataSiteName;
                    let newGlobalMetadata = GlobalMetadata.new();
                    newGlobalMetadata.site_name = newGlobalMetadataSiteName;

                    newGlobalMetadata.save().then(response => {
                        scope.newGlobalMetadataSiteName = undefined;
                        newGlobalMetadata = response.result;
                        newGlobalMetadata.created_at = new Date(newGlobalMetadata.created_at * 1000);
                        newGlobalMetadata.updated_at = new Date(newGlobalMetadata.updated_at * 1000);

                        scope.globalMetadata.push(newGlobalMetadata);
                        ngToast.create({
                            content: `${newGlobalMetadataSiteName} successfully created.`,
                            className: 'success',
                        });
                    });
                };

                scope.updateGlobalMetadata = globalMetadata => {
                    globalMetadata.save().then(response => {
                        ngToast.create({
                            content: `${globalMetadata.site_name} successfully updated.`,
                            className: 'success',
                        });

                        globalMetadata = response.result;
                        globalMetadata.created_at = new Date(globalMetadata.created_at * 1000);
                        globalMetadata.updated_at = new Date(globalMetadata.updated_at * 1000);
                    });
                };

                scope.deleteGlobalMetadata = globalMetadatum => {
                    if (!$window.confirm(`Are you sure you want to delete ${globalMetadatum.site_name}?`)) {
                        return;
                    }

                    globalMetadatum.destroy().then(() => {
                        Array.remove(scope.globalMetadata, globalMetadatum);
                        ngToast.create({
                            content: `${globalMetadatum.site_name} successfully deleted.`,
                            className: 'success',
                        });
                    });
                };

                scope.seoImageUploaded = (s3Asset, globalMetadatum) => {
                    globalMetadatum.default_image = s3Asset;
                };
                scope.seoImageEdited = (s3Asset, globalMetadatum) => {
                    globalMetadatum.default_image = s3Asset;
                };
                scope.removeSeoImage = globalMetadatum => {
                    globalMetadatum.default_image = undefined;
                };
            },
        };
    },
]);
