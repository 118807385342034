import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_mba/admin_edit_learner_project.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminEditLearnerProject', [
    '$injector',

    function factory($injector) {
        const LearnerProject = $injector.get('LearnerProject');
        const AdminEditService = $injector.get('AdminEditService');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                thing: '<',
                things: '<',
                listMeta: '<',
                goBack: '&',
                created: '&',
                destroyed: '&',
            },
            link(scope) {
                AdminEditService.onLink(scope, LearnerProject);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.$watch('things', availableProjects => {
                    scope.requirementIdentifiers = _.chain(availableProjects)
                        .map('requirement_identifier')
                        .uniq()
                        .map(identifier => ({
                            label: identifier,
                            value: identifier,
                        }))
                        .sortBy('label')
                        .value();

                    scope.projectTypes = [
                        {
                            label: 'Standard',
                            value: 'standard',
                        },
                        {
                            label: 'Presentation',
                            value: 'presentation',
                        },
                        {
                            label: 'Capstone',
                            value: 'capstone',
                        },
                    ];
                });
            },
        };
    },
]);
