import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_users/admin_edit_student_enrollment_documents.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import trashcanBeige from 'vectors/trashcan_beige.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminEditStudentEnrollmentDocuments', [
    '$injector',

    function factory($injector) {
        const DialogModal = $injector.get('DialogModal');
        const frontRoyalUpload = $injector.get('frontRoyalUpload');
        const PrivateUserDocumentsHelper = $injector.get('PrivateUserDocumentsHelper');
        const S3SupplementalEnrollmentDocument = $injector.get('S3SupplementalEnrollmentDocument');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                user: '<',
                userProxy: '<',
            },
            link(scope) {
                PrivateUserDocumentsHelper.onLinkWhenUsingProxy(scope);

                scope.trashcanBeige = trashcanBeige;

                scope.onSupplementalEnrollmentDocumentSelect = ($file, $invalidFiles, userProxy) => {
                    userProxy.$$supplementEnrollmentDocumentErrMessage = null;
                    userProxy.$$uploadingSupplementalEnrollmentDocument = true;

                    frontRoyalUpload
                        .handleNgfSelect($file, $invalidFiles, file => ({
                            url: S3SupplementalEnrollmentDocument.UPLOAD_URL,
                            data: {
                                record: {
                                    file,
                                    user_id: userProxy.id,
                                },
                            },
                            supportedFormatsForErrorMessage: '.pdf, .doc, .docx, .jpg, .png',
                        }))
                        .then(response => {
                            const documentJSON = response.data.contents.s3_supplemental_enrollment_documents[0];

                            scope.user.s3_supplemental_enrollment_documents.push(
                                S3SupplementalEnrollmentDocument.new(documentJSON),
                            );
                            userProxy.s3_supplemental_enrollment_documents.push(
                                S3SupplementalEnrollmentDocument.new(documentJSON),
                            );
                        })
                        .catch(err => {
                            userProxy.$$supplementEnrollmentDocumentErrMessage = err && err.message;
                        })
                        .finally(() => {
                            userProxy.$$uploadingSupplementalEnrollmentDocument = false;
                        });
                };

                scope.deleteSupplementEnrollmentDocument = (userProxy, index) => {
                    const document = userProxy.s3_supplemental_enrollment_documents[index];

                    DialogModal.confirm({
                        text: `Are you sure you want to delete '${document.file_file_name}'?`,
                        confirmCallback: () => {
                            userProxy.$$deletingSupplementalEnrollmentDocument = true;
                            document
                                .destroy()
                                .then(
                                    () => {
                                        userProxy.s3_supplemental_enrollment_documents.splice(index, 1);
                                        scope.user.s3_supplemental_enrollment_documents.splice(index, 1);
                                    },
                                    err => {
                                        throw err;
                                    },
                                )
                                .finally(() => {
                                    userProxy.$$deletingSupplementalEnrollmentDocument = false;
                                });
                        },
                    });
                };
            },
        };
    },
]);
