/**
 * This hook organizes cohorts into program type groups, which are not part of our
 * formal data model. Instead, these groups (defined in PROGRAM_TYPE_OPTION_GROUPS)
 * are an informal structure used for organizing options in select/autocomplete
 * dropdowns. These dropdowns can either separate Program Type (with groups as
 * subheaders) and Cohort inputs, or present a hierarchical Cohort input with a
 * nested Group -> Program Type -> Cohorts structure.
 *
 * The hook accepts a single parameter:
 * - cohorts: An array of cohort objects.
 *
 * It returns an object with the organized program type groups along with functions
 * to control old cohort exclusion (to show/hide cohorts ending more than 1 year ago).
 *
 * For a usage example with a hierarchical structure, see:
 * AdminCohortEventsPorter/HierarchicalCohortAutocomplete.tsx
 */

import { useMemo, useState } from 'react';
import { type CohortForAdminListsAttrs } from 'Cohorts';
import { PROGRAM_TYPE_OPTION_GROUPS, PROGRAM_TYPE_OPTION_GROUP_OTHER } from 'Admin/helpers';
import { ProgramTypeConfigs } from 'Program';
import { type ProgramTypeGroup } from 'Admin/Admin.types';

const useProgramTypeGroups = (cohorts: CohortForAdminListsAttrs[]) => {
    const [excludeOldCohorts, setExcludeOldCohorts] = useState(false);

    const programTypeGroups = useMemo((): ProgramTypeGroup[] => {
        const now = Date.now() / 1000;
        const filteredCohorts = excludeOldCohorts ? cohorts.filter(cohort => cohort.endDate > now - 31536000) : cohorts; // 31536000 seconds = 1 year

        const programTypes = [...new Set(filteredCohorts.map(cohort => cohort.programType))];

        const groupedProgramTypes = [];
        const groupedKeys = new Set();

        PROGRAM_TYPE_OPTION_GROUPS.forEach(group => {
            const programTypesForGroup = programTypes.filter(pt => {
                const config = ProgramTypeConfigs[pt];
                return group.filter(config);
            });
            if (programTypesForGroup.length > 0) {
                groupedProgramTypes.push({
                    ...group,
                    programTypes: programTypesForGroup,
                });
                programTypesForGroup.forEach(pt => {
                    groupedKeys.add(pt);
                });
            }
        });

        const ungroupedProgramTypes = programTypes.filter(pt => !groupedKeys.has(pt));
        if (ungroupedProgramTypes.length > 0) {
            groupedProgramTypes.push({
                ...PROGRAM_TYPE_OPTION_GROUP_OTHER,
                programTypes: ungroupedProgramTypes,
            });
        }

        return groupedProgramTypes
            .sort((a, b) => a.priority - b.priority)
            .map(group => ({
                ...group,
                programTypes: group.programTypes.map(pt => ({
                    programType: pt,
                    cohorts: filteredCohorts
                        .filter(cohort => cohort.programType === pt)
                        .sort((a, b) => (b.startDate || 0) - (a.startDate || 0)),
                })),
            }));
    }, [cohorts, excludeOldCohorts]);

    return { programTypeGroups, excludeOldCohorts, setExcludeOldCohorts };
};

export default useProgramTypeGroups;
