import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_mba/admin_edit_curriculum_template.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminEditCurriculumTemplate', [
    '$injector',

    $injector => {
        const CurriculumTemplate = $injector.get('CurriculumTemplate');
        const AdminEditService = $injector.get('AdminEditService');
        const $document = $injector.get('$document');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                thing: '<',
                listMeta: '<',
                goBack: '&',
                created: '&',
                destroyed: '&',
            },
            link(scope) {
                AdminEditService.onLink(scope, CurriculumTemplate);

                //---------------------------
                // Validation Overrides
                //---------------------------

                scope.$watchGroup(['proxy.name', 'proxy.program_type'], () => {
                    scope.proxyIsValid = !!(scope.proxy.name && scope.proxy.program_type);
                });

                //---------------------------
                // Save hotkey
                //---------------------------

                const handleEditCurriculumTemplateHotkey = $event => {
                    if ($event.ctrlKey && $event.key === 's') {
                        scope.save();
                    }
                };

                $document.on('keydown', handleEditCurriculumTemplateHotkey);
                scope.$on('$destroy', () => {
                    $document.off('keydown', handleEditCurriculumTemplateHotkey);
                });
            },
        };
    },
]);
