import cacheAngularTemplate from 'cacheAngularTemplate';
import { getProgramInclusion, getGraduationStatus } from 'Users';
import { statusOptionsWithChildren, statusOptionWithParent } from 'AdminUsers/StatusOptions';
import { formatScore } from 'FormatScore';
import angularModule from '../admin_module';
import template from '../../views/admin_mba/admin_cohort_gradebook.html';
import projectScoringModalTemplate from '../../views/admin_mba/admin_cohort_gradebook_project_scoring_modal.html';
import projectFieldsTemplate from '../../views/admin_mba/admin_cohort_gradebook_project_fields.html';
import examScoreFieldsTemplate from '../../views/admin_mba/admin_cohort_gradebook_exam_score_fields.html';
import honorsIneligibleFieldTemplate from '../../views/admin_mba/admin_cohort_gradebook_honors_ineligible_field.html';

const templateUrl = cacheAngularTemplate(angularModule, template);
const examScoreFieldsTemplateUrl = cacheAngularTemplate(angularModule, examScoreFieldsTemplate);
const projectFieldsTemplateUrl = cacheAngularTemplate(angularModule, projectFieldsTemplate);
const projectScoringModalTemplateUrl = cacheAngularTemplate(angularModule, projectScoringModalTemplate);
const honorsIneligibleFieldTemplateUrl = cacheAngularTemplate(angularModule, honorsIneligibleFieldTemplate);

angularModule.directive('adminCohortGradebook', [
    '$injector',

    function factory($injector) {
        const $window = $injector.get('$window');
        const AdminCohortStudentsTableHelper = $injector.get('AdminCohortStudentsTableHelper');
        const Stream = $injector.get('Lesson.Stream');
        const Playlist = $injector.get('Playlist');
        const editContentItemListMixin = $injector.get('editContentItemListMixin');
        const LearnerProject = $injector.get('LearnerProject');
        const ProjectProgress = $injector.get('ProjectProgress');
        const DialogModal = $injector.get('DialogModal');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                cohort: '<',
                listMeta: '<',
            },
            link(scope) {
                const clientPaginatePerPageOptions = [10, 25, 50, 100];
                const adminCohortStudentsTableHelper = new AdminCohortStudentsTableHelper(
                    scope.cohort,
                    `${$window.ENDPOINT_ROOT}/api/users/batch_update_grades.json`,
                    {
                        indexParams: {
                            view: 'cohort_gradebook',
                            filters: {
                                program_rows: [
                                    {
                                        cohort_id: scope.cohort.id,
                                        status: statusOptionsWithChildren(['matriculated', 'graduated']).concat(
                                            statusOptionWithParent('failed'),
                                        ),
                                    },
                                ],
                            },
                        },
                        batchSaveAttributes: [
                            'id',
                            'project_progress',
                            { programInclusions: ['id', 'honorsIneligible'] },
                        ],
                        saveAllInBatches: true,
                        saveAllBatchSize: 50,
                        clientPaginatePerPage: clientPaginatePerPageOptions[0],
                        clientPaginatePerPageOptions,
                    },
                );
                scope.adminCohortStudentsTableHelper = adminCohortStudentsTableHelper;

                let projectWeights;

                const projectProgressAttrs = ['scoreOptionValue', 'status', 'id_verified'];
                _.forEach(projectProgressAttrs, attr => {
                    // This works because we can never have a cohort with more than one project with the same requirement_identifier
                    // NOTE: We need to account for falsy values
                    adminCohortStudentsTableHelper.trackStudentChanges(student =>
                        _.chain(student.project_progress)
                            .map(progress => {
                                // score can be set to 0,
                                if (attr === 'scoreOptionValue' && progress[attr] === 0) {
                                    return progress[attr].toString() + progress.requirement_identifier;
                                }
                                // and id_verified can se set to false,
                                if (attr === 'id_verified' && progress[attr] === false) {
                                    return progress[attr].toString() + progress.requirement_identifier;
                                }
                                // otherwise ensure it's truthy
                                return progress[attr] && progress[attr].toString() + progress.requirement_identifier;
                            })
                            .compact()
                            .value(),
                    );
                });

                adminCohortStudentsTableHelper.trackStudentChanges(
                    student =>
                        getProgramInclusion(student, { programType: scope.cohort.programType })?.honorsIneligible,
                );

                // When the cohort is set, fetch playlists and exam streams
                // in order to set requiredExams and specializationExams
                scope.$watch('cohort', cohort => {
                    scope.requiredExams = null;
                    scope.specializationExams = null;
                    if (!cohort) {
                        return;
                    }
                    let playlists;
                    // Grab all the playlists for the cohort
                    Playlist.index({
                        filters: {
                            locale_pack_id: cohort.playlistPackIds,
                        },
                    })
                        .then(response => {
                            playlists = response.result;
                            const streamLocalePackIdsFromPlaylists = _.chain(playlists)
                                .map('streamLocalePackIds')
                                .flattenDeep()
                                .uniq()
                                .value();

                            // Grab any streams that are
                            // 1. in a required playlist
                            // 2. required in a period
                            // 3. in a specialization playlist
                            return Stream.index({
                                filters: {
                                    exam: true,
                                    locale: 'en',
                                    locale_pack_id: _.union(
                                        streamLocalePackIdsFromPlaylists,
                                        _.keys(cohort.requiredStreamPackIdsCache),
                                    ),
                                },
                            });
                        })
                        .then(response => {
                            // group the exams into required and specialization, and
                            // put the in the desired order
                            const exams = _.keyBy(response.result, 'localePackId');
                            scope.requiredExams = _.chain(scope.cohort.requiredStreamPackIdsCache)
                                .keys()
                                .flattenDeep()
                                .map(localePackId => exams[localePackId])
                                .compact()
                                .value();

                            scope.specializationExams = _.chain(scope.cohort.getSpecializationPlaylists(playlists))
                                .map('streamLocalePackIds')
                                .flattenDeep()
                                .map(localePackId => exams[localePackId])
                                .compact()
                                .value();
                        });
                });

                scope.$watchGroup(['cohort', 'listMeta.learner_projects', 'students'], () => {
                    if (!scope.cohort || !scope.listMeta || !scope.listMeta.learner_projects) {
                        return;
                    }

                    scope.learnerProjects = _.map(scope.cohort.learnerProjectIdsFromPeriods, learnerProjectId => {
                        const projectJson = _.find(scope.listMeta.learner_projects, {
                            id: learnerProjectId,
                        });
                        if (!projectJson) {
                            throw new Error('No project found');
                        }
                        return LearnerProject.new(projectJson);
                    });
                    scope.presentationProjects = _.filter(
                        scope.learnerProjects,
                        project => project.isPresentationProject,
                    );
                });

                function ensureProjectProgress(user, projectOrRequirementIdentifier) {
                    const requirementIdentifier =
                        typeof projectOrRequirementIdentifier === 'string'
                            ? projectOrRequirementIdentifier
                            : projectOrRequirementIdentifier.requirement_identifier;
                    let record = _.find(user.$$proxy.project_progress, {
                        requirement_identifier: requirementIdentifier,
                    });
                    if (!record) {
                        record = ProjectProgress.new({ requirement_identifier: requirementIdentifier, status: null });
                        user.$$proxy.project_progress.push(record);
                    }
                    return record;
                }

                let projectScoreCache;
                let totalProjectWeight;

                // duplicated in FinalScoreHelper#get_project_score
                // See comment there about what the rules are here for
                // calculating a score
                function getProjectScoreInfo(user) {
                    const scoresKey = _.map(user.$$proxy.project_progress, 'scoreOptionValue').join('-');

                    // If the scores for this user have not changed since the last time
                    // we figured all this out, return the cached value.
                    let cachedValue = projectScoreCache[user.id];
                    if (cachedValue && cachedValue.scoresKey === scoresKey) return cachedValue;

                    let totalScore = 0;
                    totalProjectWeight = _.reduce(
                        projectWeights,
                        (memo, weight, requirementIdentifier) => {
                            const projectProgress = ensureProjectProgress(user, requirementIdentifier);
                            if (projectProgress.waived) return memo;
                            return memo + weight;
                        },
                        0,
                    );

                    const scores = _.chain(scope.learnerProjects)
                        .map(project => {
                            const projectProgress = ensureProjectProgress(user, project);
                            if (projectProgress.waived) return null;

                            const weight = project.getScoringWeight(scope.cohort) / totalProjectWeight;
                            let score = projectProgress.score || 0;
                            const passingScore = project.getPassingScore(scope.cohort);
                            if (projectProgress.marked_as_passed) score = passingScore;
                            const passed = score >= passingScore;
                            const weightedScore = weight * (passed ? 1 : 0);
                            totalScore += weightedScore;
                            return Math.round(100 * weightedScore) / 100;
                        })
                        .without(null)
                        .value();

                    totalScore = Math.round(100 * totalScore) / 100;

                    const rolloverText = `${scores.join(' + ')} = ${totalScore}`;
                    cachedValue = { totalScore, rolloverText, scoresKey };
                    projectScoreCache[user.id] = cachedValue;
                    return cachedValue;
                }

                function updateColumns() {
                    if (!scope.cohort || !scope.requiredExams || !scope.specializationExams) return;

                    projectScoreCache = {};

                    scope.columns = [
                        adminCohortStudentsTableHelper.getNameColumn(),
                        adminCohortStudentsTableHelper.getEmailColumn(),
                        {
                            id: 'graduation_status',
                            prop: student => getGraduationStatus(student, { cohortId: scope.cohort.id }),
                            type: 'text',
                            label: 'Graduated',
                        },
                        {
                            id: 'final_score',
                            prop: user => formatScore(user.program_progress.final_score),
                            type: 'perc100',
                            label: 'Final Score',
                            sortEmptyAs0: true,
                        },
                        {
                            id: 'honors_ineligible',
                            prop: student =>
                                getProgramInclusion(student, { programType: scope.cohort.programType })
                                    .honorsIneligible,
                            type: 'custom',
                            templateUrl: honorsIneligibleFieldTemplateUrl,
                            label: 'Ineligible for Honors',
                            callbacks: {
                                onClick: student => {
                                    const programInclusion = getProgramInclusion(student.$$proxy, {
                                        programType: scope.cohort.programType,
                                    });
                                    programInclusion.honorsIneligible = !programInclusion.honorsIneligible;
                                },

                                // isChecked is based on the proxy because the status of the checkbox should show the value
                                // on the proxy if it has been edited
                                isChecked: student =>
                                    getProgramInclusion(student.$$proxy, { programType: scope.cohort.programType })
                                        .honorsIneligible,
                            },
                        },
                    ];

                    projectWeights = {};
                    _.forEach(scope.learnerProjects, project => {
                        projectWeights[project.requirement_identifier] = project.getScoringWeight(scope.cohort);

                        const projectScoreOptions = [{ value: null, label: '' }];
                        for (let i = 0; i <= 5; i++) {
                            projectScoreOptions.push({
                                value: i,
                                label: i + (project.getPassingScore(scope.cohort) <= i ? '*' : ''),
                            });
                        }
                        // A student's `final_score` for an ExecEd program comes entirley from their project score.
                        // At the time of this writing (10/09/2023), ExecEd cohorts are configured with only one
                        // project. If that project is waived, we can't actually calculate a proper `final_score`,
                        // so we remove the "waived" option for these cohorts.
                        //
                        // FIXME: If ExecEd cohorts are ever configured with more than one project, it may make
                        // sense to allow admins to waive some, but not all of the projects.
                        if (!scope.cohort.isExecEd) {
                            projectScoreOptions.push({ value: 'waived', label: 'N/A' });
                        }
                        projectScoreOptions.push({ value: 'marked_as_passed', label: 'Passed' });

                        const projectStatusOptions = [
                            { value: null, label: '' },
                            ...project.validStatusOptionsForProgressRecords,
                        ];

                        const idVerifiedOptions = [
                            { value: undefined, label: '' },
                            { value: true, label: 'ID verified' },
                            { value: false, label: 'ID NOT verified' },
                        ];

                        scope.columns.push({
                            id: `project-${project.id}`,
                            label: project.title,
                            type: 'custom',
                            project,
                            templateUrl: projectFieldsTemplateUrl,
                            classes: 'project',
                            projectScoreOptions, // score column
                            projectStatusOptions, // status column
                            idVerifiedOptions, // id_verified column
                            isPresentationProject: project.isPresentationProject,
                            prop: user => ensureProjectProgress(user, project).score,
                            ensureProjectProgress: user => ensureProjectProgress(user, project),
                            doNotExport: true,
                        });

                        // Add each sub-column as its own column in the CSV export
                        const projectProgressAttrsToExport = project.isPresentationProject
                            ? projectProgressAttrs
                            : _.without(projectProgressAttrs, 'id_verified');
                        _.forEach(projectProgressAttrsToExport, attr => {
                            const attrLabel = attr === 'scoreOptionValue' ? 'score' : attr.replace(/_/g, ' ');
                            scope.columns = _.union(scope.columns, [
                                {
                                    id: `project-${project.id}-${attr}`,
                                    prop: user => ensureProjectProgress(user, project)[attr],
                                    type: 'text',
                                    label: `${project.title} - ${attrLabel}`,
                                    csvOnly: true,
                                },
                            ]);
                        });
                    });

                    if (_.some(scope.cohort.learnerProjectIdsFromPeriods)) {
                        scope.columns = _.union(scope.columns, [
                            {
                                id: 'project_score',
                                prop: user => getProjectScoreInfo(user).totalScore,
                                type: 'custom',
                                templateUrl: projectFieldsTemplateUrl,
                                label: 'Project Score',
                                sortEmptyAs0: true,

                                // content that displays when rolling over a cell in the table
                                getRolloverText(user) {
                                    return getProjectScoreInfo(user).rolloverText;
                                },

                                getProjectScore(user) {
                                    return getProjectScoreInfo(user).totalScore;
                                },

                                // content that pops up when clicking on the tooltip in the head
                                tooltip: {
                                    anchorText: '?',
                                    click() {
                                        DialogModal.alert({
                                            title: 'Project Scoring',
                                            content: `<ng-include src="'${projectScoringModalTemplateUrl}'"></ng-include>`,
                                        });
                                    },
                                },
                            },
                        ]);
                    }

                    if (
                        scope.cohort.supportsPresentationProjects &&
                        scope.presentationProjects &&
                        scope.presentationProjects.length >= 1
                    ) {
                        scope.columns = _.union(scope.columns, [
                            {
                                id: 'presentation_projects_complete',
                                prop(user) {
                                    let complete = false;
                                    if (user.$$proxy) {
                                        complete =
                                            _.map(scope.presentationProjects, project =>
                                                ensureProjectProgress(
                                                    user,
                                                    project.requirement_identifier,
                                                ).unpassedForProject(project, scope.cohort),
                                            ).filter(value => !!value).length === 0;
                                    }
                                    return complete;
                                },
                                type: 'checkIfTrue',
                                label: 'Presentation Projects Complete',
                            },
                        ]);
                    }

                    if (scope.cohort.supportsSpecializations) {
                        scope.columns = _.union(scope.columns, [
                            {
                                id: 'specialization_playlists_complete',
                                prop: 'program_progress.specialization_playlists_complete',
                                type: 'text',
                                label: 'Specializations Complete',
                            },
                        ]);
                    }

                    // Old cohorts used to have participation scores.
                    if (scope.cohort.startDate < new Date('2017/09/01')) {
                        scope.columns = _.union(scope.columns, [
                            {
                                id: 'participation_score',
                                prop: user => formatScore(user.program_progress.participation_score),
                                type: 'perc100',
                                label: 'Participation Score',
                                sortEmptyAs0: true,
                            },
                        ]);
                    }

                    const avgAssessmentScoreColumn = scope.cohort.preferStrictSmartcaseScore
                        ? 'average_assessment_score_first'
                        : 'average_assessment_score_best';

                    scope.columns = _.union(scope.columns, [
                        {
                            id: avgAssessmentScoreColumn,
                            prop: user => formatScore(user.program_progress[avgAssessmentScoreColumn]),
                            type: 'perc100',
                            label: 'Smartcase',
                            sortEmptyAs0: true,
                        },
                        {
                            id: 'exam_score',
                            prop: user => formatScore(user.program_progress.exam_score),
                            type: 'perc100',
                            label: 'Exam',
                            sortEmptyAs0: true,
                        },
                    ]);

                    // Add a column for each exam
                    _.chain(scope.requiredExams)
                        .union(scope.specializationExams)
                        .forEach(exam => {
                            const scoresCache = {};
                            scope.columns.push({
                                id: `exam-${exam.localePackId}`,
                                type: 'custom',
                                templateUrl: examScoreFieldsTemplateUrl,
                                label: exam.title,
                                sortEmptyAs0: true,
                                prop(user) {
                                    if (!scoresCache[user.id]) {
                                        const streamProgress = user.exam_progress.find(
                                            examProgress => examProgress.locale_pack_id === exam.localePackId,
                                        );
                                        if (streamProgress && streamProgress.official_test_score >= 0) {
                                            scoresCache[user.id] = `${formatScore(
                                                streamProgress.official_test_score,
                                            )}%`;
                                        } else {
                                            scoresCache[user.id] = null;
                                        }
                                    }
                                    return scoresCache[user.id];
                                },
                                editScore(user) {
                                    const streamProgress = user.exam_progress.find(
                                        examProgress => examProgress.locale_pack_id === exam.localePackId,
                                    );

                                    if (!streamProgress) {
                                        return;
                                    }

                                    DialogModal.alert({
                                        title: `Edit Score for ${exam.title}`,
                                        content:
                                            '<edit-exam-score-modal user="user" exam="exam" on-success="onSuccess"></edit-exam-score-modal>',
                                        scope: {
                                            user,
                                            exam,
                                            onSuccess: response => {
                                                // Update the official_test_score on each associated exam progress record
                                                response.data.contents.exam_progress.forEach(
                                                    examProgressFromResponse => {
                                                        const examProgress = user.exam_progress.find(
                                                            _examProgress =>
                                                                _examProgress.locale_pack_id ===
                                                                examProgressFromResponse.locale_pack_id,
                                                        );
                                                        examProgress.official_test_score =
                                                            examProgressFromResponse.official_test_score;

                                                        // remove the value from the scoresCache so that the UI updates
                                                        delete scoresCache[user.id];
                                                    },
                                                );
                                            },
                                        },
                                    });
                                },
                            });
                        })
                        .value();

                    scope.columns = _.union(scope.columns, [
                        {
                            id: 'meets_graduation_requirements',
                            prop: 'program_progress.meets_graduation_requirements',
                            type: 'checkIfTrue',
                            label: 'Meets Grad Requirements',
                            csvOnly: true,
                        },
                        {
                            id: 'all_required_streams_complete',
                            prop(user) {
                                return user.program_progress?.required_streams_perc_complete === 1;
                            },
                            type: 'checkIfTrue',
                            label: 'Required Courses Complete',
                            csvOnly: true,
                        },
                    ]);

                    if (scope.cohort.supportsSpecializations) {
                        scope.columns = _.union(scope.columns, [
                            {
                                id: 'all_required_specializations_complete',
                                prop(user) {
                                    return (
                                        user.program_progress?.specialization_playlists_complete >=
                                        scope.cohort.num_required_specializations
                                    );
                                },
                                type: 'checkIfTrue',
                                label: 'Required Spec. Complete',
                                csvOnly: true,
                            },
                        ]);
                    }

                    scope.columns = _.union(scope.columns, [
                        {
                            id: 'required_lessons_complete',
                            prop: 'program_progress.required_lessons_complete',
                            type: 'text',
                            label: 'Required Lessons Complete',
                            csvOnly: true,
                        },
                        {
                            id: 'test_lessons_complete',
                            prop: 'program_progress.test_lessons_complete',
                            type: 'text',
                            label: 'Test Lessons Complete',
                            csvOnly: true,
                        },
                        {
                            id: 'elective_lessons_complete',
                            prop: 'program_progress.elective_lessons_complete',
                            type: 'text',
                            label: 'Elective Lessons Complete',
                            csvOnly: true,
                        },
                        {
                            id: 'average_assessment_score_first',
                            prop: user => formatScore(user.program_progress.average_assessment_score_first),
                            type: 'perc100',
                            label: 'Smartcase (First scores)',
                            csvOnly: true,
                        },
                    ]);

                    scope.columns.push(adminCohortStudentsTableHelper.getSaveColumn());

                    scope.csvExportColumns = _.reject(
                        scope.columns,
                        column => column.id === 'editorAbilities' || !!column.doNotExport,
                    );
                }

                scope.$watchGroup(['cohort', 'requiredExams', 'specializationExams'], updateColumns);

                //-------------------------
                // Filters
                //-------------------------

                scope.quickFilterProperties = ['name', 'email'];

                scope.meetsGraduationRequirementsOptions = getBooleanFilterOptions(
                    'Meets Graduation Requirements',
                    'Missing Graduation Requirements',
                );
                scope.allRequiredStreamsCompleteOptions = getBooleanFilterOptions(
                    'Required Courses Complete',
                    'Missing Required Courses',
                );
                scope.allRequiredSpecializationsCompleteOptions = getBooleanFilterOptions(
                    'Required Specializations Complete',
                    'Missing Required Specializations',
                );

                function getBooleanFilterOptions(trueLabel, falseLabel) {
                    return [
                        { label: trueLabel, value: 'true' },
                        { label: falseLabel, value: 'false' },
                    ];
                }

                const booleanProperties = [
                    scope.meetsGraduationRequirementsOptions,
                    scope.allRequiredStreamsCompleteOptions,
                    scope.allRequiredSpecializationsCompleteOptions,
                ];

                // default filters
                const defaultFilters = _.map(
                    adminCohortStudentsTableHelper.indexParams.filters,
                    (filterValue, filterKey) => {
                        const filter = { server: true, default: true, value: {} };
                        filter.value[filterKey] = filterValue;
                        return filter;
                    },
                );

                // wire up filtering support
                editContentItemListMixin.onLink(scope, 'adminCohortGradebook', defaultFilters, booleanProperties);

                // Since filters are stored in client storage, it is possible that when we first
                // come to this page, an unsupported filter will be set.  For example, you could be
                // looking at an EMBA cohort and set the allRequiredSpecializationsCompleteOptions
                // filter to false.  Then, if you looked at an MBA page, all the records would be hidden,
                // since the cohort has no specializations.  So, we need to remove irrelevant filters.
                // It is ok for the watch to just rely on an watch on filters
                // since we can assume that there is no way to add an unsupported filter in the UI.  So,
                // we only need this to fire once when the filters object is first initialized from ClientStorage.
                function removeUnsupportedFilters() {
                    if (scope.clientFilters && !scope.cohort.supportsSpecializations) {
                        delete scope.clientFilters.all_required_specializations_complete;
                    }
                }
                scope.$watch('cohort.supportsSpecializations', removeUnsupportedFilters);
                scope.$watchCollection('clientFilters', removeUnsupportedFilters);
            },
        };
    },
]);
