import angularModule from 'Admin/angularModule/scripts/admin_module';
import { pick } from 'lodash/fp';
import customFieldsTemplate from 'Admin/angularModule/views/admin_mba/admin_cohort_students_table_helper_custom_fields.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { USER_ATTRS_FOR_CASE_TRANSFORM } from 'Users';
import transformKeyCase from 'Utils/transformKeyCase';

const customFieldsTemplateUrl = cacheAngularTemplate(angularModule, customFieldsTemplate);

angularModule.factory('AdminCohortStudentsTableHelper', [
    '$injector',

    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const ngToast = $injector.get('ngToast');
        const $rootScope = $injector.get('$rootScope');
        const $window = $injector.get('$window');
        const $http = $injector.get('$http');
        const $q = $injector.get('$q');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const User = $injector.get('User');
        const guid = $injector.get('guid');

        /*
                The purpose of this class is to help create and manage a table of students in the
                cohort admin that allows for batch updating particular things for each student.

                It provides a few default columns (name, email, etc.), creates proxy objects for each
                student so that editing can be done on the proxy rather than the underlying object, and
                provides batch saving functionality and a save button for each student.
            */

        // eslint-disable-next-line func-names
        return SuperModel.subclass(function () {
            this.NAME_COLUMN_ID = 'name';
            this.EMAIL_COLUMN_ID = 'email';
            this.SAVE_COLUMN_ID = 'editorAbilities';

            function getCallbacksForClickableLinkColumn() {
                return {
                    onClick(student) {
                        // Note: we used to set ClientStorage key `adminEditCareerProfile_currentTab` here, but now that we're
                        // opening in a new window, there's no need. If we ever brought back the option to open in the same tab,
                        // we'd want to re-add that logic here.
                        NavigationHelperMixin.loadUrl(`/admin/users?id=${student.id}&tab=student-records`, '_blank');
                    },
                };
            }

            Object.defineProperty(this.prototype, 'hasUnsavedChanges', {
                get() {
                    return !!(this.students || []).find(this._studentHasUnsavedChanges.bind(this));
                },
            });

            Object.defineProperty(this.prototype, 'currentPageHasUnsavedChanges', {
                get() {
                    return !!((this.getStudentsOnCurrentPage && this.getStudentsOnCurrentPage()) || []).find(
                        this._studentHasUnsavedChanges.bind(this),
                    );
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'disableSave', {
                get() {
                    return !this.hasUnsavedChanges || this.saving;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'disableSaveCurrentPage', {
                get() {
                    return !this.currentPageHasUnsavedChanges || this.saving;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'disableSaveCurrentPageAndGoToNextPage', {
                get() {
                    return this.disableSaveCurrentPage || this.onLastPage;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'onLastPage', {
                get() {
                    return this.getCurrentPage
                        ? this.clientPaginatePerPage * this.getCurrentPage() >= this.students.length
                        : undefined;
                },
                configurable: true,
            });

            return {
                initialize(cohort, batchSaveEndpoint, opts) {
                    opts = opts || {};

                    this.cohort = cohort;
                    this.batchSaveEndpoint = batchSaveEndpoint;
                    this.changeTrackers = [];
                    this.$$trackedValuesCache = {};

                    this.batchSaveAttributes = opts.batchSaveAttributes || [];
                    this.saveAllInBatches = opts.saveAllInBatches || false;
                    this.saveAllBatchSize = opts.saveAllBatchSize;

                    this.clientPaginatePerPage = opts.clientPaginatePerPage;
                    this.clientPaginatePerPageOptions = opts.clientPaginatePerPageOptions;

                    if (this.saveAllInBatches && !this.saveAllBatchSize) {
                        throw new Error('Must specify saveAllBatchSize if you want to saveAllInBatches.');
                    }

                    // this is linked to the editable-things-list in the directive
                    this._destroyStudentsWatcher = $rootScope.$watch(
                        () => this.students,
                        this._onStudentsLoaded.bind(this),
                    );

                    this.indexParams = {
                        filters: opts.indexParams.filters,

                        // We always retrieve all of the records from the database, but we allow for
                        // more fine-grain control over how the client paginates these records via
                        // the clientPaginatePerPage option.
                        per_page: Number.MAX_SAFE_INTEGER,

                        ...opts.indexParams,
                    };
                },

                destroy() {
                    this._destroyStudentsWatcher();

                    if (this._destroyStudentsForCurrentPageWatcher) {
                        this._destroyStudentsForCurrentPageWatcher();
                    }
                },

                getNameColumn() {
                    return {
                        id: this.constructor.NAME_COLUMN_ID,
                        prop: 'name',
                        type: 'custom',
                        label: 'Name',
                        templateUrl: customFieldsTemplateUrl,
                        callbacks: getCallbacksForClickableLinkColumn(),
                        classes: 'frozen',
                    };
                },
                getEmailColumn() {
                    return {
                        id: this.constructor.EMAIL_COLUMN_ID,
                        prop: 'email',
                        type: 'custom',
                        label: 'Email',
                        templateUrl: customFieldsTemplateUrl,
                    };
                },
                getSaveColumn() {
                    const self = this;
                    return {
                        id: this.constructor.SAVE_COLUMN_ID,
                        prop: 'editorAbilities',
                        type: 'custom',
                        label: 'Action',
                        templateUrl: customFieldsTemplateUrl,
                        callbacks: {
                            saveThing(student) {
                                return self.saveAll([student], false);
                            },
                            isDisabled(student) {
                                return self.saving || !self._studentHasUnsavedChanges(student);
                            },
                            sort: this._studentHasUnsavedChanges.bind(this),
                            emptyToEnd: this._studentHasUnsavedChanges.bind(this),
                        },
                        classes: 'frozen',
                        sortable: false,
                    };
                },

                trackStudentChanges(getTrackedValue) {
                    const tracker = {
                        id: guid.generate(),
                        getTrackedValue,
                    };
                    this.changeTrackers.push(tracker);
                    this.$$trackedValuesCache[tracker.id] = {};
                },

                saveAll(students, confirmationRequired, confirmationMessage) {
                    if (confirmationRequired !== false) {
                        confirmationRequired = true;
                    }
                    students = students || this.students;
                    const updatedStudents = this._studentsWithUnsavedChanges(students);

                    confirmationMessage =
                        confirmationMessage ||
                        `You are about to save ${updatedStudents.length} user${
                            updatedStudents.length > 1 ? 's' : ''
                        }. Okay to proceed?`;

                    if (!confirmationRequired || $window.confirm(confirmationMessage)) {
                        this.saving = true;
                        let batchSavePromise;

                        if (this.saveAllInBatches) {
                            const promises = [];
                            const numBatches = Math.ceil(updatedStudents.length / this.saveAllBatchSize);

                            for (let i = 0; i < numBatches; i++) {
                                const index = this.saveAllBatchSize * i;
                                const studentsForBatch = updatedStudents.slice(index, index + this.saveAllBatchSize);
                                const promise = this._batchSave(studentsForBatch);
                                promises.push(promise);
                            }

                            batchSavePromise = $q.all(promises);
                        } else {
                            batchSavePromise = this._batchSave(updatedStudents);
                        }

                        return batchSavePromise
                            .then(responseOrResponses => {
                                this._onBatchSaveResponse(responseOrResponses, updatedStudents);
                            })
                            .finally(this._resetSavingAllFlag.bind(this));
                    }

                    return $q.resolve(false);
                },

                saveCurrentPage() {
                    const studentsOnCurrentPage = this.getStudentsOnCurrentPage();
                    if (studentsOnCurrentPage && studentsOnCurrentPage.length > 0) {
                        // When the user makes changes to some students on the current page and then does something to change
                        // what users are shown on the current page (maybe they update the filters or perPage setting) we keep
                        // those unsaved changes for those users in the background, but we don't include those changes in the
                        // batch save call for the current page. Instead, we inform the user of how many users are being saved
                        // in the batch save call for the current page and how many users have unsaved changes in other pages
                        // that won't be saved. This should hopefully avoid any potential confusion about what users are actually
                        // being updated as part of the batch save.
                        const allStudentsWithUnsavedChanges = this._studentsWithUnsavedChanges(this.students);
                        const studentsOnCurrentPageWithUnsavedChanges =
                            this._studentsWithUnsavedChanges(studentsOnCurrentPage);
                        const idsForAllStudentsWithUnsavedChanges = allStudentsWithUnsavedChanges.map(
                            student => student.id,
                        );
                        const idsForStudentsOnCurrentPageWithUnsavedChanges =
                            studentsOnCurrentPageWithUnsavedChanges.map(student => student.id);
                        const studentIds = [
                            idsForAllStudentsWithUnsavedChanges,
                            idsForStudentsOnCurrentPageWithUnsavedChanges,
                        ];
                        const idsForStudentsOnOtherPagesWithUnsavedChanges = studentIds.reduce((a, b) =>
                            a.filter(c => !b.includes(c)),
                        );

                        // construct the message for the confirmation dialog
                        const multipleCurrent = idsForStudentsOnCurrentPageWithUnsavedChanges.length > 1;
                        const confirmationMessageParts = [
                            `You are about to save ${idsForStudentsOnCurrentPageWithUnsavedChanges.length} user${
                                multipleCurrent ? 's' : ''
                            } from the current page.\n`,
                        ];
                        if (idsForStudentsOnOtherPagesWithUnsavedChanges.length > 0) {
                            const multipleOthers = idsForStudentsOnOtherPagesWithUnsavedChanges.length > 1;
                            confirmationMessageParts.push(
                                `${idsForStudentsOnOtherPagesWithUnsavedChanges.length} user${
                                    multipleOthers ? 's' : ''
                                } not on the current page ${
                                    multipleOthers ? 'have' : 'has'
                                } unsaved changes that will not be saved. Users with unsaved changes on other pages will retain their unsaved changes after the user${
                                    multipleCurrent ? 's' : ''
                                } from the current page ${multipleCurrent ? 'have' : 'has'} been saved.\n`,
                            );
                        }
                        confirmationMessageParts.push('Okay to proceed?');

                        return this.saveAll(studentsOnCurrentPage, true, confirmationMessageParts.join('\n'));
                    }

                    return $q.resolve(false);
                },

                saveCurrentPageAndGoToNextPage() {
                    this.saveCurrentPage().then(saved => {
                        // If the user cancels the save from the confirmation dialog, false is returned from saveCurrentPage.
                        if (saved !== false && this.goToPage) {
                            this.goToPage(this.getCurrentPage() + 1);
                        }
                    });
                },

                _onStudentsLoaded(updatedStudents) {
                    const students = updatedStudents || this.students;
                    _.forEach(students, this._onStudentLoad.bind(this));
                },

                _resetSavingAllFlag() {
                    this.saving = false;
                },

                _onStudentLoad(student) {
                    this.changeTrackers.forEach(tracker =>
                        this._ensureTrackedValueAddedToCache(student, tracker, true),
                    );
                    this._resetStudentProxy(student);
                },

                _resetStudentProxy(student) {
                    student.$$proxy = User.new(student.asJson());
                },

                _getRecordsForBatchSave(updatedStudents) {
                    const transformKeyCaseOpts = { to: 'snakeCase', keys: USER_ATTRS_FOR_CASE_TRANSFORM };

                    if (this.batchSaveAttributes.length > 0) {
                        return updatedStudents.map(student => {
                            const attributes = {};
                            this.batchSaveAttributes.forEach(attribute => {
                                if (typeof attribute === 'string') {
                                    attributes[attribute] = student.$$proxy[attribute];
                                } else if (typeof attribute === 'object') {
                                    Object.entries(attribute).forEach(entry => {
                                        const [attr, keys] = entry;
                                        attributes[attr] = Array.isArray(student.$$proxy[attr])
                                            ? student.$$proxy[attr].map(pick(keys))
                                            : pick(keys)(student.$$proxy[attr]);
                                    });
                                }
                            });
                            return transformKeyCase(attributes, transformKeyCaseOpts);
                        });
                    }

                    // In the client, the aoi records have been converted to camel-case, but our API expects
                    // snake-cased stuff. In Redux, this happens automatically (see FrontRoyalRedux/convertCasing.ts), but
                    // here we have to do it manually.
                    return transformKeyCase(
                        _.chain(updatedStudents).map('$$proxy').invokeMap('asJson').value(),
                        transformKeyCaseOpts,
                    );
                },

                _batchSave(students) {
                    const records = this._getRecordsForBatchSave(students);
                    return $http.put(this.batchSaveEndpoint, {
                        records,
                        meta: { cohort_id: this.cohort.id },
                    });
                },

                _onBatchSaveResponse(responseOrResponses, updatedStudents) {
                    let returnedAttrsById = {};
                    if (Array.isArray(responseOrResponses)) {
                        const responses = responseOrResponses;
                        responses.forEach(response => {
                            const returnedAttrsByIdForResponse = _.keyBy(response.data.contents.users, 'id');
                            returnedAttrsById = { ...returnedAttrsById, ...returnedAttrsByIdForResponse };
                        });
                    } else {
                        const response = responseOrResponses;
                        returnedAttrsById = _.keyBy(response.data.contents.users, 'id');
                    }

                    _.forEach(updatedStudents, student => {
                        // copy any values from the proxy and any values that came
                        // back from the server onto the actual record in the
                        // list of things
                        student.copyAttrs(student.$$proxy.asJson());

                        // The api is not required to return anything for the users if nothing
                        // has changed on the server, so we need the null check
                        student.copyAttrs(returnedAttrsById[student.id] || {});
                    });

                    ngToast.create({
                        content: `${updatedStudents.length} Student${updatedStudents.length > 1 ? 's' : ''} Saved`,
                        className: 'success',
                    });
                    this._onStudentsLoaded(updatedStudents);
                },

                _studentHasUnsavedChanges(student) {
                    // If no proxy has been setup yet, then there are no
                    // changes yet.
                    if (!student.$$proxy) return false;

                    return !!this.changeTrackers.find(
                        tracker =>
                            !angular.equals(
                                // tracked $$proxy values aren't cached since they can change frequently due to editing the student
                                tracker.getTrackedValue(student.$$proxy),
                                this._ensureTrackedValueAddedToCache(student, tracker),
                            ),
                    );
                },

                _studentsWithUnsavedChanges(students) {
                    return students.filter(this._studentHasUnsavedChanges.bind(this));
                },

                _ensureTrackedValueAddedToCache(student, tracker, resetValue = false) {
                    this.$$trackedValuesCache[tracker.id] = this.$$trackedValuesCache[tracker.id] || {};
                    this.$$trackedValuesCache[tracker.id][student.id] =
                        (!resetValue && this.$$trackedValuesCache[tracker.id][student.id]) ||
                        tracker.getTrackedValue(student);
                    return this.$$trackedValuesCache[tracker.id][student.id];
                },

                _clearTrackedValuesFromCache(student) {
                    this.changeTrackers.forEach(tracker => {
                        delete this.$$trackedValuesCache[tracker.id][student.id];
                    });
                },
            };
        });
    },
]);
