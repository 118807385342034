import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/batch_edit_users/batch_edit_users_show_save_progress.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('batchEditUsersShowSaveProgress', [
    '$injector',

    function factory($injector) {
        const $timeout = $injector.get('$timeout');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                viewModel: '<',
            },
            link(scope) {
                // We don't want to flash the message that says we're loading up data, so only
                // show it if it takes more than a second for the initial count to load.
                scope.showLoadingMessageIfWatcherIsNotYetLoaded = false;
                $timeout(1000).then(() => {
                    scope.showLoadingMessageIfWatcherIsNotYetLoaded = true;
                });
            },
        };
    },
]);
