import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/edit_schedule_id_verification_period.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import moment from 'moment-timezone';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editScheduleIdVerificationPeriod', [
    '$injector',

    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                idVerificationPeriod: '<',
            },
            link(scope) {
                scope.$watch('idVerificationPeriod.startDate', newVal => {
                    // if this date is the start of a period, show the period that is starting
                    const adjustedStartDate = moment(newVal).add(1, 'second').toDate();
                    scope.startDatePeriod = scope.idVerificationPeriod
                        .schedulableItem()
                        .getPeriodForDate(adjustedStartDate);
                });

                scope.$watch('idVerificationPeriod.dueDate', newVal => {
                    // If this date is the end of a period, show the period that is ending
                    const adjustedDueDate = moment(newVal).subtract(1, 'second').toDate();
                    scope.dueDatePeriod = scope.idVerificationPeriod
                        .schedulableItem()
                        .getPeriodForDate(adjustedDueDate);
                });
            },
        };
    },
]);
