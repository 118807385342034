import angularModule from 'Admin/angularModule/scripts/admin_module';
import { PROGRAM_TYPE_OPTION_GROUPS, PROGRAM_TYPE_OPTION_GROUP_OTHER } from 'Admin/helpers';
import { QueuedJobBatchWatcher } from 'QueuedJob';

angularModule.factory('BatchEditUsersViewModel', [
    '$injector',

    function factory($injector) {
        const SuperModel = $injector.get('SuperModel');
        const $location = $injector.get('$location');
        const Cohort = $injector.get('Cohort');
        const Singleton = $injector.get('Singleton');

        // This is the key that is used to store the queuedJobBatchWatcher in local storage.
        // Doing that allows us to continue to show progress from a previous save even if the
        // user refreshes the page or navigates away and back.
        const BATCH_WATCHER_KEY = 'BatchEditUsersViewModel.queuedJobBatchWatcher';
        const safeApply = $injector.get('safeApply');
        const $http = $injector.get('$http');
        const $rootScope = $injector.get('$rootScope');

        // eslint-disable-next-line func-names
        const BatchEditUsersViewModel = SuperModel.subclass(function () {
            this.include(Singleton);

            // This property is true if we are loading up the count of pending jobs.  That will be
            // the case either immediately upon pressing the save button or if we've just hit the
            // batch editor when we have a queuedJobBatchWatcher stored in localStorage
            Object.defineProperty(this.prototype, 'loadingProgress', {
                get() {
                    return this.saving || this.queuedJobBatchWatcher?.pendingCount === null;
                },
            });

            return {
                initialize() {
                    const self = this;
                    self.loadedUsers = [];
                    self.id = new Date().getTime();
                    self.updateParams = {};
                    self.errors = {};

                    this.programTypesByGroup = [];
                    this.cohortNames = {};
                    Cohort.index({
                        fields: ['BASIC_FIELDS'],
                    }).then(response => {
                        self.cohorts = response.result;
                        const cohortProgramTypes = [];
                        _.forEach(self.cohorts, cohort => {
                            self.cohortNames[cohort.id] = cohort.name;
                            cohortProgramTypes.push(Cohort.programTypes[cohort.program_type]);
                        });
                        this.programTypesByGroup = self.groupProgramTypes([...new Set(cohortProgramTypes)]);
                    });
                },

                gotoSection(section) {
                    const label = typeof section === 'string' ? section : section.id;
                    $location.url(`/admin/batch-users/${label}`);
                },

                getCohortName(cohortId) {
                    return this.cohortNames && this.cohortNames[cohortId];
                },

                removeUser(user) {
                    this.loadedUsers = _.without(this.loadedUsers, user);
                },

                resetForReview() {
                    this.updatedUsers = [];
                    this.errors = {};
                },

                save() {
                    const allIds = _.map(this.loadedUsers, 'id');
                    const data = _.extend(this.updateParams, {
                        cohort_id: this.cohortId,
                        user_ids: allIds,
                    });

                    this.saving = true;
                    this.gotoSection('show-save-progress');

                    return $http.put(`${window.ENDPOINT_ROOT}/api/users/batch_update.json`, data).then(response => {
                        const batchId = response.data.contents.job_batches[0].id;

                        this._createQueuedJobBatchWatcher({
                            batchId,
                            totalCount: allIds.length,
                        });

                        this.saving = false;
                    });
                },

                getQueuedJobBatchWatcherFromClientStorage() {
                    this.queuedJobBatchWatcher = QueuedJobBatchWatcher.load(BATCH_WATCHER_KEY, $injector);
                    if (this.queuedJobBatchWatcher) {
                        this._watchQueuedJobBatch();
                    }
                },

                // When we navigate away, we need to stop watching the batch so it doesn't keep making api calls
                stopWatchingBatch() {
                    if (this.queuedJobBatchWatcher) {
                        this.queuedJobBatchWatcher.cancel();
                    }
                },

                // As long as we have a queuedJobBatchWatcher, we will be stuck on the "show_save_progress" page.
                // In order to go back and allow for saving a new batch of users, we need to remove the queuedJobBatchWatcher.
                startOver() {
                    this.stopWatchingBatch();
                    this.queuedJobBatchWatcher = null;
                    this._unstoreQueuedJobBatch();
                },

                // create a new QueuedJobBatchWatcher right after queuing up a list of users to save
                _createQueuedJobBatchWatcher(batchInfo) {
                    this.batchInfo = batchInfo;
                    this.queuedJobBatchWatcher = new QueuedJobBatchWatcher(batchInfo.batchId, $injector, {
                        totalCount: batchInfo.totalCount,
                        pendingCount: batchInfo.totalCount,
                    }).store(BATCH_WATCHER_KEY);
                    this._watchQueuedJobBatch();
                },

                // call watch() on a QueuedJobBatchWatcher, which might have been created with _createQueuedJobBatchWatcher
                // or might have been loaded from local storage
                _watchQueuedJobBatch() {
                    this.queuedJobBatchWatcher.watch({ onProgress: safeApply }).then(() => {
                        this._unstoreQueuedJobBatch();

                        // If there are errors, then we don't want to hide the message from the user
                        if (this.queuedJobBatchWatcher.errorCount === 0) {
                            this.queuedJobBatchWatcher = null;
                        }
                        safeApply($rootScope);
                    });
                },

                // remove the QueuedJobBatchWatcher from local storage
                _unstoreQueuedJobBatch() {
                    QueuedJobBatchWatcher.unstore(BATCH_WATCHER_KEY, $injector);
                },

                filterCohortsByProgramType() {
                    if (this.programType) {
                        this.filteredCohorts = this.cohorts.filter(cohort => cohort.program_type === this.programType);
                    } else {
                        this.filteredCohorts = this.cohorts;
                    }
                    if (this.hideOldCohorts) {
                        this.filteredCohorts = this.filteredCohorts.filter(
                            // Cohort is "old" if it ended more than a year ago
                            cohort => cohort.end_date > Date.now() / 1000 - 31536000,
                        );
                    }
                },
                // Sets up the program type groups (see PROGRAM_TYPE_OPTION_GROUP_MAP) and sorts them by priority,
                // dumping any remaining program types into a catch-all group at the end.
                groupProgramTypes(programTypes) {
                    const groupedProgramTypes = [];

                    const groupedKeys = new Set();
                    PROGRAM_TYPE_OPTION_GROUPS.forEach(group => {
                        const programTypesForGroup = programTypes.filter(pt => group.filter(pt));
                        if (programTypesForGroup.length > 0) {
                            programTypesForGroup.sort((a, b) => a.label.localeCompare(b.label));
                            groupedProgramTypes.push({
                                ...group,
                                programTypes: programTypesForGroup,
                            });
                            programTypesForGroup.forEach(pt => groupedKeys.add(pt.key));
                        }
                    });

                    // Find remaining ungrouped program types and add them to a catch-all group
                    const ungroupedProgramTypes = programTypes.filter(pt => !groupedKeys.has(pt.key));
                    if (ungroupedProgramTypes.length > 0) {
                        groupedProgramTypes.push({
                            ...PROGRAM_TYPE_OPTION_GROUP_OTHER,
                            programTypes: ungroupedProgramTypes,
                        });
                    }

                    return groupedProgramTypes.sort((a, b) => a.priority - b.priority);
                },

                toggleHideOldCohorts() {
                    this.hideOldCohorts = !this.hideOldCohorts;
                    this.filterCohortsByProgramType();
                },
            };
        });

        return BatchEditUsersViewModel;
    },
]);
