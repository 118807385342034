import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/edit_schedule_dates.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { getProgramConfigValue } from 'Program';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editScheduleDates', [
    '$injector',

    function factory($injector) {
        const dateHelper = $injector.get('dateHelper');
        const Cohort = $injector.get('Cohort');
        const CurriculumTemplate = $injector.get('CurriculumTemplate');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                schedulableItem: '<',
            },
            link(scope) {
                scope.dateHelper = dateHelper;
                scope.dateHelper = $injector.get('dateHelper');

                scope.$watch('schedulableItem', () => {
                    scope.isCohort = scope.schedulableItem?.isA(Cohort);
                    scope.isCurriculumTemplate = scope.schedulableItem?.isA(CurriculumTemplate);
                });

                scope.$watch('schedulableItem.program_type', programType => {
                    if (!programType) return;

                    scope.supportsAutoDeclineAdmissionOffersDate =
                        scope.schedulableItem?.isA(Cohort) &&
                        getProgramConfigValue(programType, 'supportsAutoDeclineAdmissionOffersDate');
                    if (scope.supportsAutoDeclineAdmissionOffersDate) {
                        scope.schedulableItem.auto_decline_admission_offers_days_offset ||=
                            Cohort.DEFAULT_AUTO_DECLINE_ADMISSION_OFFERS_DAYS_OFFSET;
                    } else {
                        scope.schedulableItem.auto_decline_admission_offers_days_offset = null;
                    }

                    scope.supportsActivateExecEdDates = getProgramConfigValue(
                        programType,
                        'supportsActivateExecEdDates',
                    );
                    if (!scope.supportsActivateExecEdDates) {
                        scope.schedulableItem.activate_exec_ed_for_unadmitted_users_days_offset = null;
                        scope.schedulableItem.activate_exec_ed_for_former_students_days_offset = null;
                    }
                });
            },
        };
    },
]);
