import angularModule from 'Admin/angularModule/scripts/admin_module';

angularModule.factory('PersistedTimelineEvent', [
    '$injector',
    function ($injector) {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.setCollection('persisted_timeline_events');
            this.alias('PersistedTimelineEvent');
            this.setIdProperty('id');
        });
    },
]);
