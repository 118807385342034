import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/project_progress_events_tooltip.html';
import 'FrontRoyalUiBootstrap/popover';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('projectProgressEventsTooltip', [
    '$injector',

    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                projectProgress: '<',
            },
            link(scope) {
                scope.loadOrHideHistory = () => {
                    // If we're already showing the history, hide it
                    if ($(`#${scope.projectProgress.id}-history-tooltip`).hasClass('show')) {
                        scope.changeEvents = [];
                        $(`#${scope.projectProgress.id}-history-tooltip`).removeClass('show');
                    }
                    // Otherwise, we want to fetch the history and show it
                    else if (scope.projectProgress.user_id) {
                        scope.projectProgress.getCorrespondingTimelineEvents().then(events => {
                            scope.changeEvents = events;
                            $(`#${scope.projectProgress.id}-history-tooltip`).addClass('show');
                        });
                    }
                };

                scope.$on('$destroy', () => {
                    $(`#${scope.projectProgress.id}-history-tooltip`).removeClass('show');
                });
            },
        };
    },
]);
