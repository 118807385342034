import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/traverse_list_buttons.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('traverseListButtons', [
    '$injector',

    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                currentThing: '=',
                sortedThings: '=',
            },
            link(scope) {
                // get the current index to track which thing is being displayed from the sorted list of things
                // from the master page
                scope.currentIndex = _.findIndex(scope.sortedThings, thing => thing.id === scope.currentThing.id);

                scope.previous = () => {
                    scope.currentIndex -= 1;
                    scope.$parent.gotoThing({
                        $thing: scope.sortedThings[scope.currentIndex],
                    });
                };

                scope.next = () => {
                    scope.currentIndex += 1;
                    scope.$parent.gotoThing({
                        $thing: scope.sortedThings[scope.currentIndex],
                    });
                };
            },
        };
    },
]);
