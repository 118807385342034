import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/batch_edit_users/batch_edit_users_review_changes.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('batchEditUsersReviewChanges', [
    '$injector',

    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                viewModel: '<',
            },
            link(scope) {
                scope.perPage = 20;
                // Used for the progress indicator when saving
                scope.batchSize = 25;
                scope.currentBatch = 0;
                scope.Math = window.Math;

                scope.$watch('viewModel.loadedUsers', () => {
                    scope.usersToShow = scope.viewModel.loadedUsers;
                });
            },
        };
    },
]);
