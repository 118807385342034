import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/batch_edit_users/batch_edit_users_update_users.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { AdmissionOfferStatus } from 'AdmissionOffer';
import { ProgramInclusionStatus } from 'ProgramInclusion';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('batchEditUsersUpdateUsers', [
    '$injector',

    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                viewModel: '<',
            },
            link(scope) {
                FormHelper.supportForm(scope, scope.updateUsersForm);

                scope.statusOptions = [
                    {
                        text: 'EXPIRE OFFER',
                        value: AdmissionOfferStatus.DeclinedAdmissionOffer,
                    },
                    {
                        text: 'GRADUATE WITHOUT HONORS',
                        value: ProgramInclusionStatus.Graduated,
                    },
                    {
                        text: 'GRADUATE WITH HONORS',
                        value: 'honors',
                    },
                ];

                scope.reviewChanges = () => {
                    scope.viewModel.resetForReview();
                    scope.viewModel.gotoSection('review-changes');
                };
            },
        };
    },
]);
