import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_users.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { react2Angular } from 'FrontRoyalReact2Angular';
import { CreateUser } from 'Admin';
import camelCaseKeys from 'camelcase-keys';

angularModule.component('createUser', react2Angular(CreateUser, ['usersAdminFilterData'], '', false));

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminUsers', [
    '$injector',

    function factory($injector) {
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const $rootScope = $injector.get('$rootScope');
        const $http = $injector.get('$http');
        const $window = $injector.get('$window');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                AppHeaderViewModel.setTitleHTML('Manage Users');
                AppHeaderViewModel.setBodyBackground('beige');
                AppHeaderViewModel.showAlternateHomeButton = false;
                scope.containerViewModel = { header: '' };

                scope.getSectionHeader = () => scope.containerViewModel?.headerOverride || 'Users';
                scope.getMailtoLink = () => `mailto:${scope.containerViewModel.email}`;
                scope.getResumeLink = () => scope.containerViewModel.resumeUrl;
                scope.getLinkedInLink = () => scope.containerViewModel.linkedInUrl;

                scope.$on('$destroy', () => {
                    AppHeaderViewModel.toggleVisibility(true);
                });

                $http.get(`${$window.ENDPOINT_ROOT}/api/users/admin_filters.json`).then(response => {
                    scope.usersAdminFilterData = camelCaseKeys(response.data);
                });
            },
        };
    },
]);
