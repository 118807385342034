import angularModule from 'Admin/angularModule/scripts/admin_module';

angularModule.factory('AdminEditService', [
    '$injector',
    $injector => {
        const ngToast = $injector.get('ngToast');
        const $window = $injector.get('$window');
        const $q = $injector.get('$q');

        function displayName(thing) {
            return thing.name || thing.title;
        }

        return {
            /**
             * Adds necessary logic to the edit directive's scope for performing edit operatons on an adminable object
             *
             * @param  {Object} scope - A copy of the edit directive's scope
             * @param  {Object} klass - The Iguana class of the adminable object
             * @return {undefined}
             */
            onLink(scope, klass, options) {
                const self = this;

                options = options || {
                    includeSave: true,
                    includeDelete: true,
                };
                scope.proxy = klass.new(scope.thing.asJson()) || {};
                scope.isNew = !scope.thing.id;

                scope.$watch('thing', () => {
                    scope.proxy = self.getResetProxy(scope.thing, klass);
                });

                scope.$watch(
                    'proxy',
                    () => {
                        scope.proxyIsValid = self.isProxyValid(scope.proxy, scope.thing);
                    },
                    true,
                );

                if (options.includeSave) {
                    scope.save = () => {
                        const isNew = !scope.thing.id;

                        return scope.proxy
                            .save(scope.metadata)
                            .then(() => {
                                angular.copy(scope.proxy, scope.thing);

                                if (isNew && scope.created) {
                                    scope.created({
                                        $thing: scope.thing,
                                    });
                                }

                                ngToast.create({
                                    content: `${displayName(scope.thing)} saved`,
                                    className: 'success',
                                });

                                // If we were creating a new object then successfully saved it the isNew flag should
                                // no longer be false
                                if (scope.isNew) {
                                    scope.isNew = false;
                                }

                                // Reevaluate proxyIsValid in case we flipped it due to specific logic in a directive (ex. publishing)
                                scope.proxyIsValid = self.isProxyValid(scope.proxy, scope.thing);

                                return true;
                            })
                            .catch(() => false);
                    };
                }

                if (options.includeDelete) {
                    scope.delete = () => {
                        self.delete(scope.thing, {
                            goBack: scope.goBack,
                            destroyed: scope.destroyed,
                        });
                    };
                }
            },

            /**
             * Create a new proxy copy from the 'thing'
             *
             * @param  {Object} thing - The adminable object being edited
             * @param  {Object} klass - The Iguana class of the adminable object
             * @return {Object} An empty copy of the adminable object
             */
            getResetProxy(thing, klass) {
                return thing ? klass.new(thing.asJson()) : {};
            },

            /**
             * Determine if the proxy copy of the adminable object is valid
             *
             * @param  {Object} proxy - A copy of the adminable object being edited
             * @param  {Object} thing - The adminable object being edited
             * @return {boolean} True if the proxy is valid, false if not
             */
            isProxyValid(proxy, thing) {
                return thing && proxy && !angular.equals(thing, proxy);
            },

            /**
             * @param  {Object} thing - The adminable object being edited
             * @param  {Object} callbacks - Group of callbacks to be run after successful deletion
             * @return {Promise} A promise that resolves to true or false depending on status of the deletion
             */
            delete(thing, callbacks = {}) {
                if ($window.confirm(`Are you sure you want to delete ${displayName(thing)}`)) {
                    return thing.destroy().then(() => {
                        ngToast.create({
                            content: `${displayName(thing)} deleted`,
                            className: 'success',
                        });

                        if (callbacks.goBack) {
                            callbacks.goBack();
                        }

                        if (callbacks.destroyed) {
                            callbacks.destroyed({
                                $thing: thing,
                            });
                        }

                        return true;
                    });
                }
                return $q.when(false);
            },
        };
    },
]);
