import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_users/applicant_links.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('applicantLinks', [
    '$injector',

    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                mailtoLink: '<',
                resumeLink: '<',
                linkedInLink: '<',
            },
            link() {},
        };
    },
]);
