import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/edit_schedule.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import moment from 'moment-timezone';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editSchedule', [
    '$injector',

    function factory($injector) {
        const contentItemEditorLists = $injector.get('contentItemEditorLists');
        const $timeout = $injector.get('$timeout');
        const dateHelper = $injector.get('dateHelper');
        const $rootScope = $injector.get('$rootScope');
        const Cohort = $injector.get('Cohort');
        const LearnerProject = $injector.get('LearnerProject');
        const $window = $injector.get('$window');
        const scrollHelper = $injector.get('scrollHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                // An object that can have schedule, access_group_ids, and playlist_pack_ids
                schedulableItem: '<',
                availableLearnerProjectsJson: '<',
                availableCohortSections: '<',
                calendarService: '<?', // only set in specs
            },
            link(scope) {
                //----------------------------
                // General
                //----------------------------

                scope.dateHelper = dateHelper;

                scope.dateHelper = $injector.get('dateHelper');

                scope.programTypeOptions = Cohort.programTypes;

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                // This solves the problem of trying to set this
                // inside of an ng-if that we often solve by creating
                // a proxy object.
                let _showAllCoursesInPeriodStreamSelector = false;
                Object.defineProperty(scope, 'showAllCoursesInPeriodStreamSelector', {
                    get() {
                        return _showAllCoursesInPeriodStreamSelector;
                    },
                    set(val) {
                        _showAllCoursesInPeriodStreamSelector = val;
                    },
                });

                //----------------------------
                // Additional Content Loading
                //----------------------------

                contentItemEditorLists.load('Lesson.Stream', 'en').onLoad(lessonStreams => {
                    scope.lessonStreams = lessonStreams;
                });

                contentItemEditorLists.load('Playlist', 'en').onLoad(playlists => {
                    scope.playlists = playlists;
                });

                //----------------------------
                // Specializations
                //----------------------------

                scope.$watch('hasSpecializations', () => {
                    if (scope.hasSpecializations === false) {
                        scope.schedulableItem.num_required_specializations = 0;
                        scope.schedulableItem.specialization_playlist_pack_ids = [];
                    }
                });

                scope.$watchGroup(
                    [
                        'schedulableItem.num_required_specializations',
                        'schedulableItem.specialization_playlist_pack_ids',
                    ],
                    () => {
                        if (
                            !!scope.schedulableItem &&
                            (scope.schedulableItem.num_required_specializations > 0 ||
                                (scope.schedulableItem.specialization_playlist_pack_ids &&
                                    scope.schedulableItem.specialization_playlist_pack_ids.length > 0))
                        ) {
                            scope.hasSpecializations = true;
                        }
                    },
                );

                //----------------------------
                // Style options
                //----------------------------

                // Creates and returns the event source config for the provided periodStyle for the schedule calendar.
                // @param color - The background color that periods of the provided periodStyle in the schedule calendar will have.
                // @param textColor - The color of the text that periods of the provided periodStyle in the schedule calendar will have.
                function getEventSourceForPeriodStyle(periodStyle, color, textColor) {
                    return {
                        // See https://fullcalendar.io/docs/event-source-object
                        events(_fetchInfo, successCallback) {
                            successCallback(buildPeriodEvents(periodStyle));
                        },
                        color,
                        textColor: textColor || '#36142E', // default to a dark color
                    };
                }

                // Configure new or existing period styles here. Be sure to provide the period
                // style's key, the desired background color, and the desired color for the text
                // if the default text color isn't suitable for the chosen background color.
                const periodStyleCalendarEventSourceConfigs = {
                    standard: getEventSourceForPeriodStyle('standard', '#89C4F4'),
                    review: getEventSourceForPeriodStyle('review', '#D1F6F1'),
                    exam: getEventSourceForPeriodStyle('exam', '#E8DBF9'),
                    project: getEventSourceForPeriodStyle('project', '#DCEDC8'),
                    break: getEventSourceForPeriodStyle('break', '#F6D1D4'),
                    specialization: getEventSourceForPeriodStyle('specialization', '#F6EDD1'),
                };

                // derive the periodStyleOptions from the keys of periodStyleCalendarEventSourceConfigs to better prevent bugs
                // where we add or update a period style but forget to update the calendar event sources to support the period style
                scope.periodStyleOptions = _.keys(periodStyleCalendarEventSourceConfigs);
                scope.examStyleOptions = ['intermediate', 'final'];

                const predefinedProjectStyles = [
                    'emba_accounting',
                    'emba_leading_organizations',
                    'emba_business_plan',
                    'emba_strategy',
                    'emba_presentation',
                    'mba_accounting',
                    'mba_marketing',
                    'mba_strategy',
                    'mba_presentation',
                ];

                const predefinedSpecializationStyles = ['specialization_1', 'specialization_2', 'specialization_3'];

                scope.$watch('schedulableItem', schedulableItem => {
                    if (!schedulableItem) {
                        scope.projectStyleOptions = null;
                        scope.specializationStyleOptions = null;
                        return;
                    }

                    const periodProjectStyles = _.chain(schedulableItem.periods).map('project_style').uniq().value();

                    scope.projectStyleOptions = _.map(predefinedProjectStyles.concat(periodProjectStyles), style => ({
                        title: style,
                    }));

                    const periodSpecializationStyles = _.chain(schedulableItem.periods)
                        .map('specialization_style')
                        .uniq()
                        .value();

                    scope.specializationStyleOptions = _.map(
                        predefinedSpecializationStyles.concat(periodSpecializationStyles),
                        style => ({
                            title: style,
                        }),
                    );
                });

                //----------------------------
                // Calendar
                //----------------------------

                // builds and returns the events for all periods on the schedulableItem
                // with a style that matches the provided periodStyle
                function buildPeriodEvents(periodStyle) {
                    const periods = scope.schedulableItem.periods.filter(period => period.style === periodStyle);

                    const events = periods.map(period => ({
                        id: `period-${period.index}`,
                        title: period.periodTitle.replaceAll('**', ''),
                        start: moment(period.startDate).tz(dateHelper.ADMIN_REF_TIMEZONE).startOf('day').toDate(),
                        end: moment(period.endDate).tz(dateHelper.ADMIN_REF_TIMEZONE).startOf('day').toDate(),
                    }));

                    return events;
                }

                // For testability I am making a "service" object and did not want to put it in a different file
                scope.calendarService = scope.calendarService || {
                    initializeCalendar() {
                        const periodsCalendarElement = document.getElementById('periods-calendar');
                        const eventSources = _.values(periodStyleCalendarEventSourceConfigs);
                        scope.eventSources = eventSources;
                        scope.periodsCalendar = new Calendar(periodsCalendarElement, {
                            nextDayThreshold: '09:00:00',
                            height: 500,
                            aspectRatio: 2,
                            timeZone: scope.dateHelper.ADMIN_REF_TIMEZONE,
                            displayEventTime: false,
                            eventSources,
                            plugins: [dayGridPlugin],
                            initialView: 'dayGridMonth',
                            contentHeight: 'auto',
                            eventClick: info => {
                                scrollHelper.scrollToElement($(`[name='${info.event.id}']`), true, -15);
                            },
                        });
                        scope.periodsCalendar.render();
                    },
                    buildPeriodEvents,
                    gotoDate(date) {
                        scope.periodsCalendar.gotoDate(date);
                    },
                    refetchEvents() {
                        scope.periodsCalendar.refetchEvents();
                    },
                    setOption(key, val) {
                        scope.periodsCalendar.setOption(key, val);
                    },
                    isRendered() {
                        return document.getElementById('periods-calendar')?.hasChildNodes();
                    },
                };

                /**
                 * Build out the actual dates for each period
                 */
                scope.computePeriodDates = () => {
                    scope.schedulableItem.computePeriodDates();
                    // Check if FullCalendar has initialized the DOM
                    // See https://stackoverflow.com/a/3249260/1747491
                    if (scope.calendarService.isRendered()) {
                        // Refresh the calendar
                        scope.calendarService.refetchEvents(); // refresh events

                        // Move the calendar to the first period's startDate
                        if (_.size(scope.schedulableItem.periods) > 0) {
                            scope.calendarService.gotoDate(scope.schedulableItem.periods[0].startDate);
                        }
                    }
                };

                // Wait until the directive template has been rendered to initialize the calendar
                $timeout().then(() => {
                    scope.calendarService.initializeCalendar();
                    scope.computePeriodDates();
                });

                //----------------------------
                // Projects
                //----------------------------
                scope.$watch('availableLearnerProjectsJson', availableLearnerProjectsJson => {
                    scope.availableLearnerProjects = availableLearnerProjectsJson.map(json => LearnerProject.new(json));
                });

                scope.$watch('availableLearnerProjects', availableLearnerProjects => {
                    scope.availableLearnerProjectsById = _.keyBy(availableLearnerProjects, 'id');
                });

                //----------------------------
                // Periods
                //----------------------------

                function recalculateStreamFiltersIfDataLoaded() {
                    if (scope.lessonStreams && scope.playlists && scope.schedulableItem) {
                        scope.recalculateStreamFilters();
                    }
                }

                // FIXME: Audit and clean this up at some point
                scope.$watch('schedulableItem', scope.computePeriodDates); // do we actually need this one anymore?
                scope.$watch('schedulableItem.periods', scope.computePeriodDates); // reference
                scope.$watch('schedulableItem.periods', scope.computePeriodDates, true); // equality
                scope.$watch('schedulableItem.startDate', scope.computePeriodDates);
                scope.$watchCollection('schedulableItem.periods', recalculateStreamFiltersIfDataLoaded);

                scope.clickScheduleRow = period => {
                    scope.$$editingScheduleRow = period;
                };

                scope.closeScheduleRow = event => {
                    scope.$$editingScheduleRow = undefined;
                    event.stopPropagation();
                };

                scope.addPeriod = index => {
                    const confirmMsg = `Are you sure you want to add a period to the cohort schedule?`;
                    if (scope.schedulableItem.isA(Cohort) && !$window.confirm(confirmMsg)) {
                        return;
                    }
                    scope.schedulableItem.addPeriod(index);
                };

                scope.addWinterBreakPeriods = () => {
                    const confirmMsg =
                        `Are you sure you want to add Winter Break periods to the cohort schedule? This ` +
                        `will remove any existing Winter Breaks, add 2 Winter Break periods for each ` +
                        `year (if the cohort reaches that year's Winter Break) and update the week ` +
                        `numbers for all periods in the schedule.`;
                    if (scope.schedulableItem.isA(Cohort) && !$window.confirm(confirmMsg)) {
                        return;
                    }
                    scope.schedulableItem.addWinterBreakPeriods();
                };

                // Could potentially DRY this up with form_helper.js#moveOne and cfReorderListItemButtons
                // Or could move this to schedulable.js
                scope.moveCollectionOne = (collection, currentIndex, movement) => {
                    const newIndex = currentIndex + movement;

                    if (Math.abs(movement) > 1) {
                        const errorMessage = 'This function only allows movement of one';
                        throw errorMessage;
                    }

                    // return if trying to move the item outside the collection bounds
                    if (newIndex > collection.length - 1 || newIndex < 0) {
                        return;
                    }

                    // // remove the item from its current position
                    // var item = collection.splice(currentIndex, 1);

                    // // re-insert at the appropriate position
                    // collection.splice(newIndex, 0, item);

                    // get the item at the position where the moving item will be placed
                    const item = collection[newIndex];

                    // // move the item
                    collection[newIndex] = collection[currentIndex];

                    // // replace the original item at this spot
                    collection[currentIndex] = item;
                };

                scope.clearOtherStyles = period => {
                    if (!period.canHaveExamStyle) {
                        delete period.exam_style;
                    }
                    if (!period.canHaveSpecializationStyle) {
                        delete period.specialization_style;
                    }
                    if (period.style !== 'project') {
                        delete period.project_style;
                    }
                };

                //----------------------------
                // Stream Filtering
                //----------------------------

                // NOTE: this is being called in an onChange in the html template,
                // as well as in some methods in this file
                scope.recalculateStreamFilters = () => {
                    const localePackIdsFromGroups = _.chain(scope.lessonStreams)
                        .filter(stream => {
                            const streamGroupNames = _.map(scope.schedulableItem.groups, 'name');
                            return _.chain([stream.groupNames].flat())
                                .intersection([streamGroupNames].flat())
                                .some()
                                .value();
                        })
                        .map('localePackId')
                        .value();
                    const cohortLocalePackIds = [
                        ...new Set(
                            localePackIdsFromGroups
                                .concat(scope.schedulableItem.getStreamPackIdsFromPlaylistCollections(scope.playlists))
                                .concat(scope.schedulableItem.getStreamPackIdsFromPeriods()),
                        ),
                    ];
                    let availableStreamLocalePackIds;

                    // If show all courses is NOT checked, then we first filter
                    // out any course that is not in a concentration playlist or in an elective group.
                    // Said the opposite way, the only courses left are either in a concentration
                    // playlist or in an elective group.
                    if (scope.showAllCoursesInPeriodStreamSelector) {
                        availableStreamLocalePackIds = scope.lessonStreams.map(stream => stream.localePackId);
                    } else {
                        availableStreamLocalePackIds = cohortLocalePackIds;
                    }

                    const filters = { locale_pack_id: availableStreamLocalePackIds };

                    if (scope.schedulableItem.assessment_editing_locked) {
                        filters.customFilter = stream =>
                            // See https://trello.com/c/ZdLPPi7z to understand why we
                            // exclude exams and streams with assessments in this context.
                            !stream.gradable ||
                            // Need to include these streams to prevent period-stream-selector from throwing an error
                            // because its ngModel value contains streams not in the list of selectable streams.
                            cohortLocalePackIds.includes(stream.localePackId);
                    }

                    scope.streamFilters = filters;
                };

                scope.$watchGroup(
                    ['lessonStreams', 'playlists', 'showAllCoursesInPeriodStreamSelector'],
                    recalculateStreamFiltersIfDataLoaded,
                );
                scope.$watchCollection(
                    'schedulableItem.concentrationPlaylistPackIds',
                    recalculateStreamFiltersIfDataLoaded,
                );
                scope.$watchCollection('schedulableItem.groups', recalculateStreamFiltersIfDataLoaded);
            },
        };
    },
]);
