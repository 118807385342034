import angularModule from 'Admin/angularModule/scripts/admin_module';
// This is in the admin module because admin has its own amalgamation
// and for now that's the only place we need it.  Might need to move it
// later if we expose this stuff to end users.

import { isPayment } from 'BillingTransaction';

angularModule.factory('BillingTransaction', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        return Iguana.subclass(function () {
            this.setCollection('billing_transactions');
            this.alias('BillingTransaction');
            this.setIdProperty('id');

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.currency = this.currency || 'usd';
                this.transaction_type = this.transaction_type || 'payment';
            });
            this.setCallback('before', 'save', function () {
                this.amount = this.amount ? Number(this.amount) : 0;
                this.amount_refunded = this.amount_refunded ? Number(this.amount_refunded) : 0;

                if (!this.isPayment) {
                    this.provider = null;
                    this.provider_transaction_id = null;
                    this.amount_refunded = 0;
                    this.refunded = false;
                }
            });

            Object.defineProperty(this.prototype, 'transactionTime', {
                get() {
                    if (!this.$$transactionTime && this.transaction_time) {
                        this.$$transactionTime = new Date(this.transaction_time * 1000);
                    }
                    if (!this.transaction_time) {
                        this.$$transactionTime = null;
                    }
                    return this.$$transactionTime;
                },
                set(val) {
                    this.transaction_time = val ? val.getTime() / 1000 : null;
                    this.$$transactionTime = undefined;
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'isPayment', {
                get() {
                    return isPayment(this);
                },
            });

            Object.defineProperty(this.prototype, 'providerOrType', {
                get() {
                    if (this.isPayment) {
                        return this.provider;
                    }
                    return this.transaction_type;
                },
            });
        });
    },
]);
