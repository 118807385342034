import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/edit_period_action.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editPeriodAction', [
    '$injector',

    function factory($injector) {
        const Action = $injector.get('PeriodAction');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                action: '<',
            },
            link(scope) {
                scope.Action = Action;
            },
        };
    },
]);
