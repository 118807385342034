import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_users/admin_student_records.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminStudentRecords', [
    '$injector',

    function factory($injector) {
        const User = $injector.get('User');
        const ngToast = $injector.get('ngToast');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                user: '<',
            },
            link(scope) {
                scope.userProxy = User.new(scope.user.asJson());

                scope.resetProxy = userData => {
                    // Note that a few things can happen on the server that need to be reflected back
                    // to the original user object, in addition to the direct form changes.
                    // - When an admin has approved or waived all transcripts for a user, that user's
                    //      transcripts_verified flag becomes true
                    // - The updated_at changed, so we don't want a 406 to be triggered later
                    scope.user.copyAttrs(userData);
                    scope.userProxy = User.new(scope.user.asJson());
                    scope.userProxy.$$updateEligibleThreeYearDegreeTo = undefined;
                };

                scope.save = () => {
                    scope.saving = true;

                    const metadata = {};
                    if (scope.userProxy.$$updateEligibleThreeYearDegreeTo !== undefined) {
                        metadata.update_eligible_three_year_degree_to =
                            scope.userProxy.$$updateEligibleThreeYearDegreeTo;
                    }

                    scope.userProxy
                        .save({
                            // FIXME: I should have just read these off the career_profile
                            // param on the server in users_controller rather than sending through
                            // the meta like this. I guess I did it like this so that it was explicit
                            // that we were only saving the experiences that require a transcript.
                            education_experiences:
                                scope.userProxy.career_profile.educationExperiencesThatCanRequireOfficial,

                            ...metadata,
                        })
                        .then(response => {
                            scope.resetProxy(response.result);

                            ngToast.create({
                                content: 'Student Records Saved',
                                className: 'success',
                            });
                        })
                        .finally(() => {
                            scope.saving = false;
                            scope.editDocuments?.$setPristine();
                        });
                };
            },
        };
    },
]);
