import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/period_stream_selector.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

/*
    This is a lot like localePackSelectize, but since you need to
    add comments and other metadata along with each stream, it is
    it's own thing. We piggybacked off the existing scss file in locale_pack_selectize.
*/
angularModule.directive('periodStreamSelector', [
    '$injector',

    function factory($injector) {
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const contentItemEditorLists = $injector.get('contentItemEditorLists');

        return {
            restrict: 'E',
            templateUrl,
            require: 'ngModel', // get a hold of NgModelController
            scope: {
                config: '<?',
                filters: '<?',
                readonly: '<?',
            },
            link(scope, elem, attrs, ngModelCtrl) {
                NavigationHelperMixin.onLink(scope);

                scope.proxy = {};
                scope.config = scope.config || {};
                scope.contentItemsByLocalePackId = undefined;
                // var $$selectedContentItems;

                // Note: The property passed in as the ng-model needs to at least be an empty array.
                Object.defineProperty(scope, 'streamEntries', {
                    get() {
                        return ngModelCtrl.$modelValue;
                    },
                    set(val) {
                        ngModelCtrl.$setViewValue(val);
                        ngModelCtrl.$render();
                        return val;
                    },
                });

                Object.defineProperty(scope, 'localePackIds', {
                    get() {
                        return _.map(scope.streamEntries, 'locale_pack_id');
                    },
                });

                // Object.defineProperty(scope, 'selectedContentItems', {
                //     get: function() {
                //         return $$selectedContentItems;
                //     },
                //     set: function(val) {
                //         throw new Error('nope');
                //         // scope.localePackIds = _.map(val, 'localePackId');
                //         // $$selectedContentItems = val;
                //     }
                // });

                // function setContentItemsFromStreamEntries() {
                //     var localePackIds = scope.localePackIds;
                //     var contentItemsByLocalePackId = scope.contentItemsByLocalePackId;

                //     if (localePackIds && contentItemsByLocalePackId) {
                //         $$selectedContentItems = localePackIds.map(function(localePackId) {
                //             return contentItemsByLocalePackId[localePackId];
                //         });
                //     } else {
                //         $$selectedContentItems = undefined;
                //     }
                // }

                scope.contentItemFor = streamEntry =>
                    scope.contentItemsByLocalePackId && scope.contentItemsByLocalePackId[streamEntry.locale_pack_id];

                function setAvailableContentItems() {
                    // Throw out contentItems that have already been selected
                    scope.availableContentItems = _.reject(scope.allContentItems, contentItem =>
                        _.includes(scope.localePackIds, contentItem.localePackId),
                    );
                }

                function onContentItemChange() {
                    if (scope.streamEntries && scope.allContentItems) {
                        setAvailableContentItems();
                        // setContentItemsFromStreamEntries();
                    }
                    // else {
                    //     $$selectedContentItems = undefined;
                    // }
                }

                scope.$watchCollection('streamEntries', onContentItemChange);
                scope.$watch('allContentItems', onContentItemChange);
                scope.$watch('contentItemsByLocalePackId', onContentItemChange);

                function reloadOptions() {
                    scope.allContentItems = undefined;
                    scope.contentItemsByLocalePackId = undefined;
                    return contentItemEditorLists.load('Lesson.Stream', 'en', scope.filters).onLoad(contentItems => {
                        scope.allContentItems = contentItems;
                        scope.contentItemsByLocalePackId = _.keyBy(scope.allContentItems, 'localePackId');
                    });
                }

                scope.$watchCollection('filters', reloadOptions);

                scope.$watch('proxy.localePackIdToAdd', localePackId => {
                    if (localePackId) {
                        scope.streamEntries = scope.streamEntries.concat([
                            {
                                locale_pack_id: localePackId,
                                required: true,
                                caption: '',
                            },
                        ]);
                    }
                    scope.proxy.localePackIdToAdd = undefined;
                });

                scope.$watch('config', (config = {}) => {
                    scope.selectizeConfig = angular.extend(
                        {
                            maxItems: 1,
                            labelField: 'titleWithTagAndLocales',
                            valueField: 'localePackId',
                            sortField: 'titleWithTag',
                            searchField: 'titleWithTag',
                        },
                        config,
                    );
                });

                scope.remove = localePackId => {
                    scope.streamEntries = _.reject(scope.streamEntries, entry => entry.locale_pack_id === localePackId);

                    if (scope.config && scope.config.onChange) {
                        scope.config.onChange();
                    }
                };

                scope.open = localePackId => {
                    const contentItem = scope.contentItemsByLocalePackId[localePackId];
                    scope.loadUrl(contentItem.editorUrl, '_blank');
                };
            },
        };
    },
]);
