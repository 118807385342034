import angularModule from 'Admin/angularModule/scripts/admin_module';
import 'FrontRoyalUiBootstrap/popover';
import template from 'Admin/angularModule/views/admin_users/admin_education_experience_detail.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminEducationExperienceDetail', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const EducationExperienceDetailHelper = $injector.get('EducationExperienceDetailHelper');
        const EducationExperienceDetailMixin = $injector.get('EducationExperienceDetailMixin');

        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
                degreeProgram: '<',
            },
            require: '?^form',
            templateUrl,
            link(scope, elem, attrs, formController) {
                scope.helper = EducationExperienceDetailHelper;
                EducationExperienceDetailMixin.onLink(scope, elem, attrs, formController);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.$watch(
                    'ngModel',
                    () => {
                        scope.educationExperiences = scope.ngModel.filter(
                            experience => experience.degreeProgram === scope.degreeProgram,
                        );
                    },
                    true,
                );

                scope.moveEducationExperience = educationExperience => {
                    educationExperience.degreeProgram = !educationExperience.degreeProgram;
                };
            },
        };
    },
]);
