import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/edit_cohort_sections_and_slack_rooms.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import getOAuthUrl from 'Slack';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editCohortSectionsAndSlackRooms', [
    '$injector',

    function factory($injector) {
        const $window = $injector.get('$window');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const $location = $injector.get('$location');
        const DialogModal = $injector.get('DialogModal');
        const ngToast = $injector.get('ngToast');
        const Cohort = $injector.get('Cohort');
        const TranslationHelper = $injector.get('TranslationHelper');
        const cohortSectionsTranslationHelper = new TranslationHelper('cohort_sections');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                schedulableItem: '<',
                availableCohortSections: '<',
            },
            link(scope) {
                NavigationHelperMixin.onLink(scope);

                scope.$watchGroup(['schedulableItem', () => $location.search()], () => {
                    if (scope.schedulableItem) {
                        const searchParams = $location.search();

                        if (searchParams.slack_oauth_success) {
                            const slackRoom = scope.schedulableItem.slack_rooms.find(
                                _slackRoom => _slackRoom.id === searchParams.slack_oauth_success,
                            );
                            if (slackRoom) {
                                ngToast.create({
                                    content: `Successfully added Quantic Slack app to Slack room '${slackRoom.title}'`,
                                    className: 'success',
                                });
                            }
                            $location.search('slack_oauth_success', null);
                        } else if (searchParams.slack_oauth_error) {
                            DialogModal.alert({
                                title: 'Slack OAuth Error',
                                content: searchParams.slack_oauth_error,
                            });
                            $location.search('slack_oauth_error', null);
                        }
                    }
                });

                scope.$watchCollection('schedulableItem.cohort_sections', () => {
                    // We only want to populate the cohort section selectize
                    // with sections that aren't already assigned to the cohort
                    scope.cohortSectionOptions = scope.availableCohortSections
                        .filter(
                            cohortSection =>
                                !scope.schedulableItem.cohort_sections.find(cs => cs.id === cohortSection.id),
                        )
                        .map(cohortSection => ({
                            value: cohortSection.id,
                            label: cohortSectionsTranslationHelper.get(`${cohortSection.identifier}_short`),
                            cohortSection,
                        }));
                });

                scope.assignSectionToCohort = sectionId => {
                    if (sectionId) {
                        const sectionOption = scope.cohortSectionOptions.find(s => s.value === sectionId);
                        const confirmMsg = `Are you sure you want to add the ${sectionOption.label} section to ${scope.schedulableItem.name}?`;
                        if (scope.schedulableItem.isA(Cohort) && !$window.confirm(confirmMsg)) {
                            return;
                        }
                        scope.schedulableItem.assignCohortSection(sectionOption.cohortSection);
                        scope.$$addingSection = false;
                        scope.$$editingSectionRow = sectionId;
                    }
                };

                scope.clickSectionRow = sectionId => {
                    scope.$$editingSectionRow = sectionId;
                };

                scope.closeSectionRow = event => {
                    scope.$$editingSectionRow = undefined;
                    event.stopPropagation();
                };

                scope.addToSlack = slackRoom => {
                    // Do nothing if the slackRoom record hasn't been persisted to the database yet.
                    if (scope.addToSlackFeatureDisabled(slackRoom)) {
                        return;
                    }

                    if (
                        !$window.confirm(
                            'Adding the Quantic Slack app to the Slack room will navigate you away, discarding any unsaved changes. Are you sure you want to continue?',
                        )
                    ) {
                        return;
                    }

                    const url = getOAuthUrl(slackRoom);
                    scope.loadUrl(url);
                };

                scope.addToSlackFeatureDisabled = slackRoom => !slackRoom.created_at;
            },
        };
    },
]);
