import cacheAngularTemplate from 'cacheAngularTemplate';
import UserAdministrationTab from 'UserAdministrationTab';
import { react2Angular } from 'FrontRoyalReact2Angular';
import { getAoiRecord, getUserProgramState } from 'Users';
import { AoiRecordType } from 'ProgramAoi';
import { parsePhoneNumber } from 'libphonenumber-js';
import { StudentProfileFormConfigs } from 'FormConfigs';
import { storeProvider } from 'ReduxHelpers';
import { openChatAndCreateDraftChannelIfNeeded } from 'Messaging';
import template from '../../views/admin_users/admin_edit_career_profile.html';
import angularModule from '../admin_module';

angularModule.component(
    'userAdministrationTab',
    react2Angular(UserAdministrationTab, ['userId', 'onUserUpdate'], '', false),
);

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminEditCareerProfile', [
    '$injector',

    function factory($injector) {
        const $location = $injector.get('$location');
        const User = $injector.get('User');
        const scopeTimeout = $injector.get('scopeTimeout');
        const dateHelper = $injector.get('dateHelper');
        const LogInAs = $injector.get('LogInAs');
        const ClientStorage = $injector.get('ClientStorage');
        const rootScope = $injector.get('$rootScope');
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                user: '=thing',
                users: '=thingsOnCurrentPage',
                goBack: '&',
                gotoThing: '&',
                containerViewModel: '<',
            },
            link(scope) {
                scope.userId = scope.user.id;

                try {
                    scope.parsedPhone = parsePhoneNumber(scope.user.phone).formatNational();
                } catch (e) {
                    scope.parsedPhone = scope.user.phone;
                }

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'lastActiveAoiRecord', {
                    get() {
                        return getAoiRecord(scope.user);
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'programApplicationForActiveProgram', {
                    get() {
                        return getAoiRecord(scope.user, AoiRecordType.ProgramApplication);
                    },
                    configurable: true,
                });

                // If the user gets updated inside of the react component, we should reload the
                // user through the API to make sure we have the latest state
                scope.onUserUpdate = () => {
                    User.show(scope.userId).then(({ result }) => {
                        scope.user.copyAttrs(result.asJson());
                        setUserProxy();
                        initializeTabs();
                    });
                };

                scope.$watch('currentTab', currentTab => {
                    if (currentTab) {
                        $location.search('page', null);
                        $location.search('tab', currentTab);
                        ClientStorage.setItem('adminEditCareerProfile_currentTab', currentTab);
                    }
                });

                scope.$on('$destroy', () => {
                    scope.containerViewModel.headerOverride = undefined;
                    scope.containerViewModel.email = undefined;
                    scope.containerViewModel.resumeUrl = undefined;
                    scope.containerViewModel.linkedInUrl = undefined;
                    scope.containerViewModel.onApplicantDetailPage = undefined;
                    $location.search('tab', null);
                });

                scope.containerViewModel.headerOverride = scope.user.email;
                scope.containerViewModel.email = scope.user.email;
                scope.containerViewModel.onApplicantDetailPage = true;

                if (scope.user.career_profile) {
                    scope.containerViewModel.linkedInUrl = scope.user.career_profile.li_profile_url;
                    scope.containerViewModel.resumeUrl =
                        scope.user.career_profile.resume &&
                        scope.user.career_profile.resume.formats &&
                        scope.user.career_profile.resume.formats.original &&
                        scope.user.career_profile.resume.formats.original.url;
                }

                scope.dateHelper = dateHelper;

                function refreshCard() {
                    scope.refreshing = true;
                    scopeTimeout(
                        scope,
                        () => {
                            scope.refreshing = false;
                        },
                        10,
                    );
                }

                //-----------------------
                // Edit Career Profile
                //-----------------------

                scope.gotoEditCareerProfile = () => {
                    scope.currentTab = 'edit-profile';
                    scope.programType = getUserProgramState(scope.user)?.programType;

                    if (!scope.applicationFormSteps) {
                        scope.applicationFormSteps = StudentProfileFormConfigs;
                    }

                    // wait for section to instantiate before navigating to first page
                    scopeTimeout(
                        scope,
                        () => {
                            scope.gotoEditCareerProfileStep($location.search().page - 1 || 0);
                        },
                        10,
                    );
                };

                scope.sendMessage = () => {
                    if (scope.currentUser.sendbird_access_token && scope.user)
                        storeProvider.dispatch(
                            openChatAndCreateDraftChannelIfNeeded({
                                currentUserId: scope.currentUser.id,
                                token: scope.currentUser.sendbird_access_token,
                                userToMessageId: scope.user.id,
                            }),
                        );
                };

                // if we see that we're initially loading the page with the edit-profile tab open,
                // immediately call gotoEditCareerProfile to ensure the applicationFormSteps are set
                if (scope.currentTab === 'edit-profile') {
                    scope.gotoEditCareerProfile();
                }

                scope.gotoEditCareerProfileStep = $index => {
                    scope.activePageIndex = $index;
                    scope.$broadcast('gotoFormStep', scope.activePageIndex);
                    scope.applicationActiveStep = scope.applicationFormSteps[scope.activePageIndex];
                    scope.currentStepName = scope.applicationActiveStep.stepName;
                };

                //------------------------
                // Log in as user
                //------------------------

                scope.logInAsUser = user => {
                    LogInAs.logInAsUser(user);
                };

                //-----------------------------------
                // Saving the user and career profile
                //-----------------------------------

                function refreshUserProxy(user) {
                    _.extend(scope.user, user);
                    setUserProxy();
                }

                //-----------------------------------
                // Tabs
                //-----------------------------------

                function initializeTabs() {
                    // initialize the tabs, remembering previous tab and checking for specified tab in search params
                    const clientStorageTab = ClientStorage.getItem('adminEditCareerProfile_currentTab');

                    const paramTab = $location.search().tab;
                    const otherTabs = ['timeline', 'payments', 'administration', 'lesson-permissions'];
                    const cohortProgramRelatedTabs = ['student-records'];
                    const selectTab = paramTab || clientStorageTab;

                    // This is intended to hide certain tabs from external institution users.
                    scope.availableTabs = scope.user.prefUserProgramFieldStateId
                        ? cohortProgramRelatedTabs.concat(otherTabs)
                        : otherTabs;
                    // Career profiles are created for all users now, but until they're all backfilled, we still need
                    // this check. See https://trello.com/c/z37swAub
                    if (scope.user.career_profile) {
                        scope.availableTabs.push('edit-profile');
                    }
                    scope.currentTab = scope.availableTabs.includes(selectTab) ? selectTab : scope.availableTabs[0];
                }

                // ------------------
                // Proxy handling
                // ------------------

                scope.$watch('user', () => {
                    setUserProxy();
                    initializeTabs();
                });

                // on scope for specs
                scope.onSavedCareerProfile = () => {
                    refreshUserProxy(scope.userProxy);
                    refreshCard();
                };

                scope.$on('savedCareerProfile', scope.onSavedCareerProfile);

                // resets the user proxy based on scope.user or sets it to an empty object if no scope.user
                function setUserProxy() {
                    if (scope.user) {
                        scope.userProxy = User.new(scope.user.asJson());
                    } else {
                        scope.userProxy = {};
                    }
                }
            },
        };
    },
]);
