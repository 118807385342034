import angularModule from 'Admin/angularModule/scripts/admin_module';
import Papa from 'papaparse';
import template from 'Admin/angularModule/views/batch_lesson_grader.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('batchLessonGrader', [
    '$injector',

    function factory($injector) {
        const Lesson = $injector.get('Lesson');
        const Stream = $injector.get('Lesson.Stream');
        const $q = $injector.get('$q');
        const ComponentEditorViewModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',
        );
        const HasSortableColumnsMixin = $injector.get('HasSortableColumnsMixin');
        const FrameListGrader = $injector.get('Lesson.FrameList.FrameListGrader');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope, elem) {
                AppHeaderViewModel.setTitleHTML('GRADE LESSONS');

                HasSortableColumnsMixin.onLink(scope);

                scope.sort = {
                    column: 'lesson.title',
                    descending: false,
                };
                scope.startedGrading = false;
                scope.finishedGrading = false;
                scope.percentGradingComplete = 0;
                scope.numGraded = 0;

                scope.form = {};
                scope.form.minOver = 0;
                scope.form.withImage = true;
                scope.form.withoutImage = true;
                scope.form.normalAnswers = true;
                scope.form.wideAnswers = true;

                scope.textModelContextsMap = {};
                scope.frameTypesMap = {};
                scope.numLongTexts = 0;

                // Load up streams for the purpose of filtering
                Stream.index({
                    filters: {
                        published: true,
                    },
                    'fields[]': Stream.fieldsForEditorList,
                    include_progress: false,
                }).then(response => {
                    scope.streams = response.result;
                    scope.selectedGroup = {};
                    scope.availableGroups = _.chain(scope.streams)
                        .map(s => s.groups)
                        .flattenDeep()
                        .uniqBy(_.iteratee('name'))
                        .value();
                    scope.availableGroupsOptions = _.chain(scope.availableGroups)
                        .map(group => ({
                            id: group.name,
                            title: group.name,
                        }))
                        .value();
                });

                scope.groupsConfig = {
                    create: false,
                    valueField: 'id',
                    labelField: 'title',
                    maxItems: 1,
                    sortField: 'title',
                    searchField: ['title'],
                };

                scope.$watch('selectedGroup.group', groupText => {
                    if (!groupText) {
                        return;
                    }

                    // get list of stream titles that are acceptable for lessons
                    scope.acceptableStreamTitles = scope.streams
                        .filter(stream => stream.inGroup(groupText))
                        .map(stream => stream.titleWithTag);
                });

                scope.filterLongText = longText => +longText.lengthOver >= +scope.form.minOver;

                scope.showLongText = (frame, longText) => {
                    const mainEditorViewModel = frame.mainUiComponentEditorViewModel;

                    // longText filters...
                    return (
                        scope.textModelContextsMap[longText.context] &&
                        (angular.isUndefined(longText.wideAnswers) ||
                            (longText.wideAnswers === true && scope.form.wideAnswers) ||
                            (longText.wideAnswers === false && scope.form.normalAnswers)) &&
                        // frame filters...
                        ((mainEditorViewModel.mainImage && scope.form.withImage) ||
                            (!mainEditorViewModel.mainImage && scope.form.withoutImage)) &&
                        scope.frameTypesMap[frame.editor_template]
                    );
                };

                scope.visibleLongTexts = () => elem.find('.long-text-entry').length;

                scope.filteredLongTexts = () => elem.find('.long-text-entry.filtered-text').length;

                Object.defineProperty(scope, 'percentGradingComplete', {
                    get() {
                        if (!scope.lessonEntries) {
                            return 0;
                        }
                        return Math.round((100 * scope.numGraded) / scope.lessonEntries.length);
                    },
                });

                function addGraderToEntry(grader) {
                    scope.lessonEntryMap[grader.lesson.id].grader = grader;
                    scope.numGraded += 1;

                    grader.tooLongInteractionTypes.forEach(type => {
                        scope.frameTypesMap[type] = true;
                    });

                    grader.tooLongFrames.forEach(frame => {
                        frame.longTexts.forEach(t => {
                            scope.textModelContextsMap[t.context] = true;
                            scope.numLongTexts += 1;
                        });
                    });
                }

                function createGradingPromises() {
                    const promises = [];

                    scope.lessonEntries.forEach(nextEntry => {
                        const promise = Lesson.show(nextEntry.lesson.id, {
                            filters: {
                                published: false,
                            },
                            'except[]': ['lesson_progress', 'version_history'],
                        }).then(
                            response => {
                                const loadedLesson = response.result;

                                // we are normally not allowed to create editor view models
                                // except on the editor page, so we need to allow it explicitly
                                return ComponentEditorViewModel.allowInstantiate(() =>
                                    loadedLesson.grade().then(addGraderToEntry),
                                );
                            },
                            error => {
                                throw new Error(`Could not load lesson ${nextEntry.lesson.title} : ${error}`);
                            },
                        );

                        promises.push(promise);
                    });

                    $q.all(promises).then(() => {
                        scope.finishedGrading = true;

                        // make a note of all contexts seen so far
                        scope.textModelContexts = _.keys(scope.textModelContextsMap);

                        // make a note of all the frame types seen so far
                        scope.frameTypes = _.keys(scope.frameTypesMap);

                        const csv = Papa.unparse({
                            fields: FrameListGrader.csvColumns,
                            data: scope.lessonEntries.map(entry => entry.grader.toCsvRow()),
                        });

                        const blob = new Blob([csv], {
                            type: 'text/csv',
                        });
                        const csvUrl = URL.createObjectURL(blob);

                        scope.downloadLink = csvUrl;
                    });
                }

                scope.startGrading = limit => {
                    scope.startedGrading = true;

                    Lesson.index(
                        {
                            filters: {
                                published: true,
                                archived: false,
                            },
                            'fields[]': [
                                'id',
                                'title',
                                'tag',
                                'modified_at',
                                'published_at',
                                'updated_at',
                                'description',
                                'author',
                                'stream_titles',
                                'lesson_type',
                                'archived',
                            ],
                            'except[]': ['lesson_progress', 'version_history'],
                        },
                        {
                            // this request could take a long time. Do not time it out
                            timeout: undefined,
                        },
                    ).then(response => {
                        let lessons = response.result;
                        scope.lessonEntryMap = {};

                        let lessonCounter = 0;

                        lessons = _.filter(lessons, lesson => {
                            // filter out lessons not in streams
                            if (lesson.stream_titles.length === 0) {
                                return false;
                            }

                            // filter out lessons not in the streams in the selected group
                            if (
                                _.intersection([lesson.stream_titles].flat(), [scope.acceptableStreamTitles].flat())
                                    .length === 0
                            ) {
                                return false;
                            }

                            // filter out lessons beyond how many we asked for
                            lessonCounter += 1;

                            if (limit > 0 && lessonCounter > limit) {
                                return false;
                            }

                            return true;
                        });

                        scope.lessonEntries = lessons.map(lesson => {
                            const entry = {
                                lesson,
                                grader: undefined,
                            };
                            scope.lessonEntryMap[lesson.id] = entry;
                            return entry;
                        });

                        createGradingPromises();
                    });
                };

                scope.showDetailedGrade = entry => {
                    scope.showDetailedGradeForEntry = entry;
                };
            },
        };
    },
]);
