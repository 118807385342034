import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_mba/admin_cohort.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminCohort', [
    '$injector',

    function factory($injector) {
        const $location = $injector.get('$location');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                thing: '<',
                goBack: '&',
                created: '&',
                destroyed: '&',
                listMeta: '<',
            },
            link(scope) {
                scope.$location = $location;

                // MBA15- and EMBA10- didn't have a fully formed enrollment process yet
                scope.isLegacyEnrollmentCohort =
                    (scope.thing.program_type === 'mba' && scope.thing.startDate < new Date('2018/08/21')) ||
                    (scope.thing.program_type === 'emba' && scope.thing.startDate < new Date('2018/07/17'));

                scope.$watch('$location.search().tab', tab => {
                    if (!tab) return;
                    if (scope.currentTab !== tab) scope.currentTab = tab;
                });

                scope.$watch('currentTab', tab => {
                    if (!tab) tab = 'edit';
                    $location.search('tab', tab);
                });

                scope.$on('$destroy', () => {
                    $location.search('tab', null);
                });

                scope.$watch('thing.diff_from_published_version', diff => {
                    scope.prettyDiff = diff && JSON.stringify(diff, null, 4);
                });
            },
        };
    },
]);
